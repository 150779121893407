import { OrderLineToLoadId } from './value-objects/order-line-to-load-id';

export default class OrderLineToLoad {

    private _id: OrderLineToLoadId;
    public get id(): OrderLineToLoadId {
        return this._id;
    }

    private _description: string;
    public get description(): string {
        return this._description;
    }

    private _format: string;
    public get format(): string {
        return this._format;
    }

    private _reference: string;
    public get reference(): string {
        return this._reference;
    }

    private _quantityInPallets: number;
    public get quantityInPallets(): number {
        return this._quantityInPallets;
    }

    private _quantityInBoxes: number;
    public get quantityInBoxes(): number {
        return this._quantityInBoxes;
    }

    private _quantityInPieces: number;
    public get quantityInPieces(): number {
        return this._quantityInPieces;
    }

    private _weightGross: number;
    public get weightGross(): number {
        return this._weightGross;
    }

    constructor(
        id: OrderLineToLoadId,
        description: string,
        format: string,
        reference: string,
        quantityInPallets: number,
        quantityInBoxes: number,
        quantityInPieces: number,
        weightGross: number,
    ) {
        this._id = id;
        this._description = description;
        this._format = format;
        this._reference = reference;
        this._quantityInPallets = quantityInPallets;
        this._quantityInBoxes = quantityInBoxes;
        this._quantityInPieces = quantityInPieces;
        this._weightGross = weightGross;
    }

}
