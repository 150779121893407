import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import i18next from 'i18next';
import { useState } from 'react';

export interface useProformaInterface {
    download: (orderId:OrderId) => void,
    proformaLoading: boolean,
    error: string | undefined,
}
export const useProforma = (): useProformaInterface => {

    const [proformaLoading, setProformaLoading] = useState(false);
    const APIClient = TencerApiClientSingleton.getInstance();
    const [error, setError] = useState<string>();

    const doError = ():void => {
        setError(i18next.t('UNEXPECTED_ERROR'));
        setTimeout(() => setProformaLoading(false), 5000);
    };

    const download = async (orderId: OrderId): Promise<void> => {
        setProformaLoading(true);
        let proforma:Blob|null = null;
        try {
            proforma = await APIClient.getMyCartProforma(orderId.value);
        } catch (e) {
            doError();
            return;
        }
        if (!proforma) {
            doError();
            return;
        }

        const file = new Blob([proforma], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        setProformaLoading(false);
    };

    return {
        download,
        proformaLoading,
        error,
    };
};
