import { DeliveryServiceId } from './value-objects/delivery-service-id';

export class DeliveryService {

    private _id: DeliveryServiceId;
    private _name: string;

    public constructor(
        id: DeliveryServiceId,
        name: string,
    ) {
        this._id = id;
        this._name = name;
    }

    get id(): DeliveryServiceId {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

}
