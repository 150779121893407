import React from 'react';
import { useTranslation } from 'react-i18next';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useNavigate } from 'react-router-dom';
import { BOOKING_BUY_BANNER, CHECK_STOCK_BANNER, CHECK_THE_AVAILABLE_BANNER, DESIGN_BANNER, MAKE_THE_RESERVATION_BANNER, OUR_COLLECTION_BANNER, TRACKING, WE_TAKE_CARE_BANNER, WOW_BANNER, WELCOME_WOW } from 'i18n/translations/TR';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { SwiperBackground } from '../../shared/SwiperBackground';
import IconRest from '../../../assets/img/icons/ic-buy-res.svg';
import IconTrack from '../../../assets/img/icons/ic-track.svg';
import IconStock from '../../../assets/img/icons/ic-stock.svg';
import { useFeaturedCollectionList } from '../featured-collections/shared/domain/FeaturedCollectionList';
import { FeatureItem } from './components/FeatureItem';

export const MainBanner: React.FC = () => {
    const { t } = useTranslation();

    const classNameProp = 'swiper-container-hero swiper-banner-home';
    const history = useNavigate();
    const carouselImages: string[] = [];
    let serverErrorMessage: boolean = false;

    const collections = useFeaturedCollectionList();
    const error = collections?.error;
    const loading = collections?.loading;

    if (error) {
        serverErrorMessage = true;
    } else {
        collections.data.map((image) => (
            carouselImages.push(image.imageHeader)
        ));
    }

    const goToCollections = ():void => {
        history(TencerRoutes.COLLECTIONS);
    };

    return (
        <section className="banner">
            {!loading ?
                (
                    <div>
                        {!serverErrorMessage ?
                            (
                                <div>
                                    {carouselImages.length ?
                                        (
                                            <>
                                                <div className="container">
                                                    <h1 className="home-title">
                                                        {t(WOW_BANNER)}
                                                        <span> </span>
                                                        <span className="f-serif">
                                                            {t(DESIGN_BANNER)}
                                                        </span>
                                                    </h1>

                                                    <p className="intro-title">
                                                        {t(WELCOME_WOW)}
                                                    </p>

                                                    <div className="wow-features">
                                                        <FeatureItem
                                                            image={IconStock}
                                                            title={t(CHECK_STOCK_BANNER)}
                                                            description={t(CHECK_THE_AVAILABLE_BANNER)}
                                                        />

                                                        <FeatureItem
                                                            image={IconRest}
                                                            title={t(BOOKING_BUY_BANNER)}
                                                            description={t(MAKE_THE_RESERVATION_BANNER)}
                                                        />

                                                        <FeatureItem
                                                            image={IconTrack}
                                                            title={t(TRACKING)}
                                                            description={t(WE_TAKE_CARE_BANNER)}
                                                        />

                                                    </div>
                                                    <button
                                                        onClick={goToCollections}
                                                        type="button"
                                                        className="btn btn-primary"
                                                    >
                                                        {t(OUR_COLLECTION_BANNER)}
                                                    </button>
                                                </div>

                                                <SwiperBackground
                                                    classNameProp={classNameProp}
                                                    images={carouselImages}
                                                />
                                            </>
                                        ) : <div>{null}</div>}
                                </div>
                            ) : <ServerErrorMessage />}
                    </div>
                ) : <Spinner classCss="header-section-spinner" /> }
        </section>
    );
};
