import React from 'react';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import HttpStatusCode from 'app/shared/HttpStatusCode';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { API_MODIFY_QUANTITY_CART, BASE_URL } from 'app/constants/urlConstants';
import { tokenConfig, url } from 'app/shared/context/config';
import axios from 'axios';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import CartMapper from '../infraestructure/repository/api/data-mapper/CartMapper';
import Cart from '../domain/model/Cart';
import { usePermission } from '../../shared/hooks/use-Permission';

interface UseCartItemsList {
    refreshCart(): void;
    addToCart(
        quantity: number,
        quantity_units: string,
        id: string,
        forced: string,
        warehouse_location: string|undefined,
    ): Promise<boolean>;
    removeCartItem: (cartItemId: string, callback: () => void) => void;
    error: boolean;
    isLoading: boolean;
    cart?: Cart|null;
}

export function useCartItemsList(): UseCartItemsList {
    const isMounted = useIsMounted();
    const [error, setError] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const context = React.useContext(CurrentUserContext);
    const permission = usePermission();

    const [cart, setCart] = React.useState<Cart|null>();
    const APIClient = TencerApiClientSingleton.getInstance();

    const refreshCart = (): void => {
        setIsLoading(true);
        APIClient.getMyCart()
            .then((response) => {
                if (!isMounted()) {
                    return;
                }
                const fetchedCart = CartMapper.fromResponse(response);
                setCart(fetchedCart);
                context?.setExpirationDate(fetchedCart?.freeStockAt?.toISOString() ?? '');

            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const addToCart = async (
        quantity: number,
        quantity_units: string,
        id: string,
        forced: string,
        warehouse: string|undefined,
    ): Promise<boolean> => {
        if (!context) {
            return false;
        }

        if (!permission.canDoOrders()) {
            throw new Error("You don't have permission to add items to the cart");
        }

        context.setCartLoading(true);
        return APIClient.postCartProducts(
            quantity,
            quantity_units,
            id,
            forced.length > 0,
            warehouse,
        )
            .then(() => {
                if (!isMounted) {
                    return false;
                }
                context.setNoStockAvailable(false);
                context.setCartModified(new Date());
                refreshCart();
                return true;
            })
            .catch((err) => {
                if (err.response.status === HttpStatusCode.UNAUTHORIZED) {
                    context.setTokenExpired(true);
                } else if (err.response.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    context.setNoStockAvailable(true);
                    context.setForce('?force=true');
                    context.setItemIdRemove(id);
                } else if (err.response.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                    context.setForce('?force=true');
                    context.setItemIdRemove(id);
                }
                throw err;
            })
            .finally(() => {
                context.setCartLoading(false);
            });
    };

    const removeCartItem = async (cartItemId: string, callback: () => void): Promise<void> => {
        if (!context) {
            return;
        }
        await axios
            .delete(url(BASE_URL, API_MODIFY_QUANTITY_CART + cartItemId), tokenConfig())
            .then(() => {
                context.setCartModified(new Date());
                callback();
            });
    };

    return {
        addToCart,
        removeCartItem,
        cart,
        isLoading,
        error,
        refreshCart,
    };
}
