import React from 'react';
import { Summary } from './summary/summary';
import { UseCartSummaryResponse } from '../../hooks/use-cart-summary';
import { CartSummaryList } from './list/cart-summary-list';
import { ShippingInstructions } from './shipping-instructions/shipping-instructions';

interface ICartSummaryBodyProps {
    UseCartSummary: UseCartSummaryResponse
}
export const CartSummaryBody = ({
    UseCartSummary,
}: ICartSummaryBodyProps): React.ReactElement => {

    return (
        <div className="cart-summary-content">
            <div className="col-md-7">
                <CartSummaryList
                    UseCartSummary={UseCartSummary}
                />
            </div>
            <div className="col-md-4">
                <ShippingInstructions
                    UseCartSummary={UseCartSummary}
                />
                {UseCartSummary.cart ? (
                    <Summary
                        timer={UseCartSummary.state?.timer}
                        totalAmount={UseCartSummary.totalAmount}
                        cartWeight={UseCartSummary.cart!.weight.weight}
                        watchHasLiftGate={UseCartSummary.watchLiftGate}
                        liftGatePrice={UseCartSummary.UseTransport.liftGatePrice}
                        transportPrice={UseCartSummary.UseTransport.transportPrice}
                        watchTransportResponsible={UseCartSummary.watchResponsible}
                        isGettingTransportPrice={UseCartSummary.UseTransport.isGettingTransportPrice}
                        cart={UseCartSummary.cart}
                    />
                ) : null}
            </div>
        </div>
    );
};
