import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import Cart from 'app/cart/domain/model/Cart';
import {
    CART,
    LIFT_GATE_LABEL,
    ORDER_SUMMARY_TOTAL_PRODUCTS,
    STOCK_TIME_REMAINING,
    SUMMARY,
    TOTAL_AMOUNT,
    TOTAL_CART_DISCOUNTS_APPLIED_LABEL,
    TOTAL_WEIGHT,
    TRANSPORT_LABEL,
} from '../../../../../../i18n/translations/TR';
import { COWeight } from '../../../../../shared/components/CO-weight';
import { getRoundedValues } from '../../../../../shared/getRoundedValues';
import { Currency } from '../../../../../shared/Currency';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { ETransportType } from '../../../../../orders/model/value-objects/transport-type';
import { DiscountsSummary } from '../../../../../orders/application/components/DiscountsSummary/DiscountsSummary';
import { DiscountsSummaryLayout } from '../../../../../orders/application/components/DiscountsSummary/DiscountsSummaryLayout';

interface SummaryProps {
    cartWeight: number;
    totalAmount: number;
    liftGatePrice: number;
    transportPrice: number;
    timer: string | undefined;
    watchHasLiftGate: boolean;
    isGettingTransportPrice: boolean;
    watchTransportResponsible: ETransportType;
    cart: Cart;
}

export const Summary = (
    {
        timer,
        cartWeight,
        totalAmount,
        liftGatePrice,
        transportPrice,
        watchHasLiftGate,
        isGettingTransportPrice,
        watchTransportResponsible,
        cart,
    }: SummaryProps,
): React.ReactElement => {
    const { t } = useTranslation();
    const origin = useOrigin();

    return (
        <>
            <h5 className="card-wow-title">{t(SUMMARY)}</h5>
            <div className="card-box-summary">
                <span className="cart-content-subtitle">{t(CART)}</span>
                <ul className="card-box-summary-list-container">
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(ORDER_SUMMARY_TOTAL_PRODUCTS)}
                        </div>
                        <div className="value">{cart.items.length}</div>
                    </li>
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(TOTAL_WEIGHT)}
                        </div>
                        <div className="value">
                            <COWeight
                                value={getRoundedValues(cartWeight)}
                                className="import"
                            />
                        </div>
                    </li>
                    <li className="card-box-summary-list-item">
                        <div className="title">
                            {t(STOCK_TIME_REMAINING)}
                        </div>
                        <div className="value">{timer}</div>
                    </li>
                    {
                        cart.discounts.length > 0 ? (
                            <>
                                <li className="card-box-summary-list-item">
                                    <div className="title">
                                        {t(TOTAL_CART_DISCOUNTS_APPLIED_LABEL)}
                                    </div>
                                    <div className="value">
                                        <span>-</span>
                                        <Currency value={cart.discountsAmount()} className="import" />
                                    </div>
                                </li>
                                <li className="card-box-summary-list-item cart-discounts">
                                    <DiscountsSummary
                                        gross={cart.brutePrice()}
                                        discounts={cart.discounts}
                                        layout={DiscountsSummaryLayout.FULL}
                                    />
                                </li>
                            </>
                        ) : null
                    }
                    {origin === Origin.USA ? (
                        <>
                            {watchTransportResponsible === ETransportType.WOW ? (
                                <li className="card-box-summary-list-item">
                                    <p>
                                        {t(TRANSPORT_LABEL)}
                                    </p>
                                    {isGettingTransportPrice ? (
                                        <Skeleton inline width={70} />
                                    ) : (
                                        <p>
                                            <span>+</span>
                                            <Currency value={transportPrice} className="import" />
                                        </p>
                                    )}
                                </li>
                            ) : null}
                            {watchHasLiftGate ? (
                                <li className="card-box-summary-list-item">
                                    <p>
                                        {t(LIFT_GATE_LABEL)}
                                    </p>
                                    <p>
                                        <span>+</span>
                                        <Currency value={liftGatePrice} className="import" />
                                    </p>

                                </li>
                            ) : null}
                        </>
                    ) : null}
                    <li className="card-box-summary-list-item summary-total-price">
                        <p className="summary-total-price-title">
                            {t(TOTAL_AMOUNT)}
                        </p>
                        <p className="summary-total-price-import">
                            <Currency value={totalAmount} className="import" />
                        </p>
                    </li>
                </ul>
            </div>
        </>
    );
};
