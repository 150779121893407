import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    CANCEL,
    DELETE,
    DELETE_ITEM,
    DELETE_LINE_CART_QUESTION,
    DELETING,
} from '../../../i18n/translations/TR';

interface DeleteCartItemModalProps{
    show:boolean
    handleClose: () => void
    deleteCartItemIsLoading:boolean
    removeFromCart: (id: string) => Promise<void>
    idToDelete:string
}
export const DeleteCartItemModal = (
    {
        show,
        handleClose,
        deleteCartItemIsLoading,
        removeFromCart,
        idToDelete,
    }:DeleteCartItemModalProps,
):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            show={show}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{t(DELETE_ITEM)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {t(DELETE_LINE_CART_QUESTION)}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn-link bg-transparent text-decoration-none"
                    onClick={handleClose}
                    disabled={deleteCartItemIsLoading}
                >
                    {t(CANCEL)}
                </button>
                <button
                    type="submit"
                    form="add-form"
                    className="btn btn-primary"
                    disabled={deleteCartItemIsLoading}
                    onClick={(): Promise<void> => removeFromCart(idToDelete)}
                >
                    {
                        deleteCartItemIsLoading
                            ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                            : null
                    }
                    {deleteCartItemIsLoading ? `\u00A0${t(DELETING)}` : `\u00A0${t(DELETE)}`}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
