import React from 'react';
import { useTranslation } from 'react-i18next';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useNavigate } from 'react-router-dom';
import { PRODUCTS } from 'i18n/translations/TR';

interface Props {
    image: string,
    title: string,
    description: string
}

export const FeatureItem: React.FC<Props> = ({ image, title, description }) => {
    const { t } = useTranslation();
    const history = useNavigate();

    const goToCatalog = ():void => {
        history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
    };
    return (
        <div
            className="feature-item"
            onClick={goToCatalog}
            role="presentation"
        >
            <div className="feature-img">
                <img src={image} alt="default-icon" width="47" />
            </div>
            <div className="feature-info">
                <div className="feature-title">
                    {title}
                </div>
                <div className="feature-description">
                    {description}
                </div>
            </div>
        </div>
    );
};
