export default class LoadingOrderSlot {

    private _from: Date;
    public get from(): Date {
        return this._from;
    }

    private _until: Date;
    public get until(): Date {
        return this._until;
    }

    constructor(
        from: Date,
        until: Date,
    ) {
        this._from = from;
        this._until = until;
    }

}
