import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SHIPPING_ADDRESS_LABEL_ADDRESS } from '../../../../../../i18n/translations/TR';

interface ShippingAddressFullAddressFieldProps {
    fullAddressRegister: UseFormRegisterReturn
    errorField: FieldError | undefined
    fullAddress1Register: UseFormRegisterReturn
    fullAddress2Register: UseFormRegisterReturn
}

export const ShippingAddressFullAddressField = (
    {
        fullAddressRegister,
        errorField,
        fullAddress1Register,
        fullAddress2Register,
    }: ShippingAddressFullAddressFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <div className="form-field address-field">
                <label htmlFor="address" className="form-label is-required">
                    {t(SHIPPING_ADDRESS_LABEL_ADDRESS)}
                </label>
                <input
                    type="text"
                    id="address"
                    className="form-control"
                    {...fullAddressRegister}
                />
            </div>
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
            <div className="form-field address1-field">
                <input
                    type="text"
                    id="address1"
                    className="form-control"
                    {...fullAddress1Register}
                />
            </div>
            <div className="form-field address2-field">
                <input
                    type="text"
                    id="address2"
                    className="form-control"
                    {...fullAddress2Register}
                />
            </div>
        </>
    );
};
