import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ADD_COMMENT_PLACEHOLDER, PROVIDE_CARRIER_PLACEHOLDER } from '../../../../../../../../i18n/translations/TR';
import { ETransportType } from '../../../../../../../orders/model/value-objects/transport-type';

interface CommentsFieldProps {
    commentsRegister: UseFormRegisterReturn;
    watchResponsible: ETransportType;
}

export const CommentsField = (props: CommentsFieldProps): React.ReactElement => {
    const { t } = useTranslation();
    const placeholder = (): string => {
        switch (props.watchResponsible) {
            case ETransportType.WILL_CALL:
            case ETransportType.WOW:
                return t(ADD_COMMENT_PLACEHOLDER);
            case ETransportType.OWN_LOGISTIC:
            case ETransportType.SMALL_PARCEL:
            default:
                return t(PROVIDE_CARRIER_PLACEHOLDER);
        }
    };

    return (
        <div className="transport-form-container-item">
            <textarea
                placeholder={placeholder()}
                className="form-control"
                id="transportComments"
                {...props.commentsRegister}
            />
        </div>
    );
};
