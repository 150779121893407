import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../hooks/use-cart-summary';
import { SHIPPING_INSTRUCTIONS } from '../../../../../../i18n/translations/TR';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';
import { OrderTransportForm } from './order-transport-form/order-transport-form';
import { OrderPoField } from './order-po-field/order-po-field';
import { OrderShippingAddress } from './order-shipping-address/order-shipping-address';

interface IShippingInstructionsProps {
    UseCartSummary: UseCartSummaryResponse;
}

export const ShippingInstructions = ({
    UseCartSummary,
}: IShippingInstructionsProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="shipping-instructions-container">
            <h5 className="card-wow-title">{t(SHIPPING_INSTRUCTIONS)}</h5>
            <div className="card-box">
                <OrderPoField
                    UseCartSummary={UseCartSummary}
                />
                <OrderShippingAddress
                    UseCartSummary={UseCartSummary}
                />
                {UseCartSummary.origin === Origin.USA ? (
                    <OrderTransportForm
                        UseTransportForm={UseCartSummary.UseTransport.UseTransportForm}
                        deliveryServices={UseCartSummary.UseTransport.deliveryServices}
                    />
                ) : null}
            </div>
        </div>
    );
};
