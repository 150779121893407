import React from 'react';

interface Props {
    name: string,
    category: string,
}

export const HeaderSection: React.FC<Props> =
    (
        { name,
            category },
    ) => {

        return (
            <section className="header-section bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 product-info">
                            <p className="product-subtitle text-start">{category}</p>
                            <h3 className="page-title text-start">{name}</h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    };
