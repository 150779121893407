export class ProductPrice {

    public constructor(
        private _value: number,
        private _quality: string,
        private _discount: number,
        private _palletPrice: number,
    ) {
    }

    public get value(): number {
        return this._value;
    }
    public get quality(): string {
        return this._quality;
    }
    public get discount(): number {
        return this._discount;
    }
    public get palletPrice(): number {
        return this._palletPrice;
    }

}
