import { useTranslation } from 'react-i18next';
import { EURO, DOLLAR } from 'i18n/translations/TR';
import { useOrigin } from './hooks/useOrigin';

export const getCurrency = (): string => {

    const { t } = useTranslation();
    const origin = useOrigin();

    let currency: string = '';

    if (origin === 'EUROPE') {
        currency = t(EURO);

    } else if (origin === 'USA') {
        currency = t(DOLLAR);
    }
    return currency;

};
