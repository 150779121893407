import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { Discount } from 'app/orders/model/value-objects/Discount';
import { ShippingAddressId } from '../../../orders/model/ShippingAddressId';
import { Price } from '../../../orders/model/value-objects/Price';
import { Weight } from '../../../orders/model/value-objects/Weight';
import { CartId } from '../value-objects/CartId';
import CartItem from './CartItem';

export default class Cart {

    public get id(): CartId {
        return this._id;
    }

    public get orderId(): OrderId | null {
        return this._orderId;
    }

    public get deliveryAddressCode(): ShippingAddressId {
        return this._deliveryAddressCode;
    }

    public get clientInternalRef(): string | null {
        return this._clientInernalRef;
    }

    public set clientInernalRef(value: string | null) {
        this._clientInernalRef = value;
    }

    public get freeStockAt(): Date {
        return this._freeStockAt;
    }

    public get weight(): Weight {
        return this._weight;
    }

    public get price(): Price {
        return this._price;
    }

    public get items(): CartItem[] {
        return this._items;
    }

    public get discounts(): Discount[] {
        return this._discounts;
    }

    public constructor(
        private _id: CartId,
        private _clientInernalRef: string | null,
        private _deliveryAddressCode: ShippingAddressId,
        private _freeStockAt: Date,
        private _items: CartItem[] = [],
        private _orderId: OrderId | null,
        private _price: Price,
        private _weight: Weight,
        private _discounts: Discount[] = [],
    ) {}

    public brutePrice(): Price {
        return new Price(this._items.reduce((acc, item) => acc + item.price.amount, 0));
    }

    public discountsAmount(): number {
        let brute = this.brutePrice().amount;
        let totalDiscounted = 0;
        this._discounts.forEach((discount) => {
            const amountDiscounted = Number((brute * (discount.value / 100)).toFixed(2));
            brute -= amountDiscounted;
            totalDiscounted += amountDiscounted;
        });

        return totalDiscounted;
    }

}
