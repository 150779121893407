import moment from 'moment/moment';
import { EAnalyticsEvent } from '../../../domain/analytics';
import { EAnalyticsEventDTO, PostAnalyticsEventRequest } from '../dto/requests/post-analytics-event-request';
import { ProductId } from '../../../../products/domain/value-object/ProductId';

export class PostAnalyticsEventMapper {

    private static analyticsEventFromDomain(event: EAnalyticsEvent): EAnalyticsEventDTO {
        switch (event) {
            case EAnalyticsEvent.PRODUCT_DETAIL:
                return EAnalyticsEventDTO.PRODUCT_DETAIL;
            case EAnalyticsEvent.LOGOUT:
            default:
                return EAnalyticsEventDTO.LOGOUT;
        }
    }

    public static fromDomain(date: Date, event: EAnalyticsEvent, productId?: ProductId): PostAnalyticsEventRequest {
        const request: PostAnalyticsEventRequest = {
            event_type: this.analyticsEventFromDomain(event),
            date: moment(date).format(moment.defaultFormatUtc),
        };

        if (productId !== undefined) {
            request.product_id = productId.value;
        }

        return request;
    }

}
