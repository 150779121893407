import React from 'react';

interface Props {
    id: number,
    locationName: string,
    locationType: string,
    address: string,
    address2: string,
    phoneNumber1: string,
    phoneNumber2: string,
    schedule: string,
    usaCountry: string
}

export const LocationBlock: React.FC<Props> =
    (
        { id,
            locationName,
            locationType,
            address,
            address2,
            phoneNumber1,
            phoneNumber2,
            schedule,
            usaCountry },
    ) => {
        return (
            <div key={id} className="location">
                <h6>{locationName}</h6>
                <p>{locationType}</p>
                <p>{address}</p>
                <p>{address2}</p>
                <p>{usaCountry}</p>
                <p>{phoneNumber1}</p>
                <p>{phoneNumber2}</p>
                <p>{schedule}</p>
            </div>
        );
    };
