import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import React, { useState, useEffect } from 'react';
import PageBannerModel from '../model/PageBanner';
import i18n from '../../../i18n';
import { Spinner } from '../../shared/Spinner';

interface Props {
    pageId: string;
}
export const PageBanner: React.FC<Props> = ({ pageId }) => {
    const [banner, setBanner] = useState<PageBannerModel | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isMounted = useIsMounted();

    useEffect(() => {
        setIsLoading(true);
        TencerApiClientSingleton.getInstance()
            .getPageBanner(pageId)
            .then((response) => {
                if (!isMounted()) {
                    return;
                }
                setBanner(response);
            })
            .catch(() => {
                if (!isMounted()) {
                    return;
                }
                setBanner(null);
            })
            .finally((): void => {
                setIsLoading(false);
            });
    }, [pageId, i18n.language]);

    return banner ? (
        <div className="page-banner">
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
                <Spinner classCss="" />
            ) :
                banner.link ? (
                    <a href={banner.link.value} className="page-banner-link">
                        <span>{banner.text}</span>
                    </a>
                ) : (
                    <span>{banner.text}</span>
                )}
        </div>
    ) : null;
};
