import React from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER } from 'i18n/translations/TR';
import { ReactComponent as IcFilter } from '../../assets/img/icons/ic-filter.svg';

interface Props {
    title: string,
    handleShow: () => void,
}

export const SectionHeaderOrders: React.FC <Props> = (
    { title, handleShow },
) => {

    const { t } = useTranslation();

    return (

        <div className="header-catalog header-actions">
            <h3 className="section-title">{title}</h3>
            <div className="actions">
                <p
                    className="bg-transparent"
                    aria-hidden="true"
                    onClick={handleShow}
                >
                    <IcFilter />
                    <span>{t(FILTER)}</span>
                </p>
            </div>
        </div>
    );
};
