import React from 'react';
import { LoadingOrderListTableBodyItem } from './item/loading-order-list-table-body-item';
import LoadingOrder from '../../../../../domain/loading-order';
import { LoadingOrderId } from '../../../../../domain/value-objects/loading-order-id';

interface LoadingOrderListTableBodyProps {
    loadingOrders: LoadingOrder[]
    downloadLoadingOrderPDF: (loadingOrderId: LoadingOrderId) => void
    isDownloadingPDF: boolean
}
export const LoadingOrderListTableBody = (props: LoadingOrderListTableBodyProps): React.ReactElement => {
    return (
        <tbody>
            {props.loadingOrders.map((loadingOrder) => (
                <LoadingOrderListTableBodyItem
                    loadingOrder={loadingOrder}
                    key={loadingOrder.id.value}
                    isDownloadingPDF={props.isDownloadingPDF}
                    downloadLoadingOrderPDF={props.downloadLoadingOrderPDF}
                />
            ))}
        </tbody>
    );
};
