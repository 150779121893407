import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCollectionRepository } from '../../../products/application/hooks/useCollectionRepository';
import MarketingCollection from '../../../products/domain/model/MarketingCollection';
import { PACKING_TILE_OTHER } from '../../../../i18n/translations/TR';

export interface useMarketingCollectionInterface {
    collection: MarketingCollection | undefined;
    tileTitle: (type: string) => string;
}

export const useMarketingCollection = (): useMarketingCollectionInterface => {

    const collectionsRepository = useCollectionRepository();
    const [collection, setCollection] = useState<MarketingCollection>();

    useEffect(() => {
        const getMarketingCollection = collectionsRepository.getMarketingCollectionMutation;
        getMarketingCollection.mutate(undefined, {
            onSuccess: (data: MarketingCollection) => {
                setCollection(data);
            },
        });
    }, []);

    const { t } = useTranslation();
    const otherTile = t(PACKING_TILE_OTHER) ? t(PACKING_TILE_OTHER) : '';

    const tileTitle = (type:string):string => {
        return type.trim().length > 0 ? type.trim() : otherTile;
    };

    return {
        collection,
        tileTitle,
    };
};
