import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { BACK, ORDER_DETAIL_TITLE } from 'i18n/translations/TR';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DownloadProformaButton } from '../components/DownloadProformaButton';
import { OrderAttachAdder } from '../components/OrderAttachAdder/OrderAttachAdder';
import { OrderLinesList } from '../components/OrderLinesList';
import { AttachedFiles } from './attached-files/attached-files';
import { useOrderDetails } from '../hooks/useOrderDetails';
import { OrderSummary } from './summary/order-summary';
import { PageSpinner } from '../../../shared/components/page-spinner/page-spinner';

export interface OrderDetailsScreenPropsInterface extends Record<string, string>{
    id: string;
}

export const OrderDetailScreen: React.FC = () => {
    const slug = useParams<OrderDetailsScreenPropsInterface>();
    const orderId:string = slug.id ?? '';

    const orderDetails = useOrderDetails(new OrderId(orderId));
    const { t } = useTranslation();

    return !orderDetails.isGettingOrderInfo ? (
        <div className="screen container">
            <div className="section-header row">
                <h4 className="section-title row ">
                    <span className="col-lg-5">{t(ORDER_DETAIL_TITLE, { orderId })}</span>
                    <span className="col-lg-7 order-detail-header-actions">
                        {orderDetails.order ? (
                            <div>
                                <AttachedFiles
                                    order={orderDetails.order}
                                />
                            </div>
                        ) : null}

                        {orderDetails.order ? (
                            <>
                                <div>
                                    <OrderAttachAdder
                                        show={orderDetails.showFileAdder}
                                        order={orderDetails.order}
                                        onAttach={orderDetails.refreshAttachments}
                                    />
                                </div>

                                <div>
                                    <DownloadProformaButton orderId={orderDetails.order.id} />
                                </div>
                            </>

                        ) : null}
                    </span>
                </h4>
            </div>

            {orderDetails.order ? (
                <OrderSummary
                    order={orderDetails.order}
                    orderShippingAddress={orderDetails.orderShippingAddress}
                />
            ) : null}

            {orderDetails.order ? (
                <OrderLinesList lines={orderDetails.order?.lines} />
            ) : null}

            <div className="footer-actions">
                <button
                    type="submit"
                    className="btn btn-success"
                    onClick={():void => orderDetails.submit()}
                >
                    {t(BACK)}
                </button>
            </div>
        </div>
    ) : (
        <PageSpinner isBusy={orderDetails.isGettingOrderInfo} />
    );
};
