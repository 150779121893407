import { POUND_WEIGHT, WEIGHT_UNIT } from 'i18n/translations/TR';
import i18next from 'i18next';

export class Weight {

    private readonly _weight: number;

    public constructor(value: number) {
        this.ensureIsNotUndefined(value);
        this._weight = value;
    }

    private ensureIsNotUndefined(value: number): void {
        if (value === undefined) {
            throw new Error('Weight cannot be undefined');
        }
    }

    public get weight(): number {
        return this._weight;
    }

    public static unitLabel = (origin: string): string => {
        const originEurope = 'EUROPE';
        return origin === originEurope ? i18next.t(WEIGHT_UNIT) : i18next.t(POUND_WEIGHT);
    };

}
