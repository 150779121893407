import { TransportType } from './value-objects/transport-type';
import { Freight } from './value-objects/freight';
import { LiftGateRate } from './value-objects/lift-gate-rate';

export default class Transport {

    private _type: TransportType;
    private _isConsolidated: boolean;
    private _comments: string;
    private _freight: Freight | null;
    private _liftGateRate: LiftGateRate| null;

    public get type(): TransportType {
        return this._type;
    }

    public get isConsolidated(): boolean {
        return this._isConsolidated;
    }

    public get comments(): string | null {
        return this._comments;
    }

    public get freight(): Freight | null {
        return this._freight;
    }

    public get liftGateRate(): LiftGateRate | null {
        return this._liftGateRate;
    }

    constructor(
        type: TransportType,
        isConsolidated: boolean,
        comments: string,
        freight: Freight | null,
        liftGateRate: LiftGateRate | null,
    ) {
        this._type = type;
        this._isConsolidated = isConsolidated;
        this._comments = comments;
        this._freight = freight;
        this._liftGateRate = liftGateRate;
    }

}
