import React from 'react';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { Typeahead as TypeAHead } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { TYPEAHEAD_CLASS_BASIC } from './typeAheadClasses';
import { TypeAheadDTO } from '../../infrastructure/tencer-api/dto/typeAhead.DTO';
import { TypeAheadArrayDTO } from '../../infrastructure/tencer-api/dto/typeAheadArray.DTO';
import { LOADING, NO_RESULTS_FOUND, SEARCH, SHOW_MORE_RESULTS } from '../../../../i18n/translations/TR';

interface TypeAheadProps {
    id: string,
    options: TypeAheadArrayDTO
    isLoading: boolean
    reference: React.RefObject<Typeahead>
    disabled: boolean
    handleSelected: (option: TypeAheadDTO) => void
    item?: (option: TypeAheadDTO) => React.ReactElement
    defaultSelected?: string[] | undefined
    placeholder?: string
}

export const TypeAhead = (
    {
        id,
        options,
        isLoading,
        reference,
        item,
        disabled,
        handleSelected,
        defaultSelected,
        placeholder,
    }: TypeAheadProps,
): React.ReactElement => {
    const maxResult: number = 5;
    const { t } = useTranslation();

    return (
        <TypeAHead
            // @ts-ignore
            filterBy={(option: TypeAheadDTO, props): boolean => {
                if (props.selected.length) {
                    // Display all the options if there's a selection.
                    return true;
                }
                // Otherwise filter on some criteria.
                return option.labelKey.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
            }}
            ref={reference}
            className={TYPEAHEAD_CLASS_BASIC}
            id={id}
            labelKey="labelKey"
            options={options}
            isLoading={isLoading}
            paginationText={t(SHOW_MORE_RESULTS)}
            emptyLabel={isLoading ? t(LOADING) : t(NO_RESULTS_FOUND)}
            maxResults={maxResult}
            placeholder={placeholder || t(SEARCH)}
            // @ts-ignore
            onChange={(selected: TypeAheadDTO[]): void => {
                handleSelected(selected[0]);
            }}
            // @ts-ignore
            renderMenuItemChildren={(option: TypeAheadDTO): React.ReactElement => (item ? (
                <div>
                    {item(option)}
                </div>
            ) : (
                <div className="options-item">
                    <span>{option.labelKey}</span>
                </div>
            ))}
            positionFixed
            clearButton
            disabled={disabled}
            // @ts-ignore
            defaultSelected={defaultSelected}
        />
    );
};
