import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    APPLY_FILTERS,
    BRANDS,
    COLLECTIONS,
    FILTERS,
    MATERIAL,
    PRODUCTS,
    REMOVE_FILTERS,
    SELECT,
    SHOW_ONLY,
} from 'i18n/translations/TR';
import { Offcanvas } from 'react-bootstrap';
import { useFilterFieldsList } from './shared/FilterFieldsList';

interface Props {
    show: boolean,
    onHide: () => void
}
interface FormInputs {
    material: string,
    collection: string,
    checkbox: boolean,
    collectionString: string,
    pasteString: string,
    brand: string,
    brandString: string,
}

export const FilterModal: React.FC<Props> = ({
    show,
    onHide,
}) => {
    const { t } = useTranslation();
    const { register, handleSubmit, setValue } = useForm<FormInputs>({
        mode: 'onChange',
    });
    const history = useNavigate();
    const response = useFilterFieldsList();
    const data = response.data;

    const removeFilters = (): void => {
        history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
    };

    const submitHandler: SubmitHandler<FormInputs> = (formData: FormInputs) => {
        history(
            TencerRoutes.CATALOG,
            { state:
                { filters:
                    { collection: formData.collection,
                        material: formData.material,
                        stock: formData.checkbox,
                        collectionName: formData.collectionString,
                        materialName: formData.pasteString,
                        brandName: formData.brandString,
                        brand: formData.brand } } },
        );
    };

    return (
        <Offcanvas
            show={show}
            placement="end"
            className="offcanvas"
            onHide={(): void => {
                onHide();
            }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t(FILTERS)}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form id="filter-form" onSubmit={handleSubmit(submitHandler)}>
                    <div className="mb-4">

                        <label htmlFor="selectFormat" className="form-label">{t(MATERIAL)}</label>
                        <select
                            className="form-select"
                            id="selectFormat"
                            {...register(
                                'material',
                            )}
                            onChange={(e): void => setValue('pasteString', e.target.selectedOptions[0].label)}

                        >
                            <option value="">{t(SELECT)}</option>
                            {data?.pastes ?
                                (
                                    data.pastes.map((paste) => (
                                        <option key={paste} value={paste}>{paste}</option>
                                    ))
                                ) : null}
                        </select>
                    </div>

                    <div className="mb-4">

                        <label htmlFor="selectCollection" className="form-label">{t(COLLECTIONS)}</label>
                        <select
                            className="form-select"
                            id="selectCollection"
                            {...register(
                                'collection',
                            )}
                            onChange={(e): void => setValue('collectionString', e.target.selectedOptions[0].label)}
                        >
                            <option value="">{t(SELECT)}</option>
                            {data?.collections ?
                                (
                                    data.collections.map((collection) => (
                                        <option
                                            key={collection.id}
                                            value={collection.id}
                                            label={collection.name}
                                        >
                                            {collection.name}
                                        </option>

                                    ))
                                ) : null}
                        </select>
                    </div>

                    <div className="mb-4">

                        <label htmlFor="selectCollection" className="form-label">{t(BRANDS)}</label>
                        <select
                            className="form-select"
                            id="selectCollection"
                            {...register(
                                'brand',
                            )}
                            onChange={(e): void => setValue('brandString', e.target.selectedOptions[0].label)}
                        >
                            <option value="">{t(SELECT)}</option>
                            {data?.brands ?
                                (
                                    data.brands.map((brand) => (
                                        <option key={brand} value={brand}>{brand}</option>

                                    ))
                                ) : null}
                        </select>
                    </div>

                    <div className="row form-check mt-5 mb-4">
                        <div className="col">
                            <label className="form-check-label" htmlFor="defaultCheckbox">
                                {t(SHOW_ONLY)}
                            </label>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheckbox"
                                {...register(
                                    'checkbox',
                                )}
                            />
                        </div>
                    </div>
                </form>
            </Offcanvas.Body>
            <div className="filter-footer">
                <button
                    type="submit"
                    className="btn btn-link text-decoration-none"
                    onClick={removeFilters}
                >
                    {t(REMOVE_FILTERS)}
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="offcanvas"
                    form="filter-form"
                >
                    {t(APPLY_FILTERS)}
                </button>
            </div>

        </Offcanvas>
    );
};
