import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { DiscountResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/DiscountResponse';
import { Discount } from 'app/orders/model/value-objects/Discount';
import Cart from '../../../../domain/model/Cart';
import { CartId } from '../../../../domain/value-objects/CartId';
import { ApiCartElement } from '../../../../shared/ApiCartItem';
import { Item } from '../../../../shared/ApiItem';
import { ShippingAddressId } from '../../../../../orders/model/ShippingAddressId';
import { Price } from '../../../../../orders/model/value-objects/Price';
import { Weight } from '../../../../../orders/model/value-objects/Weight';
import { CartItemMapper } from './CartItemMapper';

export default class CartMapper {

    public static fromResponse(response: ApiCartElement | null): Cart|null {
        if (!response) {
            return null;
        }
        return new Cart(
            new CartId(response.id),
            response.client_internal_ref ?? '',
            new ShippingAddressId(response.delivery_address_code),
            new Date(response.free_stock_at),
            response.items.map((item: Item) => CartItemMapper.fromResponse(item)),
            response ? new OrderId(response.order_id) : null,
            new Price(response.price.amount ?? 0.0),
            new Weight(response.weight.amount),
            response.discounts.map(
                (discount: DiscountResponse) => new Discount(discount.percent, discount.description),
            ),
        );
    }

}
