import React from 'react';
import { useTranslation } from 'react-i18next';
import { lotInfo } from '../shared/domain/WarehouseInfo';
import { NumberFormatComponentPieceUnit } from '../../shared/NumberFormatPieceUnit';
import { getRoundedValues } from '../../shared/getRoundedValues';
import { PIECE_UNIT } from '../../../i18n/translations/TR';
import { getSquareMeasurement } from '../../shared/getSquareMeasurement';
import { WarehouseLocation } from '../shared/domain/WarehouseLocation';

interface Props {
    origin: string | null | undefined
    amount: number
    unit: string
    lot: lotInfo[]
    outOfStockWarehousesLocations?: WarehouseLocation[]
}

interface WarehouseCollection {
    [index: string]: Warehouse
}

interface Warehouse {
    quantity: number
    code: string
    name: string
    unit: string
}

export const StockAvailable: React.FC<Props> = ({
    origin,
    amount,
    unit,
    lot,
    outOfStockWarehousesLocations,
}) => {
    const { t } = useTranslation();
    const measureUnit = unit === '0' ? t(PIECE_UNIT) : getSquareMeasurement();

    const warehouses: WarehouseCollection = {};
    lot?.forEach((value) => {
        if (!value.warehouse_location) {
            return;
        }
        const warehouseCode = value.warehouse_location.code;
        if (warehouses[warehouseCode]) {
            warehouses[warehouseCode].quantity += value.quantity;
        } else {
            warehouses[warehouseCode] = {
                quantity: value.quantity,
                code: warehouseCode,
                name: value.warehouse_location.name,
                unit,
            };
        }
    });

    const EUElement = (
        <span>
            {amount === 0 ? '-' :
                (
                    <NumberFormatComponentPieceUnit
                        value={getRoundedValues(amount)}
                        suffix={` ${measureUnit}`}
                    />
                )}
        </span>
    );
    const USAElement = (
        <>
            <NumberFormatComponentPieceUnit
                value={getRoundedValues(amount)}
                suffix={` ${measureUnit}`}
            />
            <ul>
                {Object.keys(warehouses).map((key) => {
                    const warehouse = warehouses[key];
                    if (!warehouse) {
                        return ('');
                    }
                    return (
                        <li key={key}>
                            <NumberFormatComponentPieceUnit
                                value={getRoundedValues(warehouse.quantity)}
                                suffix={` ${measureUnit}`}
                            />
                            <small className="p-2">
                                {`(${warehouse.name})`}
                            </small>
                        </li>
                    );
                })}
                {outOfStockWarehousesLocations && outOfStockWarehousesLocations.length > 0 ?
                    outOfStockWarehousesLocations.map((warehouse) => {
                        return (
                            <li key={warehouse.code}>
                                <NumberFormatComponentPieceUnit
                                    value={0}
                                    suffix={` ${measureUnit}`}
                                />
                                <small className="p-2">
                                    { `(${warehouse.name})`}
                                </small>
                            </li>
                        );
                    }) : null}
            </ul>
        </>
    );
    if (origin === 'EUROPE') {
        return EUElement;
    }
    if (origin === 'USA') {
        return USAElement;
    }

    return (<span>-</span>);
};
