import { useTranslation } from 'react-i18next';
import { UNIT, M2_UNIT } from 'i18n/translations/TR';

export const getAmountUnitsLabel = (value: string): string => {
    const { t } = useTranslation();

    let unitString: string = '';

    if (value === 'M2') {
        unitString = t(M2_UNIT);
    } else if (value === 'Unit') {
        unitString = t(UNIT);
    }

    return unitString;
};
