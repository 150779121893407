import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShippingAddress } from '../../../model/ShippingAddress';
import { CountryByProvince } from '../../../model/countryByProvince';
import { useShippingAddressRepository } from '../../hooks/useShippingAddressRepository';
import { ShippingAddressForm, ShippingAddressFormData } from './ShippingAddressForm';
import { ErrorCode } from '../../../../shared/domain/value-objects/ErrorCode';
import { ERROR_CODE_40016 } from '../../../../../i18n/translations/TR';

interface Props {
    countries: CountryByProvince[];
    onCancel: () => void;
    address: ShippingAddress;
    onSuccess(): void;
    formErrorMessage: string | undefined
    handleFormErrorMessage: (error: string | undefined) => void
}

export const EditShippingAddressForm: React.FC<Props> = (props: Props) => {
    const shippingAddressRepository = useShippingAddressRepository();
    const { t } = useTranslation();
    const form = useForm<ShippingAddressFormData>({
        defaultValues: {
            description: props.address?.description,
            countryId: props.address?.country.countryId,
            provinceId: props.address?.provinceId.value,
            postalCode: props.address?.postalCode.value,
            locality: props.address?.locality,
            address: props.address?.address.value,
            phone: props.address?.phone,
            address1: props.address?.address1.value,
            address2: props.address?.address2.value,
            fax: props.address?.fax,
        },
    });

    const onSubmit = form.handleSubmit((request: ShippingAddressFormData) => {
        props.handleFormErrorMessage(undefined);
        shippingAddressRepository.putShippingAddressMutation.mutate(
            {
                shippingAddressId: props.address?.code,
                description: request.description || null,
                country_id: request.countryId || null,
                province_id: request.provinceId || null,
                postal_code: request.postalCode || null,
                locality: request.locality || null,
                address: request.address || null,
                phone: request.phone || null,
                address1: request.address1 || null,
                address2: request.address2 || null,
                fax: request.fax || null,
            },
            {
                onSuccess: () => {
                    props.onSuccess();
                },
                onError: (error) => {
                    // @ts-ignore
                    if (ErrorCode.CP_OUT_OF_RANGE === error.response.data.code) {
                        props.handleFormErrorMessage(t(
                            ERROR_CODE_40016,
                            { postalCodeMin: form.getValues('minCp'), postalCodeMax: form.getValues('maxCp') },
                        ));
                    }
                },
            },
        );
    });

    return (
        <ShippingAddressForm
            countries={props.countries}
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            address={props.address}
            isBusy={shippingAddressRepository.putShippingAddressMutation.isLoading}
            form={form}
            formErrorMessage={props.formErrorMessage}
        />
    );
};
