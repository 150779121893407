import { Province } from '../../../../model/Province';
import { ProvinceResponse } from '../../../../model/responses/ProvinceResponse';
import { ProvinceId } from '../../../../model/value-objects/ProvinceId';

export class ProvinceMapper {

    static fromResponse(response: ProvinceResponse): Province {
        return new Province(
            new ProvinceId(response.province_id ? response.province_id : ''),
            response.countryId,
            response.name,
            response.shortName,
            response.prefix,
            response.postal_code_min,
            response.postal_code_max,
        );
    }

}
