import { CollectionId } from '../value-object/CollectionId';
import { Tile } from './Tile';

export default class MarketingCollection {

    public get tiles(): Tile[] {
        return this._tiles;
    }
    public get imageHeader(): string {
        return this._imageHeader;
    }
    public get image(): string {
        return this._image;
    }
    public get date(): Date {
        return this._date;
    }
    public get id(): CollectionId {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public constructor(
        private _id: CollectionId,
        private _name: string,
        private _date: Date,
        private _image: string,
        private _imageHeader: string,
        private _tiles: Tile[],
    ) {
    }

}
