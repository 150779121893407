import { PermissionDTO } from '../dto/permission.DTO';
import { Permission } from '../../../domain/permission';
import { Id } from '../../../domain/value-objects/id';
import { Name } from '../../../domain/value-objects/name';

export class PermissionMapper {

    public static toDomain(permissionDTO: PermissionDTO): Permission {
        return new Permission(new Id(permissionDTO.id), new Name(permissionDTO.name));
    }

}
