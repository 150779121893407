import React from 'react';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiProduct } from '../ApiProduct';

interface UseProductList {
    data: ApiProduct[];
    error: boolean;
    loading: boolean;
}
export function useOutletList(): UseProductList {
    const [data, setData] = React.useState<ApiProduct[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance()
            .getOutletCatalog(5)
            .then((products) => setData(products))
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
    };
}
