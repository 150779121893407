import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { TencerRoutes } from './shared/tencer-routing/TencerRoutes';
import { TencerAuthServiceInstance } from './shared/tencer-routing/tencer-auth-route/TencerAuthService';
import { ScrollTop } from './shared/ScrollTop';
import { Header } from './shared/Header';
import { Footer } from './shared/Footer';
import { HomeScreen } from './home/HomeScreen';
import { SignInScreen } from './login/sign-in/SignInScreen';
import { AccountScreen } from './account/application/screen/account-screen';
import { SignUpScreen } from './login/sign-up/SignUpScreen';
import { CollectionDetails } from './collection-details/CollectionDetailsScreen';
import { ProductDetailsScreen } from './product-details/ProductDetailsScreen';
import { PasswordRecoveryScreen } from './password-recovery/PasswordRecoveryScreen';
import { CartScreen } from './cart/CartScreen';
import { OrderDetailScreen } from './orders/application/detail/order-detail-screen';
import { OrdersScreen } from './orders/application/list/OrdersScreen';
import { ProductsCatalogCollectionsScreen } from './product-catalog-collections/ProductsCatalogCollectionsScreen';
import { LoadingOrderListScreen } from './loading-order/application/list/screen/loading-order-list-screen';
import { CartSummaryScreen } from './cart-summary/application/cart-summary-screen';
import { LoadingOrderDetailScreen } from './loading-order/application/detail/screen/loading-order-detail-screen';
import { useOrigin } from './shared/hooks/useOrigin';
import { Origin } from './shared/domain/value-objects/Origin';
import { EnvEnum } from './shared/domain/value-objects/EnvEnum';

export const AppRouting: React.FC = () => {
    const origin = useOrigin();

    const routes = (
        <Routes>
            <Route
                path={TencerRoutes.LOGIN_SIGN_IN}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignInScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.LOGIN_SIGN_UP}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <SignUpScreen />
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.ACCOUNT}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <AccountScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.HOME}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <HomeScreen title="home" url="/" />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTION_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <CollectionDetails />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.PRODUCT_DETAILS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <ProductDetailsScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.RESET_PASSWORD}
                element={!TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <PasswordRecoveryScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.HOME} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <CartScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDER_DETAIL}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <OrderDetailScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.ORDERS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <OrdersScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDERS}
                        element={TencerAuthServiceInstance.isAuthenticated()
                        && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header />
                                        <LoadingOrderListScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            {
                process.env.REACT_APP_ENV !== EnvEnum.PROD ? (
                    <Route
                        path={TencerRoutes.LOADING_ORDER_DETAIL}
                        element={TencerAuthServiceInstance.isAuthenticated() && origin === Origin.EUROPE
                            ? (
                                <div className="co-app">
                                    <main>
                                        <Header />
                                        <LoadingOrderDetailScreen />
                                    </main>
                                    <Footer />
                                </div>
                            ) : (
                                <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                            )}
                    />
                ) : null
            }
            <Route
                path={TencerRoutes.CART_SUMMARY}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <CartSummaryScreen />
                        </main>
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CART}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <CartScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.COLLECTIONS}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <ProductsCatalogCollectionsScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.CATALOG}
                element={TencerAuthServiceInstance.isAuthenticated() ? (
                    <div className="co-app">
                        <main>
                            <Header />
                            <ProductsCatalogCollectionsScreen />
                        </main>
                        <Footer />
                    </div>
                ) : (
                    <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                )}
            />
            <Route
                path={TencerRoutes.MARKETING}
                element={TencerAuthServiceInstance.isAuthenticated() &&
                    origin === Origin.USA ? (
                        <div className="co-app">
                            <main>
                                <Header />
                                <ProductsCatalogCollectionsScreen />
                            </main>
                            <Footer />
                        </div>
                    ) : (
                        <Navigate to={TencerRoutes.LOGIN_SIGN_IN} />
                    )}
            />
            <Route path="*" element={<Navigate to={TencerRoutes.HOME} />} />
        </Routes>
    );

    return (
        <React.Suspense fallback={null}>
            <BrowserRouter>
                <ScrollTop />
                {routes}
            </BrowserRouter>
        </React.Suspense>
    );
};
