export class Freight {

    private readonly _amount: number;

    public constructor(value: number) {
        this.ensureIsNotUndefined(value);
        this._amount = value;
    }

    private ensureIsNotUndefined(value: number): void {
        if (value === undefined) {
            throw new Error('Freight value is undefined');
        }
    }

    public get amount(): number {
        return this._amount;
    }

}
