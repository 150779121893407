import React, { useEffect } from 'react';
import { Spinner } from '../../Spinner';

interface PageSpinnerProps {
    isBusy: boolean
}
export const PageSpinner = (props: PageSpinnerProps): React.ReactElement | null => {
    useEffect(() => {
        if (props.isBusy) {
            document.body.classList.add('disabled-events');
            document.body.classList.add('overflow-hidden');
        }
        return (): void => {
            document.body.classList.remove('disabled-events');
            document.body.classList.remove('overflow-hidden');
        };
    }, [props.isBusy]);
    return props.isBusy ? (
        <Spinner classCss="validating-cart-spinner-container" />
    ) : null;
};
