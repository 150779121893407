import i18next, { t } from 'i18next';

interface UseErrorTranslationResponse{
    translate: (code: number)=> string
}

export const useErrorTranslation = ():UseErrorTranslationResponse => {

    const translate = (code:number): string => {
        if (i18next.exists(`ERROR_CODE_${code}`)) {
            return t(`ERROR_CODE_${code}`);
        }
        return t('ERROR_CODE_0');
    };
    return {
        translate,
    };
};
