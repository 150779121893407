import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOWNLOAD_PROFORMA, LOADING } from 'i18n/translations/TR';
import { CActionButton } from 'app/shared/components/CActionButton/CActionButton';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { useProforma } from '../hooks/useProforma';
import { UseCartCheckoutFormInterface } from '../../../cart-summary/application/hooks/use-cart-checkout-form';

interface Props {
    orderId: OrderId
    // eslint-disable-next-line react/require-default-props
    cartSummaryForm?: UseCartCheckoutFormInterface,
}

export const DownloadProformaButton: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const proforma = useProforma();

    return (
        <CActionButton
            text={t(DOWNLOAD_PROFORMA)}
            textIsBusy={proforma.error ? proforma.error : t(LOADING)}
            onClick={(): void => proforma.download(props.orderId)}
            isBusy={proforma.proformaLoading}
            disabled={proforma.proformaLoading || (props.cartSummaryForm && props.cartSummaryForm.POIsBusy)}
        />
    );
};
