import React from 'react';
import OrderLine from '../../model/OrderLine';
import { OrderLineItem } from './OrderLineItem';

interface LinesListProps{
    lines:OrderLine[],
}
export const OrderLinesList = (
    {
        lines,
    }:LinesListProps,
):React.ReactElement => {
    return (
        <ul className="section-list">
            {
                (
                    lines.map((line: OrderLine) => (
                        <OrderLineItem
                            line={line}
                            key={line.idx.value}
                        />
                    ))
                )
            }
        </ul>
    );
};
