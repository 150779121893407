import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLoadingOrderRepository } from './use-loading-order-repository';
import LoadingOrder from '../../domain/loading-order';
import { LoadingOrderId } from '../../domain/value-objects/loading-order-id';

export interface useLoadingOrderDetailResponse {
    downloadLoadingOrderPDF: (loadingOrderId: LoadingOrderId) => void
    loadingOrder: LoadingOrder | undefined
    isBusy: boolean
    loadingOrderId: string
    isDownloadingPDF: boolean
    downloadPdfError: boolean
    getLoadingOrderError: boolean
    hideErrorModal: () => void
}

export const useLoadingOrdersDetail = (): useLoadingOrderDetailResponse => {
    const loadingOrderRepository = useLoadingOrderRepository();
    const [loadingOrder, setLoadingOrder] = useState<LoadingOrder>();
    const [downloadPdfError, setDownloadPdfError] = useState<boolean>(false);
    const [getLoadingOrderError, setGetLoadingOrderError] = useState<boolean>(false);
    const slug = useParams<{id: string}>();

    const hideErrorModal = (): void => {
        setGetLoadingOrderError(false);
        setDownloadPdfError(false);
    };
    const getLoadingOrder = (): void => loadingOrderRepository.fetchGetLoadingOrder.mutate(
        new LoadingOrderId(slug.id!),
        {
            onSuccess: (response) => {
                setLoadingOrder(response);
            },
            onError: () => {
                setGetLoadingOrderError(true);
            },
        },
    );

    const downloadLoadingOrderPDF = (
        loadingOrderId: LoadingOrderId,
    ): void => loadingOrderRepository.fetchDownloadLoadingOrderPDF.mutate(
        loadingOrderId,
        {
            onSuccess: (response) => {
                const file = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            },
            onError: () => {
                setDownloadPdfError(true);
            },
        },
    );

    const isBusy = loadingOrderRepository.fetchGetLoadingOrder.isLoading;
    const isDownloadingPDF = loadingOrderRepository.fetchDownloadLoadingOrderPDF.isLoading;

    useEffect((): void => {
        getLoadingOrder();
    }, []);

    return {
        loadingOrder,
        isBusy,
        downloadLoadingOrderPDF,
        loadingOrderId: slug.id!,
        isDownloadingPDF,
        downloadPdfError,
        getLoadingOrderError,
        hideErrorModal,
    };
};
