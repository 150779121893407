import React from 'react';
import moment from 'moment';
import { DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IcVert } from '../../../../../../../../assets/img/icons/ic-more-vert.svg';
import LoadingOrder from '../../../../../../domain/loading-order';
import { DOWNLOAD_PDF, LOADING_ORDER_VIEW_ORDER_DETAILS } from '../../../../../../../../i18n/translations/TR';
import { LoadingOrderId } from '../../../../../../domain/value-objects/loading-order-id';

interface LoadingOrderListTableBodyItemProps {
    loadingOrder: LoadingOrder
    downloadLoadingOrderPDF: (loadingOrderId: LoadingOrderId) => void
    isDownloadingPDF: boolean
}
export const LoadingOrderListTableBodyItem = (props: LoadingOrderListTableBodyItemProps): React.ReactElement => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const goToLoadingOrderDetail = (): void => {
        navigate(`/loading-orders/${props.loadingOrder.id.value}`);
    };

    return (
        <tr key={props.loadingOrder.id.value}>
            <td className="loading-order-id">{props.loadingOrder.id.value}</td>
            <td className="loading-order-address">
                {
                    props.loadingOrder.shippingAddress ?
                        props.loadingOrder.shippingAddress.description
                        : null
                }
            </td>
            <td className="loading-order-transport-type">
                {props.loadingOrder.transportType.translate}
            </td>
            <td className="loading-order-date">
                { moment(props.loadingOrder.loadingOrderDate)
                    .locale(i18n.language)
                    .format('MMM D, YYYY')}
            </td>
            <td className="loaging-order-status">
                <span className="status-label">
                    {props.loadingOrder.status.translate}
                </span>
            </td>
            <td className="loading-order-actions">
                <div className="dropdown d-flex justify-content-end">
                    <DropdownButton
                        key="start"
                        id="dropdown-button-drop-end"
                        drop="down"
                        variant="transparent"
                        title={<IcVert className="iconDots" />}
                        className="noButtonAppearance overflow-menu"
                    >
                        <li
                            className="dropdown-item"
                            onClick={goToLoadingOrderDetail}
                            role="presentation"
                        >
                            {t(LOADING_ORDER_VIEW_ORDER_DETAILS)}
                        </li>
                        <li
                            className={!props.isDownloadingPDF ? 'dropdown-item' : 'dropdown-item disabled'}
                            onClick={
                                ():void => props.downloadLoadingOrderPDF(props.loadingOrder.id)
                            }
                            role="presentation"
                        >
                            {props.isDownloadingPDF ?
                                (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )
                                : null}
                            <span>
                                {t(DOWNLOAD_PDF)}
                            </span>
                        </li>
                    </DropdownButton>
                </div>
            </td>
        </tr>
    );
};
