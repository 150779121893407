import moment from 'moment';
import OrderToLoad from '../../../domain/order-to-load';
import { PostLoadingOrderRequestDTO } from '../dto/request/post-loading-order-request.DTO';
import { ShippingAddress } from '../../../../orders/model/ShippingAddress';
import { LoadingOrderTransportType } from '../../../domain/value-objects/loading-order-transport_type';
import { LoadingOrderTransportTypeMapper } from './loading-order-transport-type.mapper';
import { PostLoadingOrderLinesRequestDTO } from '../dto/request/post-loading-order-lines-request.DTO';
import { PostLoadingOrderLineMapper } from './post-loading-order-line.mapper';

export class PostLoadingOrderMapper {

    public static fromDomain(
        date: Date,
        orders: OrderToLoad[],
        shippingAddress: ShippingAddress,
        transportType: LoadingOrderTransportType,
    ): PostLoadingOrderRequestDTO {

        const orderLinesDto: PostLoadingOrderLinesRequestDTO[] = [];

        orders.forEach((item) => {
            item.linesToLoad.forEach((line) => {
                orderLinesDto.push(
                    PostLoadingOrderLineMapper.fromDomain(line),
                );
            });
        });

        return {
            order_lines: orderLinesDto,
            shipping_code: shippingAddress.code.value,
            date: moment(date).format(moment.defaultFormatUtc),
            transport_type: LoadingOrderTransportTypeMapper.fromDomain(transportType),
        };
    }

}
