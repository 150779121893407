import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as locale from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    ADD_LOADING_ORDER_SELECT_DATE_INSTRUCTIONS,
    CANCEL,
    OK,
    SELECT_LOADING_DATE,
} from '../../../../../i18n/translations/TR';
import './calendar-modal.scss';
import { PageSpinner } from '../../../../shared/components/page-spinner/page-spinner';
import LoadingOrderSlot from '../../../domain/loading-order-slot';
import i18n, { TencerLanguage } from '../../../../../i18n';

interface CalendarModalProps {
    showModal: boolean
    goBack: () => void
    goForward: () => void
    getLoadingOrderDatesIsLoading: boolean
    loadingOrderDate: Date | undefined
    loadingOrderSlot: LoadingOrderSlot
    handleLoadingOrderSelectedDate: (data: Date | undefined) => void
}

export const CalendarModal = (
    {
        showModal,
        goBack,
        goForward,
        getLoadingOrderDatesIsLoading,
        loadingOrderDate,
        loadingOrderSlot,
        handleLoadingOrderSelectedDate,
    }: CalendarModalProps,
):React.ReactElement => {
    const { t } = useTranslation();
    const [actualLocale, setActualLocale] = useState(TencerLanguage.ENGLISH);

    useEffect(() => {
        if (!loadingOrderDate && showModal) {
            handleLoadingOrderSelectedDate(loadingOrderSlot.from);
        }
    }, [showModal]);

    const handleLocale = (): void => {
        const lang = i18n.language;
        switch (lang) {
            case TencerLanguage.SPANISH:
                registerLocale(TencerLanguage.SPANISH, locale.es);
                setActualLocale(TencerLanguage.SPANISH);
                break;
            case TencerLanguage.FRENCH:
                registerLocale(TencerLanguage.FRENCH, locale.fr);
                setActualLocale(TencerLanguage.FRENCH);
                break;
            case TencerLanguage.ITALIAN:
                registerLocale(TencerLanguage.ITALIAN, locale.it);
                setActualLocale(TencerLanguage.ITALIAN);
                break;
            case TencerLanguage.GERMAN:
                registerLocale(TencerLanguage.GERMAN, locale.de);
                setActualLocale(TencerLanguage.GERMAN);
                break;
            default:
                setActualLocale(TencerLanguage.ENGLISH);
                break;
        }
    };

    const isWeekday = (date: Date): boolean => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    useEffect((): void => {
        handleLocale();
    }, []);

    return (
        <Modal
            backdropClassName="calendar-modal-backdrop-wrapper"
            onHide={goBack}
            show={showModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5>{t(SELECT_LOADING_DATE)}</h5>
            </Modal.Header>
            <Modal.Body>
                <PageSpinner isBusy={getLoadingOrderDatesIsLoading} />
                <div className="calendar-selector-body-container">
                    <p>{t(ADD_LOADING_ORDER_SELECT_DATE_INSTRUCTIONS)}</p>
                    <div className="calendar-container">
                        <DatePicker
                            locale={actualLocale}
                            selected={loadingOrderDate || loadingOrderSlot.from}
                            onChange={(date: Date): void => handleLoadingOrderSelectedDate(date)}
                            filterDate={isWeekday}
                            calendarStartDay={1}
                            open
                            minDate={loadingOrderSlot.from}
                            maxDate={loadingOrderSlot.until}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="shipping-address-selector-footer-container">
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={goBack}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="button"
                        form="add-form"
                        className="btn btn-primary"
                        disabled={!loadingOrderDate}
                        onClick={goForward}
                    >
                        {t(OK).toUpperCase()}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
