/* eslint-disable global-require */

export const IcSize = require('./ic-size.svg');
export const IcWeight = require('./ic-weight.svg');
export const IcMenu = require('./ic-menu.svg');
export const IcSearch = require('./ic-search.svg');
export const IcShopBag = require('./ic-shop-bag.svg');
export const IcLanguagePrimary = require('./ic-language-primary.png');
export const IcDelete = require('./ic-delete.svg');
export const IcClose = require('./ic-close-banner.svg');

/* eslint-disable global-require */
