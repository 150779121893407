import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADD, PER_FEET, PER_M2, PER_PIECE, REF } from 'i18n/translations/TR';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Origin } from 'app/shared/domain/value-objects/Origin';
import { ApiCollectionList } from './shared/ApiCollectionList';
import { Currency } from '../../shared/Currency';
import { MarketingCollectionId } from '../../products/domain/model/MarketingCollectionId';
import { usePermission } from '../../shared/hooks/use-Permission';

interface Props {
    product: ApiCollectionList
}

export const MiniSwiperCard: React.FC<Props> =
    (
        { product },
    ) => {

        const { t } = useTranslation();
        const origin = useOrigin();

        const perUnit = (): string => {
            let unitString: string = '';
            if (product.unit === '0') {
                unitString = t(PER_PIECE);
            } else if (product.unit === '1') {
                unitString = origin && origin.match(Origin.EUROPE) ? t(PER_M2) : t(PER_FEET);
            }
            return unitString;
        };

        const permission = usePermission();
        return (
            <li className="product-grid-item">
                <div className="product-grid-image">
                    {product.image ? (
                        <img src={product.image} alt="product" />
                    ) : null }
                </div>

                <div className="product-grid-item-details">
                    <div className="product-grid-title">{product.collection}</div>
                    <div className="product-grid-size">{product.format}</div>
                    <div className="product-grid-color">{product.family}</div>
                    <div className="product-grid-finish">{product.description}</div>
                    <div className="product-grid-ref">{`${t(REF)}${product.product}`}</div>
                </div>
                <div className="product-grid-item-buy">
                    {product.price !== null ?
                        (
                            <div className="product-grid-item-footer">
                                <div className="product-grid-price">
                                    <div className="product-grid-import">
                                        <Currency
                                            className="product-price import"
                                            value={product?.price.value}
                                        />
                                    </div>
                                    <div className="product-grid-units-type">{perUnit()}</div>
                                </div>
                            </div>
                        ) : null}
                    {permission.canDoOrders() && product.collectionId !== MarketingCollectionId ?
                        (
                            <button
                                type="button"
                                className="btn btn-secondary btn-mini"
                            >
                                {t(ADD)}
                            </button>
                        ) : null}
                </div>
            </li>
        );
    };
