import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import MarketingCollection from '../../../domain/model/MarketingCollection';
import { CollectionRepositoryInterface } from '../../../domain/repository/CollectionRepositoryInterface';
import { MarketingCollectionMapper } from './data-mapper/MarketingCollectionMapper';

export class CollectionRepository implements CollectionRepositoryInterface {

    public constructor(
        private readonly client: TencerApiClient,
    ) {

    }

    async getMarketingCollection(): Promise<MarketingCollection> {
        const response = await this.client.getMarketingCollection();
        return MarketingCollectionMapper.fromResponse(response);
    }

}
