import { TencerApiClient } from '../infrastructure/tencer-api/TencerApiClient';
import { TencerRoutes } from '../tencer-routing/TencerRoutes';

export class TencerApiClientSingleton {

    private static instance:TencerApiClient|null = null;

    private constructor() { }

    public static getInstance(): TencerApiClient {
        if (!TencerApiClientSingleton.instance) {
            TencerApiClientSingleton.instance = new TencerApiClient(TencerRoutes.LOGIN_SIGN_IN);
        }

        return TencerApiClientSingleton.instance;
    }

}
