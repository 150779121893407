import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLLECTIONS, NO_RESULTS } from 'i18n/translations/TR';
import { SectionHeaderCollections } from 'app/shared/SectionHeaderCollections';
import { ApiCollection } from 'app/home/featured-collections/shared/domain/ApiCollection';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ProductSort } from '../../products/domain/model/ProductSort';
import { CollectionItem } from './components/CollectionsItem';
import { useCollectionList } from './components/shared/CollectionsList';

export const ProductCollectionsScreen: React.FC = () => {
    const { t } = useTranslation();

    const urlRouter = TencerRoutes.COLLECTION_DETAILS;
    const urlRouterFormat = urlRouter.replace(':id', '');

    let serverErrorMessage: boolean = false;

    const responseCollections = useCollectionList();
    const isLoading = responseCollections?.loading;
    const collections: ApiCollection[] = responseCollections?.data;
    const error = responseCollections?.error;

    if (error) {
        serverErrorMessage = true;
    }

    const [page, setPage] = useState(2);

    const fetchCollections = async ():Promise<ApiCollection[]> => {
        return TencerApiClientSingleton.getInstance()
            .getCollections(page, ProductSort.Alphabetical, responseCollections.pageResultsDefault);
    };

    const fetchData = async (): Promise<void> => {
        const collectionsFromApi = await fetchCollections();
        collectionsFromApi.map((product: ApiCollection) => (
            collections.push(product)
        ));

        if (collectionsFromApi.length === 0 || collectionsFromApi.length < responseCollections.pageResultsDefault) {
            responseCollections.setHasMore(false);
        }
        setPage(page + 1);
    };

    return (
        <div
            className={`tab-pane fade
            ${window.location.pathname === TencerRoutes.COLLECTIONS ? 'show active' : ''}`}
            id="pills-collection"
            role="tabpanel"
            aria-labelledby="pills-collection-tab"
        >

            <SectionHeaderCollections title={t(COLLECTIONS)} />

            {!isLoading ?
                (
                    <div>
                        {!serverErrorMessage ?
                            (
                                <InfiniteScroll
                                    dataLength={collections.length}
                                    next={fetchData}
                                    hasMore={responseCollections.hasMore}
                                    loader={<Spinner classCss="basic-spinner-container" />}
                                >
                                    {collections.length ?
                                        (
                                            <section className="container section-grid">
                                                {
                                                    collections.map((collection) => (
                                                        <Link
                                                            className="text-decoration-none w-100"
                                                            to={`${urlRouterFormat}${collection.id}`}
                                                            key={collection.id}
                                                        >
                                                            <CollectionItem
                                                                collection={collection}
                                                            />
                                                        </Link>
                                                    ))
                                                }
                                            </section>
                                        ) : <div>{t(NO_RESULTS)}</div>}
                                </InfiniteScroll>
                            )
                            : <ServerErrorMessage />}
                    </div>
                ) : <Spinner classCss="header-section-spinner" />}
        </div>
    );
};
