class TencerAuthService {

    private static readonly KEY_TOKEN = 'token';
    private static readonly KEY_REFRESH = 'refresh_token';
    private static readonly KEY_LANG = 'lang';

    private static INSTANCE = new TencerAuthService();

    private _nextRoute: string;

    private constructor() {
        this._nextRoute = '';
    }

    public static getInstance(): TencerAuthService {
        return TencerAuthService.INSTANCE;
    }

    public authenticate(token: string): void {
        window.localStorage.setItem(TencerAuthService.KEY_TOKEN, token);
    }

    public isAuthenticated(): boolean {
        return !!window.localStorage.getItem(TencerAuthService.KEY_TOKEN);
    }

    public getToken(): string | null {
        return window.localStorage.getItem(TencerAuthService.KEY_TOKEN);
    }

    public setRefreshToken(item: string): void {
        window.localStorage.setItem(TencerAuthService.KEY_REFRESH, item);
    }

    public getRefreshToken(): string {
        return window.localStorage.getItem(TencerAuthService.KEY_REFRESH) || '';
    }

    public setLanguage(item: string): void {
        window.localStorage.setItem(TencerAuthService.KEY_LANG, item);
    }

    public getLanguage(): string {
        return window.localStorage.getItem(TencerAuthService.KEY_LANG) || '';
    }

    public clear():void {
        window.localStorage.removeItem(TencerAuthService.KEY_TOKEN);
        window.localStorage.removeItem(TencerAuthService.KEY_REFRESH);
    }

}

export const TencerAuthServiceInstance = TencerAuthService.getInstance();
