import { OrderId } from '../../shared/domain/value-objects/OrderId';
import OrderLineToLoad from './order-line-to-load';

export default class OrderToLoad {

    private readonly _id: OrderId;
    public get id(): OrderId {
        return this._id;
    }

    private readonly _po: string;
    public get po(): string {
        return this._po;
    }

    private readonly _orderToLoadDate: Date;
    public get orderToLoadDate(): Date {
        return this._orderToLoadDate;
    }

    private readonly _linesToLoad: OrderLineToLoad[];
    public get linesToLoad(): OrderLineToLoad[] {
        return this._linesToLoad;
    }

    constructor(
        id: OrderId,
        po: string,
        orderToLoadDate: Date,
        linesToLoad: OrderLineToLoad[],
    ) {
        this._id = id;
        this._po = po;
        this._orderToLoadDate = orderToLoadDate;
        this._linesToLoad = linesToLoad;
    }

}
