import React from 'react';
import { useTranslation } from 'react-i18next';
import { NO_ITEMS_LOADED_MESSAGE } from '../../../../../../i18n/translations/TR';
import { ReactComponent as IcList } from '../../../../../../assets/img/icons/ic-list-gray.svg';
import './no-items-loaded-message.scss';

export const NoItemsLoadedMessage = (): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="no-items-loaded-message-container">
            <IcList />
            <p>

                {t(NO_ITEMS_LOADED_MESSAGE)}
            </p>
        </div>
    );
};
