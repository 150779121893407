import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ALL_RIGHTS_FOOTER,
    CONTACT_FOOTER,
    CONTACT_US_FOOTER,
    DISCLAIMER_FOOTER,
    FACEBOOK_FOOTER,
    INSTAGRAM_FOOTER,
    LINKEDIN_FOOTER,
    PINTEREST_FOOTER,
    PRIVACY_POLICY_FOOTER,
    TERMS_CONDITIONS_FOOTER,
    WE_CARE_ABOUT_FOOTER,
    OPEN_HOURS,
    COPYRIGHT,
    OPEN_HOURS_CA,
    OPEN_HOURS_VA,
    LOCATION_CA,
    LOCATION_VA,
    TEL,
} from 'i18n/translations/TR';

import { LogoWow } from 'assets/img';
import {
    FACEBOOK_HREF,
    LINKEDIN_HREF,
    INSTAGRAM_HREF,
    PINTEREST_HREF,
    CONTACT_EMAIL,
} from 'app/constants/hrefConstants';
import { LocationBlock } from './LocationBlock';

export const Footer: React.FC = () => {

    const { t } = useTranslation();

    const year: number = new Date().getFullYear();

    const addresses: {
        id: number;
        locationName: string;
        locationType: string;
        address: string;
        address2: string;
        phoneNumber1: string;
        phoneNumber2: string;
        schedule: string;
        usaCountry: string;
    }[] = [
        {
            id: 0,
            locationName: 'WOW HEADQUARTERS',
            locationType: '',
            address: 'Polígono Pont 12180 Cabanes',
            address2: 'Castellón. Spain',
            phoneNumber1: `${t(TEL)} (+34) 964 331 910`,
            phoneNumber2: `${t(TEL)} (+34) 964 331 911`,
            schedule: t(OPEN_HOURS),
            usaCountry: '',
        },
        {
            id: 1,
            locationName: 'LOGISTICS CENTER',
            locationType: '',
            address: 'Ctra Castellon-Alcora CV-16, km 8 12130 Sant Joan de Moro',
            address2: 'Castellón. Spain',
            phoneNumber1: `${t(TEL)} (+34) 964 331 910`,
            phoneNumber2: `${t(TEL)} (+34) 964 331 911`,
            schedule: t(OPEN_HOURS),
            usaCountry: '',
        },
        {
            id: 2,
            locationName: 'WOW USA, Inc. VA',
            locationType: t(LOCATION_VA),
            address: '9408 GUNSTON COVE RD. Suite C-D',
            address2: 'LORTON VA 22079 ',
            phoneNumber1: `${t(TEL)} (703) 910-6162`,
            phoneNumber2: '',
            schedule: t(OPEN_HOURS_VA),
            usaCountry: 'United States',
        },
        {
            id: 3,
            locationName: 'WOW USA, Inc. CA',
            locationType: t(LOCATION_CA),
            address: '1548 S STATE COLLEGE BLVD',
            address2: 'ANAHEIM CA 92806',
            phoneNumber1: `${t(TEL)} (714) 940-0705`,
            phoneNumber2: '',
            schedule: t(OPEN_HOURS_CA),
            usaCountry: 'United States',
        },
    ];

    return (
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 pb-3">
                        <ul className="list-unstyled footer-legal">
                            <li>
                                <a className="logo-footer" href="index.html">
                                    <img src={LogoWow} alt="logo" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="legalAdvice.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="small"
                                >
                                    {t(DISCLAIMER_FOOTER)}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="cookiesPolicy.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="small"
                                >
                                    {t(TERMS_CONDITIONS_FOOTER)}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="privacyPolicy.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="small"
                                >
                                    {t(PRIVACY_POLICY_FOOTER)}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-8 pb-5">
                        <div className="footer-contact">
                            <h3>{t(WE_CARE_ABOUT_FOOTER)}</h3>
                            <p className="small">{t(CONTACT_US_FOOTER)}</p>
                            <a href={`mailto:${CONTACT_EMAIL}`} className="btn btn-success">
                                {t(CONTACT_FOOTER)}
                            </a>
                        </div>
                    </div>

                    <div className="locations-container border-top">
                        {addresses.map((address) => (
                            <LocationBlock
                                key={address.id}
                                id={address.id}
                                locationName={address.locationName}
                                locationType={address.locationType}
                                address={address.address}
                                address2={address.address2}
                                phoneNumber1={address.phoneNumber1}
                                phoneNumber2={address.phoneNumber2}
                                schedule={address.schedule}
                                usaCountry={address.usaCountry}
                            />
                        ))}
                    </div>

                    <div className="bottom-footer">
                        <div className="col-sm-12 col-md-3 text-start">
                            <ul className="list-unstyled">
                                <li>
                                    <a className="small" href="index.html">
                                        {`${t(COPYRIGHT)} ${year} ${t(ALL_RIGHTS_FOOTER)}`}
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-md-6 d-flex justify-content-center">
                            <ul className="list-unstyled social-links">
                                <li>
                                    <a href={FACEBOOK_HREF}>{t(FACEBOOK_FOOTER)}</a>
                                </li>
                                <li>
                                    <a href={LINKEDIN_HREF}>{t(LINKEDIN_FOOTER)}</a>
                                </li>
                                <li>
                                    <a href={INSTAGRAM_HREF}>{t(INSTAGRAM_FOOTER)}</a>
                                </li>
                                <li>
                                    <a href={PINTEREST_HREF}>{t(PINTEREST_FOOTER)}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
