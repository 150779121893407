import i18next from 'i18next';
import { ERROR_500 } from '../../../../i18n/translations/TR';

export class Email {

    private readonly _email: string;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this._email = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined || value === '') {
            throw i18next.t(ERROR_500); // Todo crear error específico
        }
    }

    public get email(): string {
        return this._email;
    }

}
