import React from 'react';
import { UseCartSummaryResponse } from '../../../../hooks/use-cart-summary';
import { OrderShippingAddressSelectorModal } from './shipping-address-selector-modal/order-shipping-address-selector-modal';
import { UserCredentialId } from '../../../../../../shared/domain/value-objects/UserCredentialId';

interface IOrderShippingAddressProps {
    UseCartSummary: UseCartSummaryResponse
}
export const OrderShippingAddress = ({
    UseCartSummary,
}: IOrderShippingAddressProps): React.ReactElement => {

    return (
        <div className="order-shipping-address-container">
            {UseCartSummary.cartSummary.erpUserId && UseCartSummary.tokenPayload?.username ? (
                <OrderShippingAddressSelectorModal
                    erpUserId={UseCartSummary.cartSummary.erpUserId}
                    userCredentialId={
                        new UserCredentialId(UseCartSummary.tokenPayload.username)
                    }
                    countries={UseCartSummary.UseAccount.countries}
                    afterChangeShippingAddressDefault={UseCartSummary.afterChangeShippingAddressDefault}
                />
            ) : null}
        </div>
    );
};
