import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ORDER_DATE,
    ORDERS_TO_LOAD_TABLE_COLUMN_ORDER_NO,
    PO_LABEL,
} from '../../../../../../../i18n/translations/TR';

export const OrderToLoadTableHead = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <thead>
            <tr>
                <th>{t(ORDERS_TO_LOAD_TABLE_COLUMN_ORDER_NO)}</th>
                <th>{t(PO_LABEL)}</th>
                <th>{t(ORDER_DATE)}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
                <th>{}</th>
            </tr>
        </thead>
    );
};
