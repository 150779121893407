import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    ADD_LOADING_ORDER_SELECT_ADDRESS_INSTRUCTIONS,
    CANCEL,
    CONTINUE,
    CREATE_LOADING_ORDER,
} from '../../../../../i18n/translations/TR';
import './shipping-address-selector-modal.scss';
import { TypeAheadArrayDTO } from '../../../../shared/infrastructure/tencer-api/dto/typeAheadArray.DTO';
import { TypeAheadDTO } from '../../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';
import { ShippingAddressSelectorForm } from './form/shipping-address-selector-form';
import { LoadingOrderTransportType } from '../../../domain/value-objects/loading-order-transport_type';

interface ShippingAddressSelectorModalProps {
    isBusy: boolean
    showModal: boolean
    goBack: () => void
    goForward: () => void
    shippingAddresses: TypeAheadArrayDTO
    loadingOrderShippingAddress: TypeAheadDTO | undefined
    loadingOrderTransportType: LoadingOrderTransportType | undefined
    handleLoadingOrderShippingAddress: (shippingAddress: TypeAheadDTO | undefined) => void
    handleLoadingOrderTransportType: (transportType: LoadingOrderTransportType | undefined) => void
}
export const ShippingAddressSelectorModal = (
    {
        isBusy,
        goBack,
        showModal,
        goForward,
        shippingAddresses,
        loadingOrderTransportType,
        loadingOrderShippingAddress,
        handleLoadingOrderTransportType,
        handleLoadingOrderShippingAddress,
    }: ShippingAddressSelectorModalProps,
):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            onHide={goBack}
            show={showModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5>{t(CREATE_LOADING_ORDER)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div className="shipping-address-selector-body-container">
                    <p className="shipping-address-selector-body-text">
                        {t(ADD_LOADING_ORDER_SELECT_ADDRESS_INSTRUCTIONS)}
                    </p>
                    <ShippingAddressSelectorForm
                        isBusy={isBusy}
                        shippingAddresses={shippingAddresses}
                        loadingOrderTransportType={loadingOrderTransportType}
                        loadingOrderShippingAddress={loadingOrderShippingAddress}
                        handleLoadingOrderTransportType={handleLoadingOrderTransportType}
                        handleLoadingOrderShippingAddress={handleLoadingOrderShippingAddress}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="shipping-address-selector-footer-container">
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={goBack}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="button"
                        form="add-form"
                        className="btn btn-primary"
                        disabled={!loadingOrderShippingAddress || !loadingOrderTransportType}
                        onClick={goForward}
                    >
                        {t(CONTINUE)}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
