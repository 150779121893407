import { usePagination, usePaginationInterface } from 'app/shared/hooks/usePagination';
import { useEffect, useState } from 'react';
import { useLoadingOrderRepository } from './use-loading-order-repository';
import LoadingOrder from '../../domain/loading-order';
import { LoadingOrderId } from '../../domain/value-objects/loading-order-id';

export interface useLoadingOrderListResponse {
    downloadLoadingOrderPDF: (loadingOrderId: LoadingOrderId) => void
    getLoadingOrders: (actualPage?: number) => void
    paginator: usePaginationInterface
    loadingOrders: LoadingOrder []
    isBusy: boolean
    refreshLoadingOrders: () => void
    isDownloadingPDF: boolean
}

export const useLoadingOrderList = (): useLoadingOrderListResponse => {
    const paginator = usePagination();
    const loadingOrderRepository = useLoadingOrderRepository();
    const [loadingOrders, setLoadingOrders] = useState<LoadingOrder[]>([]);

    const getLoadingOrders = (
        actualPage?: number,
    ): void => {
        loadingOrderRepository.fetchGetLoadingOrderMutation.mutate(
            {
                actualPage: actualPage || paginator.actualPage,
                resultsPerPage: paginator.resultsPerPage,
            },
            {
                onSuccess: (response) => {
                    if (actualPage) {
                        setLoadingOrders(response);
                        paginator.resetPaginator(response.length);
                    } else {
                        const allResults = [...loadingOrders, ...response];
                        setLoadingOrders(allResults);
                        paginator.refresh(response.length);
                    }
                },
            },
        );
    };

    const downloadLoadingOrderPDF = (
        loadingOrderId: LoadingOrderId,
    ): void => loadingOrderRepository.fetchDownloadLoadingOrderPDF.mutate(
        loadingOrderId,
        {
            onSuccess: (response) => {
                const file = new Blob([response], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            },
        },
    );

    const refreshLoadingOrders = (): void => {
        setLoadingOrders([]);
        getLoadingOrders(1);
    };

    const isBusy = loadingOrderRepository.fetchGetLoadingOrderMutation.isLoading;

    const isDownloadingPDF = loadingOrderRepository.fetchDownloadLoadingOrderPDF.isLoading;

    useEffect((): void => {
        getLoadingOrders();
    }, []);

    return {
        paginator,
        loadingOrders,
        isBusy,
        downloadLoadingOrderPDF,
        getLoadingOrders,
        refreshLoadingOrders,
        isDownloadingPDF,
    };
};
