import React from 'react';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiProduct } from 'app/home/product/shared/domain/ApiProduct';

interface UseProductList {
    data: ApiProduct[];
    error: boolean;
    loading: boolean;
    more: boolean;
}

export function useCatalogList(url: string): UseProductList {
    const [data, setData] = React.useState<ApiProduct[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [more, setMore] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (url.length > 0) {
            TencerApiClientSingleton.getInstance().getCatalogByUrl(url)
                .then((products) => {
                    setData(products);
                    if (products.length < 30) {
                        setMore(false);
                    }
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => setLoading(false));
        }
    }, []);

    return {
        data,
        error,
        loading,
        more,
    };
}
