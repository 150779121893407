import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SELECT_COUNTRY, SHIPPING_ADDRESS_LABEL_COUNTRY } from '../../../../../../i18n/translations/TR';
import { CountryByProvince } from '../../../../model/countryByProvince';

interface ShippingAddressCountryFieldProps {
    countryRegister: UseFormRegisterReturn
    errorField: FieldError | undefined
    countries: CountryByProvince[],

}

export const ShippingAddressCountryField = (
    {
        countryRegister,
        errorField,
        countries,
    }: ShippingAddressCountryFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="form-field country-field">
            <label htmlFor="country" className="form-label is-required">
                {t(SHIPPING_ADDRESS_LABEL_COUNTRY)}
            </label>
            <select
                {...countryRegister}
                className="form-select"
                id="country"
            >
                <option value="">{t(SELECT_COUNTRY)}</option>
                {countries.map((countryByProvince) => (
                    <option
                        key={countryByProvince.country.countryId}
                        value={countryByProvince.country.countryId}
                    >
                        {countryByProvince.country.name}
                    </option>
                ))}
            </select>
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
        </div>
    );
};
