import {
    ETransportTypeDTO,
    OrderShipmentResponse,
} from '../../../../../shared/infrastructure/tencer-api/dto/responses/order-shipment-response';
import Transport from '../../../../model/transport';
import { ETransportType, TransportType } from '../../../../model/value-objects/transport-type';
import { Freight } from '../../../../model/value-objects/freight';
import { LiftGateRate } from '../../../../model/value-objects/lift-gate-rate';

export class TransportMapper {

    private static transportTypeToDomain(type: ETransportTypeDTO): ETransportType {
        switch (type) {
            case ETransportTypeDTO.WOW:
                return ETransportType.WOW;
            case ETransportTypeDTO.OWN_LOGISTIC:
                return ETransportType.OWN_LOGISTIC;
            case ETransportTypeDTO.WILL_CALL:
                return ETransportType.WILL_CALL;
            case ETransportTypeDTO.SMALL_PARCEL:
            default:
                return ETransportType.SMALL_PARCEL;
        }
    }
    static fromResponse(response: OrderShipmentResponse): Transport {
        return new Transport(
            new TransportType(this.transportTypeToDomain(response.type.value)),
            response.consolidated ? response.consolidated : false,
            response.description,
            response.freight_price ? new Freight(response.freight_price) : null,
            response.lift_gate_price ? new LiftGateRate(response.lift_gate_price) : null,
        );
    }

}
