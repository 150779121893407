import { t } from 'i18next';
import React from 'react';
import { ACCOUNT_FIELD_DESCRIPTION, ACCOUNT_FIELD_EMAIL, ACCOUNT_FIELD_PHONE } from '../../../../../../../../i18n/translations/TR';
import { ReactComponent as IcDelete } from '../../../../../../../../assets/img/icons/ic-delete.svg';
import { AccountContactModalDelete } from '../account-contact-modal-delete/account-contact-modal-delete';

interface AccountCardContactItemProps {
    description: string;
    email: string;
    phone: string;
    notifications: string[]
    showDeleteContactModal:boolean
    handleShowDeleteContactModal: () => void
    handleHideDeleteContactModal: () => void
    deleteErpUserContact: (email:string) => void
    deleteErpUserContactIsLoading:boolean
}

export const AccountCardContactItem = (
    {
        description,
        email,
        phone,
        showDeleteContactModal,
        handleHideDeleteContactModal,
        handleShowDeleteContactModal,
        deleteErpUserContact,
        notifications,
        deleteErpUserContactIsLoading,
    }: AccountCardContactItemProps,
): React.ReactElement => {
    return (
        <form className="row account-card-contact-container">
            <div className="account-card-contact-description">
                <label htmlFor="contactDescription" className="form-label">{t(ACCOUNT_FIELD_DESCRIPTION)}</label>
                <input
                    type="text"
                    className="form-control"
                    id="contactDescription"
                    defaultValue={description}
                />
            </div>
            <div className="account-card-contact-email">
                <label htmlFor="contactEmail" className="form-label">{t(ACCOUNT_FIELD_EMAIL)}</label>
                <input
                    type="email"
                    className="form-control"
                    id="contactEmail"
                    defaultValue={email}
                />
            </div>
            <div className="account-card-contact-phone">
                <label htmlFor="contactPhone" className="form-label">{t(ACCOUNT_FIELD_PHONE)}</label>
                <input
                    type="text"
                    className="form-control"
                    id="contactPhone"
                    defaultValue={phone}
                />
            </div>
            <div className="account-card-contact-notifications-container">
                <label htmlFor="notificationTitle" className="form-label">
                    {t('ACCOUNT_NOTIFICATIONS_TITLE')}
                </label>
                {notifications
                    ? notifications.map((notification) => (
                        <div className="form-check" key={notification}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="notification1"
                                defaultValue={notification}
                            />
                            <label htmlFor="notification1" className="form-check-label">
                                {notification}
                            </label>
                        </div>
                    ))
                    : null}
            </div>

            <div className="account-card-contact-delete-icon-container">
                <button type="button" className="btn-icon-delete" onClick={handleShowDeleteContactModal}>
                    <IcDelete />
                </button>
            </div>

            <AccountContactModalDelete
                handleClose={handleHideDeleteContactModal}
                showModal={showDeleteContactModal}
                deleteContactIsLoading={deleteErpUserContactIsLoading}
                deleteErpUserContact={deleteErpUserContact}
                contactToDelete={email}
            />
        </form>
    );
};
