import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    ADD_LOADING_ORDER_SUMMARY_INSTRUCTIONS,
    CANCEL,
    LOADING_DATE,
    LOADING_ORDER_SUMMARY, LOADING_ORDER_TRANSPORT_TYPE,
    ORDERS_TABLE_COLUMN_SHIPING_ADDRESS,
    PACKAGES_PALLETS,
    SEND,
    SUMMARY,
    TOTAL_PRODUCTS,
    TOTAL_WEIGHT,
} from '../../../../../i18n/translations/TR';
import './summary-modal.scss';
import { PageSpinner } from '../../../../shared/components/page-spinner/page-spinner';
import i18n from '../../../../../i18n';
import { COWeight } from '../../../../shared/components/CO-weight';
import { TypeAheadDTO } from '../../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';
import { LoadingOrderTransportType } from '../../../domain/value-objects/loading-order-transport_type';

interface SummaryModalProps {
    isBusy: boolean
    packages: number
    showModal: boolean
    goBack: () => void
    totalWeight: number
    totalProducts: number
    goForward: () => void
    loadingOrderDate: Date | undefined
    loadingOrderShippingAddress: TypeAheadDTO | undefined
    loadingOrderTransportType: LoadingOrderTransportType | undefined
}

export const SummaryModal = (
    {
        goBack,
        isBusy,
        packages,
        goForward,
        showModal,
        totalWeight,
        totalProducts,
        loadingOrderDate,
        loadingOrderTransportType,
        loadingOrderShippingAddress,
    }: SummaryModalProps,
):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            backdropClassName="calendar-modal-backdrop-wrapper"
            onHide={goBack}
            show={showModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h5>{t(SUMMARY)}</h5>
            </Modal.Header>
            <Modal.Body>
                <PageSpinner isBusy={isBusy} />
                <div className="summary-body-container">
                    <p className="summary-body-container-title">
                        {t(ADD_LOADING_ORDER_SUMMARY_INSTRUCTIONS)}
                    </p>
                    <div className="summary-container">
                        <div className="summary-card">
                            <p className="summary-card-title">
                                {t(LOADING_ORDER_SUMMARY)}
                            </p>
                            <div className="summary-info-container">
                                <div className="summary-info-item">
                                    <p className="summary-info-item-title">
                                        {t(PACKAGES_PALLETS)}
                                    </p>
                                    <span className="summary-info-item-info">
                                        {packages}
                                    </span>
                                </div>
                                <div className="summary-info-item">
                                    <p className="summary-info-item-title">
                                        {t(TOTAL_PRODUCTS)}
                                    </p>
                                    <span className="summary-info-item-info">
                                        {totalProducts}
                                    </span>
                                </div>
                                <div className="summary-info-item">
                                    <p className="summary-info-item-title">
                                        {t(TOTAL_WEIGHT)}
                                    </p>
                                    <span className="summary-info-item-info">
                                        <COWeight value={totalWeight} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="summary-card">
                            <p className="summary-card-title">
                                {t(ORDERS_TABLE_COLUMN_SHIPING_ADDRESS)}
                            </p>
                            <span className="summary-card-info">
                                {loadingOrderShippingAddress?.item.buildAddressInfo()}
                            </span>
                        </div>
                        <div className="summary-card">
                            <p className="summary-card-title">
                                {t(LOADING_ORDER_TRANSPORT_TYPE)}
                            </p>
                            <span className="summary-card-info">
                                {loadingOrderTransportType?.translate}
                            </span>
                        </div>
                        <div className="summary-card">
                            <p className="summary-card-title">
                                {t(LOADING_DATE)}
                            </p>
                            <span className="summary-card-info">
                                {
                                    loadingOrderDate
                                        ? moment(loadingOrderDate)
                                            .locale(i18n.language)
                                            .format('L')
                                        : ''
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="shipping-address-selector-footer-container">
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={goBack}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="button"
                        form="add-form"
                        className="btn btn-primary"
                        onClick={goForward}
                    >
                        {t(SEND)}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
