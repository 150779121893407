import React, { useState } from 'react';
import { UseMutationResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_ADD_CONTACT, ACCOUNT_CONTACTS_SUBTITLE, ACCOUNT_CONTACTS_TITLE } from '../../../../../../../i18n/translations/TR';
import { ReactComponent as IcAdd } from '../../../../../../../assets/img/icons/ic-add-primary.svg';
import { AccountCardContactItem } from './account-card-contact-item/account-card-contact-item';
import { ErpContactsArray } from '../../../../../domain/erp-contacts-array';
import { AddErpUserContactRequestDTO } from '../../../../../infrastructure/api/dto/request/add-erp-user-contact-request.DTO';
import { AccountContactModalAdd } from './account-contact-modal-add/account-contact-modal-add';

interface AccountContactsCardProps{
    erpUserContacts: ErpContactsArray
    showDeleteContactModal:boolean
    handleShowDeleteContactModal: () => void
    handleHideDeleteContactModal: () => void
    deleteErpUserContact: (email:string) => void
    deleteErpUserContactIsLoading:boolean
    mutationAddErpUserContact: UseMutationResult<string, unknown, AddErpUserContactRequestDTO, unknown>
}
export const AccountContactsCard = (
    {
        erpUserContacts,
        showDeleteContactModal,
        handleHideDeleteContactModal,
        handleShowDeleteContactModal,
        deleteErpUserContactIsLoading,
        deleteErpUserContact,
        mutationAddErpUserContact,
    }:AccountContactsCardProps,
):React.ReactElement => {
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const handleShowAddContactModal = ():void => {
        setShowAddContactModal(true);
    };

    const handleHideAddContactModal = ():void => {
        setShowAddContactModal(false);
    };

    const { t } = useTranslation();

    return (
        <div className="account-card">
            <h5 className="card-wow-title">{t(ACCOUNT_CONTACTS_TITLE)}</h5>
            <p className="account-card-subtitle">{t(ACCOUNT_CONTACTS_SUBTITLE)}</p>

            {
                erpUserContacts.erpContacts.map((erpContact):React.ReactElement => (
                    <AccountCardContactItem
                        key={erpContact.email.email}
                        description={erpContact.description.description}
                        email={erpContact.email.email}
                        phone={erpContact.phoneNumber.value}
                        notifications={erpContact.events}
                        showDeleteContactModal={showDeleteContactModal}
                        handleHideDeleteContactModal={handleHideDeleteContactModal}
                        handleShowDeleteContactModal={handleShowDeleteContactModal}
                        deleteErpUserContact={deleteErpUserContact}
                        deleteErpUserContactIsLoading={deleteErpUserContactIsLoading}
                    />
                ))
            }
            <div className="add-contact-container">
                <div className="btn btn-link add-contact-item" onClick={handleShowAddContactModal} aria-hidden>
                    <IcAdd />
                    <span className="add-contact-item-text">{t(ACCOUNT_ADD_CONTACT)}</span>
                </div>
            </div>

            <AccountContactModalAdd
                handleClose={handleHideAddContactModal}
                showModal={showAddContactModal}
                addContactIsLoading={mutationAddErpUserContact.isLoading}
                mutationAddErpUserContact={mutationAddErpUserContact}
                notifications={['notificacion 1', 'notificacion 2']}
            />
        </div>

    );
};
