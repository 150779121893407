export class OrderLineToLoadId {

    private _idx: number;
    public get idx(): number {
        return this._idx;
    }

    private _year: number;
    public get year(): number {
        return this._year;
    }

    private _orderNumber: number;
    public get orderNumber(): number {
        return this._orderNumber;
    }

    constructor(
        idx: number,
        year: number,
        orderNumber: number,
    ) {
        this._idx = idx;
        this._year = year;
        this._orderNumber = orderNumber;
    }

}
