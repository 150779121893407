import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShippingAddressRepository } from '../../../orders/application/hooks/useShippingAddressRepository';
import { TypeAheadArrayDTO } from '../../../shared/infrastructure/tencer-api/dto/typeAheadArray.DTO';
import { ErpUserId } from '../../../account/domain/value-objects/erp-user-id';
import { ShippingAddressMapper } from '../../../orders/infrastructure/repository/api/mappers/ShippingAddressMapper';
import { useToken } from '../../../shared/hooks/use-token';
import { TypeAheadDTO } from '../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';
import { useLoadingOrderRepository } from './use-loading-order-repository';
import OrderToLoad from '../../domain/order-to-load';
import OrderLineToLoad from '../../domain/order-line-to-load';
import LoadingOrderSlot from '../../domain/loading-order-slot';
import { LOADING_ORDER_WITHOUT_LINES_MESSAGE, UNEXPECTED_ERROR } from '../../../../i18n/translations/TR';
import {
    ELoadingOrderTransportType,
    LoadingOrderTransportType,
} from '../../domain/value-objects/loading-order-transport_type';
import { ErrorCode } from '../../../shared/domain/value-objects/ErrorCode';

export interface useLoadingOrderListResponse {
    goBack: () => void
    goForward: () => void
    ordersLoaded: OrderToLoad[]
    ordersToLoad: OrderToLoad[]
    currentStep: LoadingOrderAddStep
    getOrdersToLoadIsLoading: boolean
    calculateTotalWeight: () => number
    postLoadingOrderIsLoading: boolean
    calculateTotalProducts: () => number
    numberOfPackages: number | undefined
    hideOrderToLoadErrorModal: () => void
    getShippingAddressesIsLoading: boolean
    showContainerWeightErrorModal: boolean
    getLoadingOrderDatesIsLoading: boolean
    containerMaxWeight: number | undefined
    getContainerMaxWeightIsLoading: boolean
    hideContainerWeightErrorModal: () => void
    getLoadingOrderPackagesIsLoading: boolean
    loadingOrderSelectedDate: Date | undefined
    orderToLoadErrorMessage: string | undefined
    shippingAddressesTypeAhead: TypeAheadArrayDTO
    loadingOrderSlot: LoadingOrderSlot | undefined
    loadingOrderShippingAddress: TypeAheadDTO | undefined
    moveOrderToLoaded: (orderToMove: OrderToLoad) => void
    checkIfOrderIsLoaded: (order: OrderToLoad) => boolean
    checkIfContainerMaxWeightHasBeenExceeded: () => boolean
    removeOrderFromLoaded: (orderToRemove: OrderToLoad) => void
    handleLoadingOrderSelectedDate: (data: Date | undefined) => void
    loadingOrderTransportType: LoadingOrderTransportType | undefined
    moveOrderLineToLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    checkIfOrderLineIsLoaded: (order: OrderToLoad, line: OrderLineToLoad) => boolean
    removeOrderLineFromLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    handleLoadingOrderShippingAddress: (shippingAddress: TypeAheadDTO | undefined) => void
    handleLoadingOrderTransportType: (transportType: LoadingOrderTransportType | undefined) => void
}

export enum LoadingOrderAddStep {
    'SHIPPING_ADDRESS_SELECTOR_STEP' = 'SHIPPING_ADDRESS_SELECTOR_STEP',
    'ORDERS_TO_LOAD_STEP' = 'ORDERS_TO_LOAD_STEP',
    'SCHEDULE_SELECTOR_STEP' = 'SCHEDULE_SELECTOR_STEP',
    'SUMMARY_STEP' = 'SUMMARY_STEP',
}

// TODO (Álvaro) split into hooks for better readability
export const useLoadingOrderAdd = (
    hideLoadingOrdersAddScreen: () => void,
    afterAddingLoadingScreen: () => void,
): useLoadingOrderListResponse => {
    const UseToken = useToken();
    const { t } = useTranslation();
    const loadingOrderRepository = useLoadingOrderRepository();
    const shippingAddressRepository = useShippingAddressRepository();
    const [numberOfPackages, setNumberOfPackages] = useState<number>();
    const [containerMaxWeight, setContainerMaxWeight] = useState<number>();
    const [ordersToLoad, setOrdersToLoad] = useState<OrderToLoad[]>([]);
    const [ordersLoaded, setOrdersLoaded] = useState<OrderToLoad[]>([]);
    const [loadingOrderSelectedDate, setLoadingOrderSelectedDate] = useState<Date>();
    const [orderToLoadErrorMessage, setOrderToLoadErrorMessage] = useState<string>();
    const [loadingOrderSlot, setLoadingOrderSlot] = useState<LoadingOrderSlot>();
    const [showContainerWeightErrorModal, setShowContainerWeightErrorModal] = useState(false);
    const [currentStep, setCurrentStep] = useState(LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP);
    const [loadingOrderShippingAddress, setLoadingOrderShippingAddress] = useState<TypeAheadDTO>();
    const [loadingOrderTransportType, setLoadingOrderTransportType] = useState<LoadingOrderTransportType>();
    const [shippingAddressesTypeAhead, setShippingAddressesTypeAhead] = useState<TypeAheadArrayDTO>(
        [] as unknown as TypeAheadArrayDTO,
    );

    const hideOrderToLoadErrorModal = (): void => {
        setOrderToLoadErrorMessage(undefined);
    };

    const hideContainerWeightErrorModal = (): void => {
        setShowContainerWeightErrorModal(false);
    };

    const handleLoadingOrderShippingAddress = (shippingAddress: TypeAheadDTO | undefined): void => {
        setLoadingOrderShippingAddress(shippingAddress);
    };

    const handleLoadingOrderSelectedDate = (date: Date | undefined): void => {
        setLoadingOrderSelectedDate(date);
    };

    const handleLoadingOrderTransportType = (transportType: LoadingOrderTransportType | undefined): void => {
        setOrdersLoaded([]);
        setLoadingOrderTransportType(transportType);
    };

    const getShippingAddresses = (): void => {
        if (UseToken.erpCode) {
            shippingAddressRepository.getShippingAddressesMutation.mutate(
                {
                    erpCode: new ErpUserId(UseToken.erpCode),
                    limit: 1000,
                    excludeDefault: false,
                },
                {
                    onSuccess: (data) => {
                        setShippingAddressesTypeAhead(ShippingAddressMapper.toTypeAHeadArray(data));
                    },
                },
            );
        }
    };

    const getOrdersToLoad = (): void => {
        loadingOrderRepository.fetchGetOrdersToLoadMutation.mutate(
            loadingOrderShippingAddress!,
            {
                onSuccess: (data) => {
                    setOrdersToLoad(data);
                    setOrdersLoaded([]);
                    if (data.length === 0) {
                        setOrderToLoadErrorMessage(t(LOADING_ORDER_WITHOUT_LINES_MESSAGE));
                    } else {
                        hideOrderToLoadErrorModal();
                    }
                },
                onError: () => {
                    setOrderToLoadErrorMessage(t(UNEXPECTED_ERROR));
                },
            },
        );
    };

    const getLoadingOrderDates = (): void => {
        loadingOrderRepository.fetchGetLoadingOrderDates.mutate(
            undefined,
            {
                onSuccess: (data) => {
                    setLoadingOrderSlot(data);
                },
            },
        );
    };

    const getLoadingOrderPackages = (): void => {
        loadingOrderRepository.fetchGetLoadingOrderPackages.mutate(
            ordersLoaded,
            {
                onSuccess: (data) => {
                    setNumberOfPackages(data);
                },
            },
        );
    };

    const postLoadingOrder = (): void => {
        loadingOrderRepository.fetchPostLoadingOrder.mutate(
            {
                ordersLoaded,
                date: loadingOrderSelectedDate!,
                transportType: loadingOrderTransportType!,
                shippingAddress: loadingOrderShippingAddress!.item,
            },
            {
                onSuccess: () => {
                    afterAddingLoadingScreen();
                },
                onError: (error) => {
                    // @ts-ignore
                    if (ErrorCode.CONTAINER_MAX_WEIGHT_EXCEED === error.response.data.code) {
                        setShowContainerWeightErrorModal(true);
                    }
                },
            },
        );
    };

    const getContainerMaxWeight = (erpUserId: string): void => {
        loadingOrderRepository.fetchGetContainerMaxWeight.mutate(
            erpUserId,
            {
                onSuccess: (weight) => {
                    setContainerMaxWeight(weight);
                },
            },
        );
    };

    const calculateTotalWeight = (): number => {
        let total = 0;

        ordersLoaded.forEach((order) => {
            order.linesToLoad.forEach((line) => {
                total += line.weightGross;
            });
        });

        return total;
    };

    const calculateTotalProducts = (): number => {
        let total = 0;

        ordersLoaded.forEach((order) => {
            order.linesToLoad.forEach(() => {
                total += 1;
            });
        });

        return total;
    };

    const goBack = (): void => {
        switch (currentStep) {
            case LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP:
                hideLoadingOrdersAddScreen();
                break;
            case LoadingOrderAddStep.ORDERS_TO_LOAD_STEP:
                setCurrentStep(LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP);
                break;
            case LoadingOrderAddStep.SCHEDULE_SELECTOR_STEP:
                setCurrentStep(LoadingOrderAddStep.ORDERS_TO_LOAD_STEP);
                break;
            case LoadingOrderAddStep.SUMMARY_STEP:
            default:
                setCurrentStep(LoadingOrderAddStep.SCHEDULE_SELECTOR_STEP);
                break;
        }
    };

    const goForward = (): void => {
        switch (currentStep) {
            case LoadingOrderAddStep.SHIPPING_ADDRESS_SELECTOR_STEP:
                setCurrentStep(LoadingOrderAddStep.ORDERS_TO_LOAD_STEP);
                break;
            case LoadingOrderAddStep.ORDERS_TO_LOAD_STEP:
                setCurrentStep(LoadingOrderAddStep.SCHEDULE_SELECTOR_STEP);
                break;
            case LoadingOrderAddStep.SCHEDULE_SELECTOR_STEP:
                setCurrentStep(LoadingOrderAddStep.SUMMARY_STEP);
                break;
            case LoadingOrderAddStep.SUMMARY_STEP:
            default:
                postLoadingOrder();
                break;
        }
    };

    const moveOrderToLoaded = (orderToMove: OrderToLoad): void => {
        const orderToMoveIndex = ordersLoaded.findIndex((order) => order.id.value === orderToMove.id.value);
        const updatedOrdersLoaded = [...ordersLoaded];
        const newOrder = new OrderToLoad(
            orderToMove.id,
            orderToMove.po,
            orderToMove.orderToLoadDate,
            orderToMove.linesToLoad,
        );

        if (orderToMoveIndex !== -1) {
            updatedOrdersLoaded.splice(orderToMoveIndex, 1);
            setOrdersLoaded([...updatedOrdersLoaded, newOrder]);

        } else {
            setOrdersLoaded([...updatedOrdersLoaded, newOrder]);
        }
    };

    const removeOrderFromLoaded = (orderToRemove: OrderToLoad): void => {
        const updatedOrdersLoaded = ordersLoaded.filter(
            (order) => order.id.value !== orderToRemove.id.value,
        );

        setOrdersLoaded(updatedOrdersLoaded);
    };

    const moveOrderLineToLoaded = (lineOrder: OrderToLoad, line: OrderLineToLoad): void => {
        const orderToMoveIndex = ordersLoaded.findIndex((order) => order.id.value === lineOrder.id.value);

        if (orderToMoveIndex !== -1) {
            const updatedOrdersLoaded = [...ordersLoaded];
            const existingOrder = updatedOrdersLoaded[orderToMoveIndex];
            existingOrder.linesToLoad.push(line);

            setOrdersLoaded(updatedOrdersLoaded);

        } else {
            const newOrder = new OrderToLoad(lineOrder.id, lineOrder.po, lineOrder.orderToLoadDate, [line]);
            setOrdersLoaded([...ordersLoaded, newOrder]);
        }
    };

    const removeOrderLineFromLoaded = (lineOrder: OrderToLoad, line: OrderLineToLoad): void => {
        const orderIndex = ordersLoaded.findIndex((loadedOrder) => loadedOrder.id.value === lineOrder.id.value);

        const updatedOrdersLoaded = [...ordersLoaded];
        const existingOrder = updatedOrdersLoaded[orderIndex];
        const lineIndex = existingOrder.linesToLoad.findIndex((loadedLine) => loadedLine.id.idx === line.id.idx);

        if (existingOrder.linesToLoad.length === 1) {
            updatedOrdersLoaded.splice(orderIndex, 1);
        } else {
            const existingOrderLines = [...existingOrder.linesToLoad];
            existingOrderLines.splice(lineIndex, 1);
            updatedOrdersLoaded.splice(orderIndex, 1);
            const newOrder = new OrderToLoad(
                existingOrder.id,
                existingOrder.po,
                existingOrder.orderToLoadDate,
                existingOrderLines,
            );
            updatedOrdersLoaded.push(newOrder);
        }
        setOrdersLoaded(updatedOrdersLoaded);
    };

    const checkIfOrderIsLoaded = (order: OrderToLoad): boolean => {
        return ordersLoaded.some(
            (
                loadedOrder,
            ) => loadedOrder.id.value === order.id.value && loadedOrder.linesToLoad.length === order.linesToLoad.length,
        );
    };

    const checkIfOrderLineIsLoaded = (order: OrderToLoad, line: OrderLineToLoad): boolean => {
        const orderFound = ordersLoaded.find(
            (loadedOrder) => loadedOrder.id.value === order.id.value,
        );

        if (orderFound) {
            return orderFound.linesToLoad.some((orderLineToLoad) => line.id.idx === orderLineToLoad.id.idx);
        }

        return false;
    };

    const checkIfContainerMaxWeightHasBeenExceeded = (): boolean => {
        return !!containerMaxWeight &&
            containerMaxWeight < calculateTotalWeight() &&
            loadingOrderTransportType?.value === ELoadingOrderTransportType.CONTAINER;
    };

    const postLoadingOrderIsLoading = loadingOrderRepository.fetchPostLoadingOrder.isLoading;
    const getOrdersToLoadIsLoading = loadingOrderRepository.fetchGetOrdersToLoadMutation.isLoading;
    const getLoadingOrderDatesIsLoading = loadingOrderRepository.fetchGetLoadingOrderDates.isLoading;
    const getContainerMaxWeightIsLoading = loadingOrderRepository.fetchGetContainerMaxWeight.isLoading;
    const getLoadingOrderPackagesIsLoading = loadingOrderRepository.fetchGetLoadingOrderPackages.isLoading;
    const getShippingAddressesIsLoading = shippingAddressRepository.getShippingAddressesMutation.isLoading;

    useEffect(() => {
        getShippingAddresses();
        getLoadingOrderDates();
    }, []);

    useEffect((): void => {
        hideOrderToLoadErrorModal();
        if (loadingOrderShippingAddress) {
            getOrdersToLoad();
            setNumberOfPackages(undefined);
            setLoadingOrderSelectedDate(undefined);
        }

    }, [loadingOrderShippingAddress]);

    useEffect((): void => {
        getLoadingOrderPackages();
    }, [ordersLoaded]);

    useEffect((): void => {
        if (UseToken.erpCode) {
            getContainerMaxWeight(UseToken.erpCode);
        }
    }, [UseToken.erpCode]);

    useEffect((): void => {
        if (checkIfContainerMaxWeightHasBeenExceeded()) {
            setShowContainerWeightErrorModal(true);
        }
    }, [ordersLoaded]);

    return {
        goBack,
        goForward,
        currentStep,
        ordersToLoad,
        ordersLoaded,
        loadingOrderSlot,
        numberOfPackages,
        moveOrderToLoaded,
        containerMaxWeight,
        calculateTotalWeight,
        checkIfOrderIsLoaded,
        removeOrderFromLoaded,
        moveOrderLineToLoaded,
        calculateTotalProducts,
        orderToLoadErrorMessage,
        loadingOrderSelectedDate,
        checkIfOrderLineIsLoaded,
        getOrdersToLoadIsLoading,
        hideOrderToLoadErrorModal,
        loadingOrderTransportType,
        postLoadingOrderIsLoading,
        removeOrderLineFromLoaded,
        shippingAddressesTypeAhead,
        loadingOrderShippingAddress,
        showContainerWeightErrorModal,
        hideContainerWeightErrorModal,
        getShippingAddressesIsLoading,
        getLoadingOrderDatesIsLoading,
        getContainerMaxWeightIsLoading,
        handleLoadingOrderSelectedDate,
        handleLoadingOrderTransportType,
        getLoadingOrderPackagesIsLoading,
        handleLoadingOrderShippingAddress,
        checkIfContainerMaxWeightHasBeenExceeded,
    };
};
