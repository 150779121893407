import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { ACCEPT, INVALID_ACCESS_MODAL_INFO, INVALID_ACCESS_MODAL_TITLE, CANCEL } from '../../../../../i18n/translations/TR';
import { Environment } from '../../../../../environment';

interface AccessInvalidModalProps{
    show: boolean
    onClose: () => void
}
export const InvalidAccessModal = (props: AccessInvalidModalProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            show={props.show}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{t(INVALID_ACCESS_MODAL_TITLE)}</h5>
            </Modal.Header>
            <Modal.Body className="modal-body">
                {t(INVALID_ACCESS_MODAL_INFO)}
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    form="add-form"
                    className="btn btn-primary-outline"
                    onClick={():void => {
                        props.onClose();
                    }}
                >
                    {t(CANCEL)}
                </button>
                <button
                    type="button"
                    form="add-form"
                    className="btn btn-primary"
                    onClick={():void => {
                        window.location.href = String(Environment.API_URL);
                    }}
                >
                    {t(ACCEPT)}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
