import { URL } from 'app/shared/domain/value-objects/URL';

export default class PageBanner {

    constructor(
        public readonly text: string,
        public readonly link: URL | null,
    ) {
    }

}
