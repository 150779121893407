import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { RECOVER_PASSWORD, RECOVER_PASSWORD_TEXT, SEND, CORREO, ERROR_FORM, PASSWORD_MESSAGE, EMAIL_NOT_VALID } from 'i18n/translations/TR';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import { ErrorMessage } from './ErrorMessage';
import { ThankYouMessage } from './ThankYouMessage';

interface FormInput {
    email: string,
}

interface Props {
    show: boolean,
    onHide: () => void,
}

export const ModalPassword: React.FC<Props> = ({
    show,
    onHide,
}) => {

    const { t } = useTranslation();
    const { register, handleSubmit, reset, formState: { errors, isSubmitSuccessful } } = useForm<FormInput>();
    const [hasSentPassword, setPasswordSent] = useState(false);
    const [hasSentPasswordButFail, setPasswordSentFailed] = useState(false);
    const state = React.useContext(CurrentUserContext);

    const navigatorLanguage = navigator.language;

    let defaultLanguage: string;

    const setLanguage = (): string => {
        if (document.cookie) {
            defaultLanguage = document.cookie;
        } else if (navigatorLanguage) {
            defaultLanguage = navigatorLanguage;
        }
        return defaultLanguage;
    };

    useEffect(() => {

        if (state?.noPasswordReset === true) {
            setPasswordSentFailed(true);
        }

        if (state?.passwordRecoverySuccess === true) {
            setPasswordSent(true);
        }

        if (isSubmitSuccessful) {
            reset({ email: '' });
        }
    }, [state?.passwordRecoverySuccess, isSubmitSuccessful, state?.noPasswordReset]);

    const submitHandlerPassword: SubmitHandler<FormInput> = (data: FormInput) => {

        const languageAndRegion = setLanguage();
        if (state?.handlePasswordRecovery) {
            state?.handlePasswordRecovery(data.email, languageAndRegion);
        }
    };

    const refreshForm = (): void => {
        state?.refreshForm();
        setPasswordSentFailed(false);
    };

    return (
        <div>
            <Modal
                onHide={(): void => {
                    onHide();
                }}
                show={show}
                className="modal fade"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton onClick={refreshForm}>
                    <h5 className="modal-title">{t(RECOVER_PASSWORD)}</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">

                    { !hasSentPassword ?
                        (
                            <>
                                <p>{t(RECOVER_PASSWORD_TEXT)}</p>
                                <form onSubmit={handleSubmit(submitHandlerPassword)}>
                                    <div className="form-floating mb-3">

                                        <input
                                            onClick={refreshForm}
                                            type="email"
                                            className="form-control"
                                            id="floatingInputPasswordRecovery"
                                            defaultValue=""
                                            {...register('email', { required: true })}
                                        />

                                        <label htmlFor="floatingInputPasswordRecovery">{t(CORREO)}</label>

                                        <Modal.Footer>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                {t(SEND)}
                                            </button>
                                        </Modal.Footer>
                                    </div>
                                </form>
                                { errors.email ? <ErrorMessage message={t(ERROR_FORM)} /> : null }
                                { hasSentPasswordButFail ? <ErrorMessage message={t(EMAIL_NOT_VALID)} /> : null }
                            </>
                        ) : <ThankYouMessage message={t(PASSWORD_MESSAGE)} /> }

                </Modal.Body>

            </Modal>
        </div>
    );
};
