import classNames from 'classnames';
import React from 'react';

interface NavTabButtonProps {
    className?: string,
    id: string,
    text: string,
    target: string,
    onClick: () => void,
    active: boolean,
}

export const NavTabButton: React.FC<NavTabButtonProps> = (props: NavTabButtonProps) => {
    return (
        <button
            className={classNames('nav-link', props.className, { active: props.active })}
            id={props.id}
            data-bs-toggle="pill"
            data-bs-target={`#${props.target}`}
            type="button"
            role="tab"
            aria-controls={props.target}
            aria-selected="true"
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
};

NavTabButton.defaultProps = {
    className: '',
};
