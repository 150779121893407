import React from 'react';
import { useOrdersListInterface } from '../../../hooks/useOrdersList';
import { OrderListTableBodyItem } from './item/orderListTableBodyItem';

interface OrderListTableBodyProps {
    ordersList: useOrdersListInterface
}
export const OrderListTableBody = (props: OrderListTableBodyProps): React.ReactElement => {
    return (
        <tbody>
            {props.ordersList.orders.map((order) => (
                <OrderListTableBodyItem
                    order={order}
                    key={order.id.value}
                    ordersList={props.ordersList}
                />
            ))}
        </tbody>
    );
};
