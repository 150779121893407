import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { useForm } from 'react-hook-form';
import {
    ACCEPT,
    ACCOUNT_ADD_CONTACT,
    ACCOUNT_FIELD_DESCRIPTION,
    ACCOUNT_FIELD_EMAIL,
    ACCOUNT_FIELD_PHONE,
    CANCEL,
    EMAIL_NOT_VALID,
    OBLIGATORY_FIELD_ERROR,
} from '../../../../../../../../i18n/translations/TR';
import { AddErpUserContactRequestDTO } from '../../../../../../infrastructure/api/dto/request/add-erp-user-contact-request.DTO';

interface AccountContactModalAddProps {
    handleClose: () => void
    showModal: boolean
    addContactIsLoading:boolean
    mutationAddErpUserContact: UseMutationResult<string, unknown, AddErpUserContactRequestDTO, unknown>
    notifications: string[]
}

interface AddContactRegisters{
    description:string
    email:string,
    phone:string,
    events:string[],
}
export const AccountContactModalAdd = (
    {
        handleClose,
        showModal,
        mutationAddErpUserContact,
        addContactIsLoading,
        notifications,
    }:AccountContactModalAddProps,
):React.ReactElement => {
    const { t } = useTranslation();
    const UseFormAddContact = useForm<AddContactRegisters>({
        defaultValues: {
            description: '',
            email: '',
            phone: '',
            events: [],
        },
    });

    const onSubmitAddContact =
        UseFormAddContact.handleSubmit((data: AddContactRegisters) => {
            UseFormAddContact.clearErrors();
            mutationAddErpUserContact.mutate({
                description: data.description,
                email: data.email,
                phone: data.phone,
                events: data.events,
            }, { onSuccess: ():void => {
                handleClose();
            } });
        });

    const descriptionRegister = UseFormAddContact.register(
        'description',
        { required: t(OBLIGATORY_FIELD_ERROR) as string },
    );
    const descriptionErrors = UseFormAddContact.formState.errors.description;
    const phoneRegister = UseFormAddContact.register('phone', { required: t(OBLIGATORY_FIELD_ERROR) as string });
    const phoneErrors = UseFormAddContact.formState.errors.phone;
    const emailRegister = UseFormAddContact.register('email', {
        required: t(OBLIGATORY_FIELD_ERROR) as string,
        pattern: {
            value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            message: t(EMAIL_NOT_VALID),
        },

    });
    const emailErrors = UseFormAddContact.formState.errors.email;
    const eventsRegister = UseFormAddContact.register('events');

    return (
        <Modal
            show={showModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{t(ACCOUNT_ADD_CONTACT)}</h5>
            </Modal.Header>
            <Modal.Body className="account-card add-contact-wrapper">
                <form className="row account-card-contact-container">
                    <div className="account-card-contact-description">
                        <label htmlFor="contactDescription" className="form-label is-required">
                            {t(ACCOUNT_FIELD_DESCRIPTION)}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactDescription"
                            {...descriptionRegister}
                        />
                        {
                            descriptionErrors && <p className="error-input-form">{descriptionErrors.message}</p>
                        }
                    </div>
                    <div className="account-card-contact-email">
                        <label htmlFor="contactEmail" className="form-label is-required">
                            {t(ACCOUNT_FIELD_EMAIL)}
                        </label>
                        <input
                            type="email"
                            className="form-control"
                            id="contactEmail"
                            {...emailRegister}
                        />
                        {
                            emailErrors && <p className="error-input-form">{emailErrors.message}</p>
                        }
                    </div>
                    <div className="account-card-contact-phone">
                        <label htmlFor="contactPhone" className="form-label is-required">
                            {t(ACCOUNT_FIELD_PHONE)}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactPhone"
                            {...phoneRegister}
                        />
                        {
                            phoneErrors && <p className="error-input-form">{phoneErrors.message}</p>
                        }
                    </div>
                    <div className="account-card-contact-notifications-container">
                        <label htmlFor="notificationTitle" className="form-label">
                            Receive notifications
                        </label>
                        {notifications
                            ? notifications.map((notification) => (
                                <div className="form-check" key={notification}>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`notification-${notification}`}
                                        {...eventsRegister}
                                        value={notification}
                                    />
                                    <label htmlFor="notification1" className="form-check-label">
                                        {notification}
                                    </label>
                                </div>
                            ))
                            : null}
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn-link bg-transparent text-decoration-none"
                    onClick={(): void => {
                        handleClose();
                        UseFormAddContact.clearErrors();
                    }}
                    disabled={addContactIsLoading}
                >
                    {t(CANCEL)}
                </button>
                <button
                    type="submit"
                    form="add-form"
                    className="btn btn-primary"
                    disabled={addContactIsLoading}
                    onClick={onSubmitAddContact}
                >
                    {
                        addContactIsLoading
                            ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                            : null
                    }
                    {`\u00A0${t(ACCEPT)}`}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
