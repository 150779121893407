import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
    ADD_LOADING_ORDER_ORDERS_TO_LOAD_INSTRUCTIONS,
    CANCEL,
    CONTINUE,
    CREATE_LOADING_ORDER,
    ITEMS_ADDED,
    ITEMS_TO_ADD,
    LOADING_ORDER_TRANSPORT_TYPE,
    LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS,
    PACKAGES_PALLETS,
    TOTAL_WEIGHT,
} from '../../../../../i18n/translations/TR';
import './order-to-load-modal.scss';
import { OrdersToLoadTable } from './orders-to-load-table/orders-to-load-table';
import { PageSpinner } from '../../../../shared/components/page-spinner/page-spinner';
import OrderToLoad from '../../../domain/order-to-load';
import OrderLineToLoad from '../../../domain/order-line-to-load';
import { NoItemsLoadedMessage } from './no-items-loaded-message/no-items-loaded-message';
import { TypeAheadDTO } from '../../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';
import { COWeight } from '../../../../shared/components/CO-weight';
import { LoadingOrderTransportType } from '../../../domain/value-objects/loading-order-transport_type';

interface OrdersToLoadModalProps {
    showModal: boolean
    goBack: () => void
    goForward: () => void
    ordersToLoad: OrderToLoad[]
    ordersLoaded: OrderToLoad[]
    getOrdersToLoadIsLoading: boolean
    calculateTotalWeight: () => number
    numberOfPackages: number | undefined
    getContainerMaxWeightIsLoading: boolean
    getLoadingOrderPackagesIsLoading: boolean
    moveOrderToLoaded: (orderToMove: OrderToLoad) => void
    checkIfOrderIsLoaded: (order: OrderToLoad) => boolean
    loadingOrderShippingAddress: TypeAheadDTO | undefined
    checkIfContainerMaxWeightHasBeenExceeded: () => boolean
    removeOrderFromLoaded: (orderToRemove: OrderToLoad) => void
    loadingOrderTransportType: LoadingOrderTransportType | undefined
    moveOrderLineToLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    checkIfOrderLineIsLoaded: (order: OrderToLoad, line: OrderLineToLoad) => boolean
    removeOrderLineFromLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
}

export const OrdersToLoadModal = (
    {
        goBack,
        showModal,
        goForward,
        ordersToLoad,
        ordersLoaded,
        numberOfPackages,
        moveOrderToLoaded,
        calculateTotalWeight,
        checkIfOrderIsLoaded,
        removeOrderFromLoaded,
        moveOrderLineToLoaded,
        getOrdersToLoadIsLoading,
        checkIfOrderLineIsLoaded,
        removeOrderLineFromLoaded,
        loadingOrderTransportType,
        loadingOrderShippingAddress,
        getContainerMaxWeightIsLoading,
        getLoadingOrderPackagesIsLoading,
        checkIfContainerMaxWeightHasBeenExceeded,
    }: OrdersToLoadModalProps,
): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Modal
            centered
            fullscreen
            onHide={goBack}
            show={showModal}
            className="full-screen modal fade"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <h5>{t(CREATE_LOADING_ORDER)}</h5>
            </Modal.Header>
            <Modal.Body>
                <PageSpinner
                    isBusy={getOrdersToLoadIsLoading || getContainerMaxWeightIsLoading}
                />
                <div className="orders-to-load-body-container">
                    <div className="orders-to-load-container">
                        <span
                            className="orders-to-load-instructions-container"
                        >
                            {t(ADD_LOADING_ORDER_ORDERS_TO_LOAD_INSTRUCTIONS)}
                        </span>

                        <div className="orders-to-load-card">
                            <h5 className="card-wow-title">
                                {t(ITEMS_TO_ADD)}
                            </h5>
                            <OrdersToLoadTable
                                isOrderLoadedTable={false}
                                orders={ordersToLoad}
                                moveOrderToLoaded={moveOrderToLoaded}
                                removeOrderFromLoaded={removeOrderFromLoaded}
                                moveOrderLineToLoaded={moveOrderLineToLoaded}
                                removeOrderLineFromLoaded={removeOrderLineFromLoaded}
                                checkIfOrderIsLoaded={checkIfOrderIsLoaded}
                                checkIfOrderLineIsLoaded={checkIfOrderLineIsLoaded}
                            />
                        </div>
                    </div>

                    <div className="orders-to-load-container">
                        <div className="orders-to-load-shipping-address-container">
                            <p className="form-label">
                                {t(LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS)}
                            </p>
                            <span>
                                {loadingOrderShippingAddress?.item.buildAddressInfo()}
                            </span>
                        </div>

                        <div className="orders-to-load-card background">
                            <div className="orders-to-load-card-header">
                                <h5 className="card-wow-title">
                                    {t(ITEMS_ADDED)}
                                </h5>

                                <div className="order-to-load-card-header-info-container">
                                    <div className="order-to-load-card-header-info-item">
                                        <p className="order-to-load-card-header-info-item-title">
                                            {`${t(PACKAGES_PALLETS)}:`}
                                        </p>
                                        {getLoadingOrderPackagesIsLoading ? (
                                            <Skeleton
                                                inline
                                                width={50}
                                                height={20}
                                                className="order-to-load-card-header-info-item-skeleton"
                                            />
                                        ) : (
                                            <span className="order-to-load-card-header-info-item-value">
                                                {
                                                    (numberOfPackages && calculateTotalWeight() > 0) ?
                                                        numberOfPackages :
                                                        '-'
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className="order-to-load-card-header-info-item">
                                        <p className="order-to-load-card-header-info-item-title">
                                            {`${t(TOTAL_WEIGHT)}:`}
                                        </p>
                                        {calculateTotalWeight() > 0 ? (
                                            <div className="order-to-load-card-header-info-item-value">
                                                <COWeight value={calculateTotalWeight()} />
                                            </div>
                                        ) : '-'}
                                    </div>
                                    <div className="order-to-load-card-header-info-item">
                                        <p className="order-to-load-card-header-info-item-title">
                                            {`${t(LOADING_ORDER_TRANSPORT_TYPE)}:`}
                                        </p>
                                        <span className="order-to-load-card-header-info-item-value">
                                            {loadingOrderTransportType?.translate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <OrdersToLoadTable
                                isOrderLoadedTable
                                orders={ordersLoaded}
                                moveOrderToLoaded={moveOrderToLoaded}
                                removeOrderFromLoaded={removeOrderFromLoaded}
                                moveOrderLineToLoaded={moveOrderLineToLoaded}
                                removeOrderLineFromLoaded={removeOrderLineFromLoaded}
                                checkIfOrderIsLoaded={checkIfOrderIsLoaded}
                                checkIfOrderLineIsLoaded={checkIfOrderLineIsLoaded}
                            />
                            {ordersLoaded.length === 0 ? (
                                <NoItemsLoadedMessage />
                            ) : null}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <hr />
                <div className="shipping-address-selector-footer-container">
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={goBack}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="button"
                        form="add-form"
                        onClick={goForward}
                        className="btn btn-primary"
                        disabled={ordersLoaded.length === 0 || checkIfContainerMaxWeightHasBeenExceeded()}
                    >
                        {t(CONTINUE)}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
