import { useCallback, useEffect, useRef } from 'react';

export function useIsMounted(): () => boolean {
    const mountedRef = useRef(true);
    const isMounted = useCallback(() => mountedRef.current, []);

    useEffect(() => {
        mountedRef.current = true;
        return (): void => {
            mountedRef.current = false;
        };
    }, []);

    return isMounted;
}
