import { ELoadingOrderStatusDTO, LoadingOrderDTO } from '../dto/loading-order.DTO';
import LoadingOrder from '../../../domain/loading-order';
import { LoadingOrderId } from '../../../domain/value-objects/loading-order-id';
import { ShippingAddressMapper } from '../../../../orders/infrastructure/repository/api/mappers/ShippingAddressMapper';
import { OrderToLoadDTO } from '../dto/order-to-load.DTO';
import { OrderToLoadMapper } from './order-to-load.mapper';
import OrderToLoad from '../../../domain/order-to-load';
import { ELoadingOrderStatus, LoadingOrderStatus } from '../../../domain/value-objects/loading-order-status';
import { LoadingOrderTransportTypeMapper } from './loading-order-transport-type.mapper';

export class LoadingOrderMapper {

    private static calculateTotalWeight(ordersToLoad: OrderToLoadDTO[]) : number {
        let totalWeight = 0;

        ordersToLoad.forEach((orderToLoad) => {
            orderToLoad.lines.forEach((line) => {
                totalWeight += line.weight_gross;
            });
        });

        return totalWeight;
    }

    private static statusToDomain(status: ELoadingOrderStatusDTO) : ELoadingOrderStatus {
        switch (status) {
            case ELoadingOrderStatusDTO.CONFIRMED:
                return ELoadingOrderStatus.CONFIRMED;
            case ELoadingOrderStatusDTO.SERVED:
                return ELoadingOrderStatus.SERVED;
            case ELoadingOrderStatusDTO.IN_PREPARATION:
            default:
                return ELoadingOrderStatus.IN_PREPARATION;
        }
    }

    public static toDomain(loadingOrderDto: LoadingOrderDTO): LoadingOrder {

        const ordersToLoad: OrderToLoad[] = [];

        loadingOrderDto.orders_to_load.forEach((order) => {
            ordersToLoad.push(OrderToLoadMapper.toDomain(order));
        });

        return new LoadingOrder(
            new LoadingOrderId(loadingOrderDto.loading_order_id.toString()),
            loadingOrderDto.shipping ? ShippingAddressMapper.fromResponse(loadingOrderDto.shipping) : null,
            LoadingOrderTransportTypeMapper.toDomain(loadingOrderDto.transport_type),
            new Date(loadingOrderDto.date_time),
            loadingOrderDto.packages,
            this.calculateTotalWeight(loadingOrderDto.orders_to_load),
            ordersToLoad,
            new LoadingOrderStatus(this.statusToDomain(loadingOrderDto.status)),
        );
    }

}
