import i18next from 'i18next';
import { ERROR_500 } from '../../../../i18n/translations/TR';

export class ContactDescription {

    private readonly _description: string;

    public constructor(value:string) {
        this.ensureIsNotUndefined(value);
        this._description = value;

    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw i18next.t(ERROR_500); // Todo crear error específico
        }
    }

    public get description(): string {
        return this._description;
    }

}
