import { ContactDescription } from './value-objects/contact-description';
import { Phone } from './value-objects/phone';
import { Email } from './value-objects/email';

export class ErpContact {

    private _email: Email;
    private _events: string[];
    private _phone: Phone;
    private _description: ContactDescription;

    public constructor(
        email:Email,
        events:string[],
        phone:Phone,
        description:ContactDescription,
    ) {
        this._email = email;
        this._events = events;
        this._phone = phone;
        this._description = description;
    }

    public get email(): Email {
        return this._email;
    }
    public set email(value: Email) {
        this._email = value;
    }

    public get phoneNumber():Phone {
        return this._phone;
    }

    public set phoneNumber(value: Phone) {
        this._phone = value;
    }

    public get events(): string[] {
        return this._events;
    }

    public set events(value: string[]) {
        this._events = value;
    }

    public get description(): ContactDescription {
        return this._description;
    }

    public set description(value: ContactDescription) {
        this._description = value;
    }

}
