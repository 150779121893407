import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AVAILABLE_LOTS,
    AVAILABLE_QUANTITY,
    LOT_REFERENCE,
    OUT_OF_STOCK_WAREHOUSES_INFO,
    PIECE_UNIT,
} from 'i18n/translations/TR';
import { getSquareMeasurement } from 'app/shared/getSquareMeasurement';
import { NumberFormatComponentPieceUnit } from 'app/shared/NumberFormatPieceUnit';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { ApiProductDetail } from '../shared/domain/ApiProductDetail';
import { useOrigin } from '../../shared/hooks/useOrigin';
import { Origin } from '../../shared/domain/value-objects/Origin';
import { WarehouseLocation } from '../shared/domain/WarehouseLocation';

interface Props {
    detail: ApiProductDetail,
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export const WarehouseInfoTable: React.FC<Props> = ({ detail, outOfStockWarehousesLocations }) => {
    const { t } = useTranslation();
    const origin = useOrigin();

    const getUnit = (): string => {

        let unitString: string = '';

        if (detail?.unit === '0') {
            unitString = `${t(AVAILABLE_QUANTITY)} (${t(PIECE_UNIT)})`;
        } else if (detail?.unit === '1') {
            unitString = `${t(AVAILABLE_QUANTITY)} (${getSquareMeasurement()})`;
        }
        return unitString;
    };

    return (
        <section className="warehouse-info-table-container">
            {origin === Origin.USA ? (
                <div className="unavailable-lots-container">
                    <p>{t(OUT_OF_STOCK_WAREHOUSES_INFO).toUpperCase()}</p>
                </div>
            ) : null}

            <div className="container available-lots-container">
                <h3 className="section-title">{t(AVAILABLE_LOTS)}</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="text-end">
                                {t(LOT_REFERENCE)}
                            </th>
                            <th scope="col" className="text-end">
                                {getUnit()}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {detail?.lotInfo?.map((info) => (
                            <tr key={info.reference} className="available-lot">
                                <th scope="row" className="text-end">
                                    <span className="lot-reference">
                                        {info.reference}
                                    </span>
                                    {info.warehouse_location ? (
                                        <span className="lot-location">
                                            {`(${info.warehouse_location.code})`}
                                        </span>
                                    ) : null }
                                </th>
                                <td className="text-end">
                                    <NumberFormatComponentPieceUnit
                                        value={getRoundedValues(info.quantity)}
                                    />
                                </td>
                            </tr>
                        ))}
                        {
                            outOfStockWarehousesLocations.map((warehouse) => (
                                <tr key={warehouse.code} className="available-lot">
                                    <th scope="row" className="text-end">
                                        <span className="lot-location">
                                            {`(${warehouse.code})`}
                                        </span>
                                    </th>
                                    <td className="text-end">
                                        <NumberFormatComponentPieceUnit
                                            value={0}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </section>
    );
};
