import {
    ELoadingOrderTransportType,
    LoadingOrderTransportType,
} from '../../../domain/value-objects/loading-order-transport_type';
import { ELoadingOrderTransportTypeDTO } from '../dto/loading-order-transport-type.DTO';

export class LoadingOrderTransportTypeMapper {

    public static fromDomain(
        transportType: LoadingOrderTransportType,
    ): ELoadingOrderTransportTypeDTO {

        switch (transportType.value) {
            case ELoadingOrderTransportType.TRUCK:
                return ELoadingOrderTransportTypeDTO.TRUCK;
            case ELoadingOrderTransportType.CONTAINER:
            default:
                return ELoadingOrderTransportTypeDTO.CONTAINER;
        }
    }

    public static toDomain(
        transportTypeDto: ELoadingOrderTransportTypeDTO,
    ): LoadingOrderTransportType {

        switch (transportTypeDto) {
            case ELoadingOrderTransportTypeDTO.TRUCK:
                return new LoadingOrderTransportType(ELoadingOrderTransportType.TRUCK);
            case ELoadingOrderTransportTypeDTO.CONTAINER:
            default:
                return new LoadingOrderTransportType(ELoadingOrderTransportType.CONTAINER);
        }
    }

}
