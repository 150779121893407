import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    LOADING_DATE, LOADING_ORDER_TRANSPORT_TYPE,
    LOADING_ORDERS_TABLE_COLUMN_LOADING_ORDER_NO,
    LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS,
    LOADING_ORDERS_TABLE_COLUMN_STATUS,
} from '../../../../../../../i18n/translations/TR';

export const LoadingOrderListTableHead = (): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <thead>
            <tr>
                <th>{t(LOADING_ORDERS_TABLE_COLUMN_LOADING_ORDER_NO)}</th>
                <th>{t(LOADING_ORDERS_TABLE_COLUMN_SHIPPING_ADDRESS)}</th>
                <th>{t(LOADING_ORDER_TRANSPORT_TYPE)}</th>
                <th>{t(LOADING_DATE)}</th>
                <th>{t(LOADING_ORDERS_TABLE_COLUMN_STATUS)}</th>
                <th>{}</th>
            </tr>
        </thead>
    );
};
