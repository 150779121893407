import React from 'react';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiCollectionDetail } from './ApiCollectionDetails';

interface UseProductDetailList {
    data?: ApiCollectionDetail;
    error: boolean;
    loading: boolean;
}

export function useCollectionDetailsList(id: string): UseProductDetailList {
    const [data, setData] = React.useState<ApiCollectionDetail>();
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance().getCollectionDetails(id)
            .then((products) => setData(products))
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
    };
}
