/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

interface Props {
    value: number | string,
    className?: string,
    label: string,
    scale?: number,
}

export const Quantity: React.FC<Props> = (props:Props) => {
    const { i18n } = useTranslation();

    return (
        <NumberFormat
            value={props.value}
            displayType="text"
            className={props.className}
            thousandSeparator={i18n.language === 'es' ? '.' : ','}
            decimalSeparator={i18n.language === 'es' ? ',' : '.'}
            decimalScale={props.scale || 2}
            renderText={(x):ReactElement => (
                <>
                    <span className="quantity-value">{x}</span>
                    <span className="quantity-label">{props.label}</span>
                </>
            )}
        />
    );
};
