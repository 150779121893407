import React from 'react';
import { useOrdersListInterface } from '../../hooks/useOrdersList';
import { OrderListTableHead } from './head/orderListTableHead';
import { OrderListTableBody } from './body/orderListTableBody';

interface OrderListTableProps {
    ordersList: useOrdersListInterface
}
export const OrderListTable = (props: OrderListTableProps): React.ReactElement => {
    return (
        <table
            className="table orders-list table-responsive align-middle table-striped table-hover table-borderless"
        >
            <OrderListTableHead />
            <OrderListTableBody
                ordersList={props.ordersList}
            />
        </table>
    );
};
