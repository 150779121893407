import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LOADING_ORDERS } from '../../../../../i18n/translations/TR';
import { PageSpinner } from '../../../../shared/components/page-spinner/page-spinner';
import { useLoadingOrderList } from '../../hooks/use-loading-order-list';
import { Spinner } from '../../../../shared/Spinner';
import { SectionHeaderLoadingOrder } from './header/section-header-loading-order';
import { LoadingOrderListTable } from './table/loading-order-list-table';
import { LoadingOrderAddScreen } from '../../add/loading-order-add-screen';
import { usePermission } from '../../../../shared/hooks/use-Permission';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';

export const LoadingOrderListScreen: React.FC = () => {
    const { t } = useTranslation();
    const UseLoadingOrderList = useLoadingOrderList();
    const [showLoadingOrderAddScreen, setShowLoadingOrderAddScreen] = useState(false);

    const loaderElement = (<Spinner classCss="spinner-infinite_scroll text-center" />);
    const permission = usePermission();
    if (!permission.canManageLoadingOrders()) {
        return <Navigate replace to={TencerRoutes.HOME} />;
    }
    return (
        <div className="screen">
            <PageSpinner isBusy={UseLoadingOrderList.isBusy && UseLoadingOrderList.loadingOrders.length === 0} />
            {
                showLoadingOrderAddScreen ? (
                    <LoadingOrderAddScreen
                        hideLoadingOrdersAddScreen={(): void => setShowLoadingOrderAddScreen(false)}
                        afterAddingLoadingScreen={(): void => {
                            setShowLoadingOrderAddScreen(false);
                            UseLoadingOrderList.refreshLoadingOrders();
                        }}
                    />
                ) : null
            }
            <div className="screen-loading-orders">
                <section className="loading-orders">
                    <div className="container">
                        <InfiniteScroll
                            dataLength={UseLoadingOrderList.loadingOrders.length}
                            next={UseLoadingOrderList.getLoadingOrders}
                            hasMore={UseLoadingOrderList.paginator.hasMore}
                            loader={loaderElement}
                            endMessage={<p className="end-message">{t('NO_MORE_RESULTS')}</p>}
                        >
                            <SectionHeaderLoadingOrder
                                title={t(LOADING_ORDERS)}
                                openLoadingOrderAddModal={(): void => {
                                    setShowLoadingOrderAddScreen(true);
                                }}
                            />

                            <LoadingOrderListTable
                                loadingOrders={UseLoadingOrderList.loadingOrders}
                                downloadLoadingOrderPDF={UseLoadingOrderList.downloadLoadingOrderPDF}
                                isDownloadingPDF={UseLoadingOrderList.isDownloadingPDF}
                            />
                        </InfiniteScroll>
                    </div>
                </section>
            </div>
        </div>
    );
};
