import React from 'react';
import { Link } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { ApiProduct } from 'app/home/product/shared/domain/ApiProduct';
import { ListItem } from './ListItem';

interface Props {
    productsCatalog: ApiProduct[]
}

export const ProductsCatalogList: React.FC<Props> = ({ productsCatalog }) => {

    const urlRouter = TencerRoutes.PRODUCT_DETAILS;
    const urlRouterFormat = urlRouter.replace(':id', '');

    const result = productsCatalog.map((product, index) => ({
        ...product,
        id: index,
    }));

    return (
        <div>
            <section className="container section-list 100vh">

                { result.map((product) => (
                    <Link
                        to={`${urlRouterFormat}${product.product}`}
                        style={{ textDecoration: 'none' }}
                        key={product.id}
                    >
                        <ListItem key={product.id} product={product} />
                    </Link>
                )) }
            </section>
        </div>
    );
};
