import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ICartSummaryRepository } from '../../domain/repositories/cart-summary-repository-interface';
import { TransportRequestMapper } from './api/data-mappers/transport-request.mapper';
import { UseTransportFormRegisters } from '../../domain/model/transport-form';

export class CartSummaryRepository implements ICartSummaryRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async checkout(data?: UseTransportFormRegisters): Promise<void> {
        const response = await this.client.postMyCartCheckout(
            data ?
                TransportRequestMapper.fromDomain(data) :
                undefined,
        );

        return response;
    }

}
