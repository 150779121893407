import React from 'react';
import { LoadingOrderDetailTableBodyItem } from './loading-order-detail-table-body-item/loading-order-detail-table-body-item';
import OrderToLoad from '../../../../../domain/order-to-load';

interface LoadingOrderDetailTableBodyProps {
    orders: OrderToLoad[]
}
export const LoadingOrderDetailTableBody = (props: LoadingOrderDetailTableBodyProps): React.ReactElement => {
    return (
        <tbody>
            {
                props.orders
                    .map((order) => {
                        return (
                            <LoadingOrderDetailTableBodyItem
                                key={order.id.value}
                                order={order}
                            />
                        );
                    })
            }
        </tbody>
    );
};
