import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';
import { ErpUserId } from '../../../account/domain/value-objects/erp-user-id';
import { TencerApiClientSingleton } from '../../../shared/hooks/TencerApiClientSingleton';
import { useCartCheckoutForm, UseCartCheckoutFormInterface } from './use-cart-checkout-form';
import { GetErpUserRequestDTO } from '../../../account/infrastructure/api/dto/request/get-erp-user-request.DTO';
import { ErpUserMapper } from '../../../account/infrastructure/api/data-mapper/erp-user.mapper';
import { ErpUser } from '../../../account/domain/erp-user';
import { useToken } from '../../../shared/hooks/use-token';
import { CurrentUserContext } from '../../../shared/context/CurrentUserContext';
import CartMapper from '../../../cart/infraestructure/repository/api/data-mapper/CartMapper';
import Cart from '../../../cart/domain/model/Cart';

export interface UseProductDetailList {
    cart?: Cart|null;
    refreshCart(): void;
    isLoading: boolean;
    error: boolean;
    erpUserId :ErpUserId | undefined,
    userCredentialId: string | undefined,
    cartCheckoutForm: UseCartCheckoutFormInterface,
}

export function useCartSummaryList(): UseProductDetailList {

    const isMounted = useIsMounted();
    const [cart, setCart] = React.useState<Cart|null>();
    const [erpUser, setErpUser] = useState<ErpUser>();
    const [error, setError] = React.useState<boolean>(false);
    const cartCheckoutForm = useCartCheckoutForm(cart);
    const apiClient = TencerApiClientSingleton.getInstance();
    const UseToken = useToken();
    const userContext = useContext(CurrentUserContext);

    const erpUserId = erpUser ? erpUser.id : undefined;
    const userCredentialId = UseToken.username;

    const refreshCart = (): void => {
        const APIClient = TencerApiClientSingleton.getInstance();
        APIClient.getMyCart()
            .then((response) => {
                if (!isMounted) {
                    return;
                }
                setCart(CartMapper.fromResponse(response));
                if (response) {
                    userContext?.setCartItems(response.items.length);
                } else {
                    userContext?.setCartItems(undefined);
                }
                userContext?.setExpirationDate(new Date(response.free_stock_at).toISOString());
            })
            .catch(() => {
                setError(true);
            });
    };

    const mutationGetUser = useMutation(
        async (getUserRequestDTO: GetErpUserRequestDTO) => apiClient.getErpUser(getUserRequestDTO),
        {
            onSuccess: (data) => {
                setErpUser(ErpUserMapper.toDomain(data));
            },
        },
    );

    const getErpUser = (): void => {
        if (!UseToken.erpCode) {
            return;
        }
        mutationGetUser.mutate({
            erpUserId: UseToken.erpCode,
        });
    };

    React.useEffect(() => {
        cartCheckoutForm.form.setValue('clientRef', cart?.clientInternalRef ?? null);
    }, [cart]);

    React.useEffect(() => {
        refreshCart();
        getErpUser();
    }, []);

    return {
        cart,
        refreshCart,
        isLoading: !cart && !error,
        error,
        erpUserId,
        userCredentialId,
        cartCheckoutForm,
    };
}
