import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { CartSummaryRepository } from '../../infrastructure/repository/cart-summary-repository';
import { UseTransportFormRegisters } from '../../domain/model/transport-form';

interface CartSummaryRepositoryMutationsInterface {
    mutationCheckout: UseMutationResult<void, unknown, UseTransportFormRegisters | undefined, unknown>
}

export const useCartSummaryRepository = (): CartSummaryRepositoryMutationsInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new CartSummaryRepository(client);

    const mutationCheckout = useMutation(
        async (
            transportInfo?: UseTransportFormRegisters,
        ) => repository.checkout(transportInfo),
    );

    return {
        mutationCheckout,
    };
};
