import React from 'react';

export const COErrorMessage: React.FC <{
    message: string | undefined;
    // eslint-disable-next-line react/require-default-props
    className?: string
}> = ({ message, className }) => {

    return (
        <div className={className!}>
            {message}
        </div>
    );
};
