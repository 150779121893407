import { useForm, UseFormReturn } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { UseTransportFormName, UseTransportFormRegisters } from '../../domain/model/transport-form';
import { TencerApiClientSingleton } from '../../../shared/hooks/TencerApiClientSingleton';
import { TransportRepository } from '../../infrastructure/repository/transport-repository';
import { useOrigin } from '../../../shared/hooks/useOrigin';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import { DeliveryService } from '../../../shared/domain/delivery-service';
import { ETransportType } from '../../../orders/model/value-objects/transport-type';
import { useToken } from '../../../shared/hooks/use-token';

export interface UseTransportResponse {
    liftGatePrice: number
    transportPrice: number
    hasTransportPrice: boolean
    getTransportPrice: () => void
    isGettingLiftGatePrice: boolean
    isGettingTransportPrice: boolean
    isGettingDeliveryServices: boolean
    deliveryServices: DeliveryService[]
    UseTransportForm: UseFormReturn<UseTransportFormRegisters>
}

export const useTransport = (
    onErrorGettingTransportPrice: () => void,
): UseTransportResponse => {
    const origin = useOrigin();
    const apiClient = TencerApiClientSingleton.getInstance();
    const repository = new TransportRepository(apiClient);
    const [liftGatePrice, setLiftGatePrice] = useState<number>(0);
    const [transportPrice, setTransportPrice] = useState<number>(0);
    const [hasTransportPrice, setHasTransportPrice] = useState(true);
    const [deliveryServices, setDeliveryServices] = useState<DeliveryService[]>([]);
    const token = useToken();

    const UseTransportForm = useForm<UseTransportFormRegisters>({
        defaultValues: {
            comments: '',
            liftGate: false,
            consolidated: false,
            deliveryService: '',
            responsible: ETransportType.WILL_CALL,
            allowQuoteTransport: false,
        },
    });

    const mutationGetLiftGatePrice = useMutation(
        async () => repository.getLiftGatePrice(),
        {
            onSuccess: (price) => {
                setLiftGatePrice(price);
            },
        },
    );

    const getLiftGatePrice = (): void => {
        mutationGetLiftGatePrice.mutate();
    };

    const mutationGetTransportPrice = useMutation(
        async () => repository.getTransportPrice(),
        {
            onSuccess: (price) => {
                setTransportPrice(price);
                setHasTransportPrice(true);
            },
            onError: () => {
                setHasTransportPrice(false);
                onErrorGettingTransportPrice();
            },
        },
    );

    const getTransportPrice = (): void => {
        mutationGetTransportPrice.mutate();
    };

    const mutationGetDeliveryServices = useMutation(
        async () => repository.getDeliveryServices(),
        {
            onSuccess: (services) => {
                setDeliveryServices(services);
            },
        },
    );

    const getDeliveryServices = (): void => {
        mutationGetDeliveryServices.mutate();
    };

    const isGettingLiftGatePrice = mutationGetLiftGatePrice.isLoading;
    const isGettingTransportPrice = mutationGetTransportPrice.isLoading;
    const isGettingDeliveryServices = mutationGetDeliveryServices.isLoading;

    useEffect(() => {
        if (origin === Origin.USA) {
            getLiftGatePrice();
            getTransportPrice();
            getDeliveryServices();
        }
    }, []);

    useEffect(() => {
        if (token.erpCode) {
            repository.getAllowQuoteTransport(token.erpCode).then((allowQuoteTransport) => {
                // @ts-ignore
                return UseTransportForm.setValue(UseTransportFormName.ALLOW_QUOTE_TRANSPORT, allowQuoteTransport);
            });
        }
    }, [token.erpCode]);

    return {
        liftGatePrice,
        transportPrice,
        deliveryServices,
        UseTransportForm,
        hasTransportPrice,
        getTransportPrice,
        isGettingLiftGatePrice,
        isGettingTransportPrice,
        isGettingDeliveryServices,
    };
};
