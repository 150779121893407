import React from 'react';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { COLLECTIONS, MENU_OPTION_MARKETING, PRODUCTS } from 'i18n/translations/TR';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Origin } from 'app/shared/domain/value-objects/Origin';
import { useNavigate } from 'react-router-dom';
import { NavTabButton } from './NavTabButton';

interface Props {
    location: boolean
}

export const HeaderCatalogCollections: React.FC <Props> = ({ location }) => {
    const history = useNavigate();
    const { t } = useTranslation();
    const origin = useOrigin();

    const OPTIONS = {
        CATALOG: 'catalog',
        COLLECTIONS: 'collections',
        MARKETING: 'marketing',
    };

    const optionsRouteMap = new Map<string, string>([
        [OPTIONS.CATALOG, TencerRoutes.CATALOG],
        [OPTIONS.COLLECTIONS, TencerRoutes.COLLECTIONS],
        [OPTIONS.MARKETING, TencerRoutes.MARKETING],
    ]);

    const isActiveOption = (option: string): boolean => {
        return optionsRouteMap.get(option) === window.location.pathname;
    };

    const goToCatalog = ():void => {
        history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
    };

    const goToCollections = ():void => {
        history(TencerRoutes.COLLECTIONS);
    };

    const goToMarketing = ():void => {
        history(TencerRoutes.MARKETING);
    };

    const classTab = classNames(
        'container',
        { invisible: location === true },
    );

    return (
        <div className={classTab}>
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <NavTabButton
                        id="pills-catalog-tab"
                        active={isActiveOption(OPTIONS.CATALOG)}
                        text={t(PRODUCTS)}
                        target="pills-catalog"
                        onClick={goToCatalog}
                    />
                </li>
                <li className="nav-item" role="presentation">
                    <NavTabButton
                        id="pills-collection-tab"
                        active={isActiveOption(OPTIONS.COLLECTIONS)}
                        text={t(COLLECTIONS)}
                        target="pills-collection"
                        onClick={goToCollections}
                    />
                </li>
                { origin && origin.match(Origin.USA) ? (
                    <li className="nav-item" role="presentation">
                        <NavTabButton
                            id="pills-marketing-tab"
                            active={isActiveOption(OPTIONS.MARKETING)}
                            text={t(MENU_OPTION_MARKETING)}
                            target="pills-marketing"
                            onClick={goToMarketing}
                        />
                    </li>
                ) : null }

            </ul>
        </div>
    );
};
