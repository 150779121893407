export enum OrderFilterRequestName{
    PHASE = 'phase',
    PO = 'order-po',
    START_DATE = 'dateStart',
    FINAL_DATE = 'dateEnd',
}

export enum StatusOrderFilterName {
    PREPARING = 'EN PREPARACION',
    ORDERED = 'PEDIDO',
    CARRIED = 'CARGADO',
    INVOICED = 'FACTURADO',
    PREPARED = 'PREPARADO',
    OPEN = 'Open',
    RELEASED = 'Released',
}

export interface OrderFilterRequest {
    request: OrderFilterRequestItem []
}

export interface OrderFilterRequestItem {
    name: OrderFilterRequestName,
    value: string,
}
