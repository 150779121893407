import React from 'react';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { useNavigate, Link } from 'react-router-dom';
import 'swiper/components/pagination/pagination.scss';
import { useTranslation } from 'react-i18next';
import { FEATURED_COLLECTIONS, VIEW_ALL, BESTSELLERS, OUTLET, SETTLEMENT_SENTENCE } from 'i18n/translations/TR';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { SwiperCard } from 'app/shared/SwiperCard';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { useFeaturedCollectionList } from '../featured-collections/shared/domain/FeaturedCollectionList';
import { FeaturedCollectionItem } from '../featured-collections/FeaturedCollectionItem';
import { useTopTrendingList } from './shared/domain/Top trending/TopTrendingList';
import { useOutletList } from './shared/domain/Outlet/OutletList';
import { ApiProduct } from './shared/domain/ApiProduct';
import { ApiCollection } from '../featured-collections/shared/domain/ApiCollection';

SwiperCore.use([Pagination]);

interface ProductCarouselProps {
    title: string;
}

const swiperBreakpoints = {
    640: {
        width: 640,
        slidesPerView: 1,
    },
    768: {
        width: 768,
        slidesPerView: 2,
    },
    1200: {
        width: 1200,
        slidesPerView: 3,
    },
};

const pagination = {
    el: '.swiper-pagination',
    clickable: true,
};

export const ProductsCarousel: React.FC<ProductCarouselProps> = ({ title }) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const productsData: ApiProduct[] = [];
    const collectionsData: ApiCollection[] = [];

    let serverErrorMessage: boolean = false;

    const classes = classNames(
        'wow-section',
        { 'bg-light': title === t(FEATURED_COLLECTIONS) },
        { 'bg-white': title === t(BESTSELLERS) },
        { 'bg-white': title === t(OUTLET) },
    );

    let urlRouter: string = '';
    let location: string = '';
    let loading: boolean = false;

    if (title === t(BESTSELLERS)) {
        let result :ApiProduct[] = [];
        const products = useTopTrendingList();
        loading = products?.loading;
        const error = products?.error;
        if (error) {
            serverErrorMessage = true;
        } else {
            result = products.data;
            result.map((product: ApiProduct) => (
                productsData.push(product)
            ));
        }
        urlRouter = TencerRoutes.PRODUCT_DETAILS;
        location = t(BESTSELLERS);
    } else if (title === t(OUTLET)) {
        const products = useOutletList();
        loading = products.loading;
        const error = products?.error;
        if (error) {
            serverErrorMessage = true;
        } else {
            products.data.map((product: ApiProduct) => (
                productsData.push(product)
            ));
        }
        urlRouter = TencerRoutes.PRODUCT_DETAILS;
        location = t(OUTLET);
    } else if (title === t(FEATURED_COLLECTIONS)) {

        const collections = useFeaturedCollectionList();
        loading = collections.loading;
        const error = collections?.error;
        if (error) {
            serverErrorMessage = true;
        } else {
            collections.data.map((collection: ApiCollection) => (
                collectionsData.push(collection)
            ));
        }
        urlRouter = TencerRoutes.COLLECTION_DETAILS;
    }

    const urlRouterFormat = urlRouter.replace(':id', '');

    const goToCatalog = ():void => {
        history(TencerRoutes.CATALOG, { state: { productsType: location } });
    };

    const goToCollections = ():void => {
        history(TencerRoutes.COLLECTIONS);
    };

    return (
        <div>
            {!loading ?
                (
                    <div>
                        {!serverErrorMessage ?
                            (
                                <div>
                                    {collectionsData.length ? (
                                        <section className={classes}>
                                            <h3 className="container section-title">{title}</h3>
                                            <Swiper
                                                spaceBetween={32}
                                                breakpoints={swiperBreakpoints}
                                                pagination={pagination}
                                                className="container section-grid swiper-container swiper-card-products"
                                            >
                                                {(collectionsData.map((collection: ApiCollection) => (
                                                    <SwiperSlide key={collection.id} className="swiper-slide">
                                                        <Link
                                                            className="text-decoration-none w-100"
                                                            to={`${urlRouterFormat}${collection.id}`}
                                                            key={collection.id}
                                                        >
                                                            <FeaturedCollectionItem collection={collection} />
                                                        </Link>
                                                    </SwiperSlide>
                                                )))}
                                            </Swiper>
                                        </section>
                                    ) : null}

                                    {productsData.length ?
                                        (
                                            <section className={classes}>
                                                <h3 className="container section-title">{title}</h3>
                                                <Swiper
                                                    spaceBetween={32}
                                                    breakpoints={swiperBreakpoints}
                                                    pagination={pagination}
                                                    className="container
                                                    section-grid
                                                    swiper-container
                                                    swiper-card-products"
                                                >
                                                    {(title === t(BESTSELLERS) || title === t(OUTLET)) ?
                                                        (productsData.map((product: ApiProduct) => (
                                                            <SwiperSlide key={product.product} className="swiper-slide">
                                                                <div
                                                                    className="text-decoration-none w-100"
                                                                    key={product.product}
                                                                >
                                                                    <SwiperCard title={title} product={product} />
                                                                </div>
                                                            </SwiperSlide>
                                                        ))) : null}
                                                    <div className="swiper-pagination" />
                                                </Swiper>
                                                {(title === t(BESTSELLERS) || title === t(OUTLET)) ?
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={goToCatalog}
                                                        >
                                                            {t(VIEW_ALL)}
                                                        </button>
                                                    ) :
                                                    (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={goToCollections}
                                                        >
                                                            {t(VIEW_ALL)}
                                                        </button>
                                                    )}

                                                {title === t(OUTLET) ?
                                                    (
                                                        <p
                                                            className="small text-muted mt-4"
                                                        >
                                                            {t(SETTLEMENT_SENTENCE)}
                                                        </p>
                                                    ) : null }
                                            </section>
                                        ) : null}
                                </div>
                            ) : <ServerErrorMessage />}
                    </div>
                )
                : <Spinner classCss="basic-spinner-container" />}
        </div>
    );
};
