import OrderToLoad from '../../../domain/order-to-load';
import { PostLoadingOrderLinesRequestDTO } from '../dto/request/post-loading-order-lines-request.DTO';
import { PostLoadingOrderLineMapper } from './post-loading-order-line.mapper';

export class GetLoadingOrderPackagesMapper {

    public static fromDomain(
        ordersLoaded: OrderToLoad[],
    ): PostLoadingOrderLinesRequestDTO[] {

        const orderLinesDto: PostLoadingOrderLinesRequestDTO[] = [];

        ordersLoaded.forEach((item) => {
            item.linesToLoad.forEach((line) => {
                orderLinesDto.push(
                    PostLoadingOrderLineMapper.fromDomain(line),
                );
            });
        });

        return orderLinesDto;
    }

}
