import i18next from '../../../../i18n';
import { ERROR_500 } from '../../../../i18n/translations/TR';

export class Name {

    private readonly _value: string;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this._value = value;
    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined || value === '') {
            throw i18next.t(ERROR_500); // Todo crear error específico
        }
    }

    public get value(): string {
        return this._value;
    }

}
