import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { SHIPPING_ADDRESS_LABEL_FAX } from '../../../../../../i18n/translations/TR';

interface ShippingAddressFaxFieldProps {
    faxRegister: UseFormRegisterReturn
}

export const ShippingAddressFaxField = (
    {
        faxRegister,
    }: ShippingAddressFaxFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="form-field fax-field">
            <label htmlFor="fax" className="form-label">{t(SHIPPING_ADDRESS_LABEL_FAX)}</label>
            <input
                type="text"
                id="fax"
                className="form-control"
                {...faxRegister}
            />
        </div>
    );
};
