import React from 'react';
import { ModalError } from '../../../../shared/ModalError';

interface IOrdersToLoadErrorModalProps{
    message: string
    goBack: () => void
    showModal: boolean
}
export const OrdersToLoadErrorModal = (props: IOrdersToLoadErrorModalProps): React.ReactElement => {
    return (
        <ModalError
            onHide={props.goBack}
            show={props.showModal}
            message={props.message}
        />
    );
};
