import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import OrderLine from '../../model/OrderLine';
import { OrderLineStatusEnum } from '../../model/value-objects/OrderLineStatus';

interface OrderLineDeliveryDateProps {
    line: OrderLine;
}
export const OrderLineDeliveryDate = (props: OrderLineDeliveryDateProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="product-tag">
            <span className="product-tag-label">
                {
                    props.line.status.value === OrderLineStatusEnum.F ?
                        t('ORDER_LINE_AVAILABILITY_DATE') : t('ORDER_LINE_DELIVERY_DATE')
                }
            </span>
            <span className="product-tag-value">
                {moment(props.line.deliveryDate).format('L')}
            </span>
        </div>
    );
};
