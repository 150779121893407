import { Address } from 'app/account/domain/value-objects/address';
import { PostalCode } from 'app/account/domain/value-objects/postal-code';
import { Country } from './country';
import { ShippingAddressId } from './ShippingAddressId';
import { ProvinceId } from './value-objects/ProvinceId';

export class ShippingAddress {

    private _code: ShippingAddressId;
    public get code(): ShippingAddressId {
        return this._code;
    }

    private _description: string;
    public get description(): string {
        return this._description;
    }

    private _country: Country;
    public get country(): Country {
        return this._country;
    }

    private _provinceId: ProvinceId;
    public get provinceId(): ProvinceId {
        return this._provinceId;
    }

    private _postalCode: PostalCode;
    public get postalCode(): PostalCode {
        return this._postalCode;
    }

    private _locality: string;
    public get locality(): string {
        return this._locality;
    }

    private _address: Address;
    public get address(): Address {
        return this._address;
    }

    private _phone: string;
    public get phone(): string {
        return this._phone;
    }

    private _address1: Address;
    public get address1(): Address {
        return this._address1;
    }
    private _address2: Address;
    public get address2(): Address {
        return this._address2;
    }

    protected _portal: boolean;
    public get portal(): boolean {
        return this._portal;
    }
    protected _fax: string | undefined;
    public get fax(): string | undefined {
        return this._fax;
    }

    public buildAddressInfo(): string {

        const address1Info = `${this.address1.value},`;
        const address2Info = `${this.address2.value},`;

        return `
            ${this.description},
            ${this.address.value},
            ${this.address1.value ? address1Info : ''}
            ${this.address2.value ? address2Info : ''}
            ${this.postalCode.value}
            ${this.locality}
            (${this.country.region.trim()})
            ${this.country.name}
        `;
    }

    constructor(
        code: ShippingAddressId,
        description: string,
        country: Country,
        provinceId: ProvinceId,
        postalCode: PostalCode,
        locality: string,
        address: Address,
        phone: string,
        address1: Address,
        address2: Address,
        portal: boolean,
        fax?: string,
    ) {
        this._code = code;
        this._description = description;
        this._country = country;
        this._provinceId = provinceId;
        this._postalCode = postalCode;
        this._locality = locality;
        this._address = address;
        this._phone = phone;
        this._address1 = address1;
        this._address2 = address2;
        this._portal = portal;
        this._fax = fax;
    }

}
