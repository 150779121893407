import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ProductMapper } from '../../../../orders/infrastructure/repository/api/mappers/ProductMapper';
import { Product } from '../../../domain/model/Product';
import { ProductRepositoryInterface } from '../../../domain/repository/ProductRepositoryInterface';

export class ProductRepository implements ProductRepositoryInterface {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async getProduct(productId: string): Promise<Product|undefined> {
        const response = await this.client.getProduct(productId);
        return ProductMapper.fromApiProductDetail(response);
    }

}
