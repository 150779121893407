import { useTranslation } from 'react-i18next';
import { PER_PIECE, PER_M2, PER_FEET } from 'i18n/translations/TR';
import { useOrigin } from './hooks/useOrigin';

export const getArea = (value: string): string => {

    const { t } = useTranslation();
    const origin = useOrigin();

    let unitString: string = '';

    if (value === '0') {
        unitString = t(PER_PIECE);
    } else if (value === '1') {
        if (origin === 'EUROPE') {
            unitString = t(PER_M2);
        } else if (origin === 'USA') {
            unitString = t(PER_FEET);
        }
    }

    return unitString;
};
