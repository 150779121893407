import React from 'react';
import { useTranslation } from 'react-i18next';
import { BACK, DOWNLOAD_PDF, LOADING } from 'i18n/translations/TR';
import { CActionButton } from '../../../../../shared/components/CActionButton/CActionButton';
import './section-header-loading-order-detail.scss';
import LoadingOrder from '../../../../domain/loading-order';

interface Props {
    title: string
    downloadPDF: () => void
    goBack: () => void
    isDownloadingPDF: boolean
    loadingOrder: LoadingOrder | undefined
}

export const SectionHeaderLoadingOrderDetail: React.FC <Props> = (
    {
        title,
        downloadPDF,
        goBack,
        isDownloadingPDF,
        loadingOrder,
    },
) => {
    const { t } = useTranslation();
    return (

        <div className="section-header-loading-order-detail-container">
            <div className="section-header-loading-order-title-container">
                <h3 className="section-title">{title}</h3>
                {loadingOrder ? (
                    <div>
                        <span className="status-label">
                            {loadingOrder.status.translate}
                        </span>
                    </div>
                ) : null}
            </div>
            <div className="section-header-loading-order-actions-container">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={goBack}
                >
                    {t(BACK)}
                </button>
                <CActionButton
                    text={t(DOWNLOAD_PDF)}
                    textIsBusy={t(LOADING)}
                    onClick={downloadPDF}
                    isBusy={isDownloadingPDF}
                    disabled={isDownloadingPDF}
                />
            </div>
        </div>
    );
};
