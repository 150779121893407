import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useTranslation } from 'react-i18next';
import { BESTSELLERS, OUTLET, PRODUCTS } from 'i18n/translations/TR';
import { API_CATALOG, API_CATALOG_SEARCH, API_TOP_TRENDING_PRODUCTS, API_OUTLET } from 'app/constants/urlConstants';
import { useOrigin } from 'app/shared/hooks/useOrigin';
import { Origin } from 'app/shared/domain/value-objects/Origin';
import { PageBanner } from '../page-banner/component/PageBanner';
import { ProductCatalogScreen } from './Catalog/ProductCatalogScreen';
import { ProductCollectionsScreen } from './Collections/ProductCollectionsScreen';
import { HeaderCatalogCollections } from './components/HeaderCatalogCollections';
import { MarketingCollectionView } from './components/MarketingCollection/MarketingCollection';

export const ProductsCatalogCollectionsScreen: React.FC = () => {
    const [isFilter, setIsFilter] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isBestSellers, setIsBestSellers] = useState(false);
    const [isOutlet, setIsOutlet] = useState(false);
    const origin = useOrigin();

    let searchParam: string = '';
    let searchString: string = '';
    let collectionParameter: string = '';
    let materialParameter: string = '';
    let brandParameter: string = '';
    let stockParameter: string = '';

    const [url, setUrl] = useState('');
    const [searchContent, setsearchContent] = useState('');
    const [materialLabel, setMaterialLabel] = useState('');
    const [collectionLabel, setCollectionLabel] = useState('');
    const [brandLabel, setBrandLabel] = useState('');
    const [stockLabel, setStockLabel] = useState<boolean>();

    const [stockParameterProp, setStockParameterProp] = useState<boolean>();
    const [materialParameterProp, setMaterialParameterProp] = useState<string>();
    const [collectionParameterProp, setCollectionParameterProp] = useState<string>();
    const [brandParameterProp, setBrandParameterProp] = useState<string>();

    const location = useLocation();
    const [key, setKey] = useState(0);
    const [title, setTitle] = useState('');
    const [toHide, setToHide] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (location.state) {
            setBrandParameterProp('');
            setCollectionParameterProp(undefined);
            setMaterialParameterProp('');
            setStockParameterProp(undefined);
            setBrandLabel('');
            setsearchContent('');
            setCollectionLabel('');
            setMaterialLabel('');
            setStockLabel(false);
            setIsSearch(false);

            setIsFilter(false);
            setIsBestSellers(false);
            setIsOutlet(false);
            setKey(key + 1);
            const state = location.state;
            if (state.productsType) {
                if (state.productsType === t(BESTSELLERS)) {
                    setIsBestSellers(true);
                    setToHide(true);
                    setTitle(t(BESTSELLERS));
                    setUrl(`${API_TOP_TRENDING_PRODUCTS}&page=1`);
                } else if (state.productsType === t(OUTLET)) {
                    setIsOutlet(true);
                    setToHide(true);
                    setTitle(t(OUTLET));
                    setUrl(`${API_OUTLET}&page=1`);
                } else if (state.productsType === t(PRODUCTS)) {
                    setToHide(false);
                    setTitle(t(PRODUCTS));
                    setUrl(`${API_CATALOG}&page=1&searchType=catalog`);
                }
            } else if (state.search) {
                setIsSearch(true);
                setsearchContent(state.search);
                setTitle(t(PRODUCTS));
                setToHide(false);
                searchString = state.search;
                setUrl(`${API_CATALOG_SEARCH}&page=1&textSearch=${searchString}`);
            } else if (state.searchCatalog) {
                setIsSearch(true);
                setsearchContent(state.searchCatalog);
                searchParam = state.searchCatalog;
                setTitle(t(PRODUCTS));
                setToHide(false);
                if (searchParam.length > 1) {
                    setUrl(`${API_CATALOG_SEARCH}&page=1&textSearch=${searchParam}&searchType=catalog`);
                }
            } else if (state.filters) {
                setIsFilter(true);
                setTitle(t(PRODUCTS));
                setToHide(false);

                materialParameter = '';
                brandParameter = '';
                collectionParameter = '';
                stockParameter = '';
                if (state.filters.material && state.filters.material.trim() !== '') {
                    setMaterialLabel(state.filters.materialName);
                    materialParameter = `&paste=${state.filters.material}`;
                    setMaterialParameterProp(state.filters.material);
                }

                if (state.filters.brand && state.filters.brand.trim() !== '') {
                    setBrandLabel(state.filters.brandName);
                    brandParameter = `&brand=${state.filters.brand}`;
                    setBrandParameterProp(state.filters.brand);
                }

                if (state.filters.collection && state.filters.collection.trim() !== '') {
                    setCollectionLabel(state.filters.collectionName);
                    collectionParameter = `&collectionId=${state.filters.collection}`;
                    setCollectionParameterProp(state.filters.collection);
                }

                if (state.filters.stock) {
                    setStockLabel(state.filters.stock);
                    stockParameter = '&availableStock=true';
                    setStockParameterProp(true);
                }

                // eslint-disable-next-line max-len
                setUrl(`${API_CATALOG}&page=1${collectionParameter}${materialParameter}${stockParameter}${brandParameter}&searchType=catalog`);
            }
        }
    }, [location]);

    return (
        <>
            {window.location.pathname === TencerRoutes.MARKETING
            && origin && origin.match(Origin.USA) ? <MarketingCollectionView /> : null}
            {window.location.pathname === TencerRoutes.CATALOG ? (
                <section className="header-section">
                    <PageBanner pageId={title} />
                    <HeaderCatalogCollections location={toHide} />
                    <div className="container tab-content" id="pills-tabContent">
                        <ProductCatalogScreen
                            key={key}
                            title={title}
                            urlGet={url}
                            stockLabel={stockLabel}
                            collectionLabel={collectionLabel}
                            materialLabel={materialLabel}
                            brandLabel={brandLabel}
                            isFilter={isFilter}
                            isSearch={isSearch}
                            isBestSellers={isBestSellers}
                            isOutlet={isOutlet}
                            collectionParameter={collectionParameterProp}
                            materialParameter={materialParameterProp}
                            brandParameter={brandParameterProp}
                            stockParameter={stockParameterProp}
                            searchContent={searchContent}
                        />
                    </div>
                </section>
            ) : null }
            {window.location.pathname === TencerRoutes.COLLECTIONS ? (
                <ProductCollectionsScreen key={key} />
            ) : null}
        </>
    );
};
