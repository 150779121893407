import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ORDERS_TABLE_COLUMN_AMOUNT,
    ORDERS_TABLE_COLUMN_DATE,
    ORDERS_TABLE_COLUMN_ORDER_NO,
    ORDERS_TABLE_COLUMN_PO,
    ORDERS_TABLE_COLUMN_SHIPING_ADDRESS,
    ORDERS_TABLE_COLUMN_STATUS,
    SHIPPING_METHOD,
} from '../../../../../../i18n/translations/TR';
import { useOrigin } from '../../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../../shared/domain/value-objects/Origin';

export const OrderListTableHead = (): React.ReactElement => {
    const { t } = useTranslation();
    const origin = useOrigin();
    return (
        <thead>
            <tr>
                <th>{t(ORDERS_TABLE_COLUMN_ORDER_NO)}</th>
                <th>{t(ORDERS_TABLE_COLUMN_PO)}</th>
                <th>{t(ORDERS_TABLE_COLUMN_SHIPING_ADDRESS)}</th>
                <th>{t(ORDERS_TABLE_COLUMN_DATE)}</th>
                <th>{t(ORDERS_TABLE_COLUMN_STATUS)}</th>
                <th className="order-price">{t(ORDERS_TABLE_COLUMN_AMOUNT)}</th>
                {origin === Origin.USA ? (
                    <th>{t(SHIPPING_METHOD)}</th>
                ) : null}
                <th>{}</th>
            </tr>
        </thead>
    );
};
