export enum ErrorCode {
    UNKNOWN = 0,
    CART_NO_STOCK_AVAILABLE = 30002,
    CART_NO_STOCK_AVAILABLE_ON_SINGLE_LOT = 30003,
    CART_BIG_RESERVE = 30004,
    CART_NO_STOCK_AVAILABLE_USA = 30007,
    CART_MUST_BE_IN_SAME_WAREHOUSE = 30008,
    CP_OUT_OF_RANGE= 40016,
    CONTAINER_MAX_WEIGHT_EXCEED= 40022,
}
