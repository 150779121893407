import { ErpUser } from '../../../domain/erp-user';
import { ContactMapper } from './contact.mapper';
import { ErpUserId } from '../../../domain/value-objects/erp-user-id';
import { ErpUserDTO } from '../dto/erp-user.DTO';
import { ErpUserName } from '../../../domain/value-objects/erp-user-name';
import { Cif } from '../../../domain/value-objects/cif';
import { Weight } from '../../../../orders/model/value-objects/Weight';

export class ErpUserMapper {

    public static toDomain(erpUserDto: ErpUserDTO): ErpUser {
        return new ErpUser(
            new ErpUserId(erpUserDto.id),
            new ErpUserName(erpUserDto.name),
            new Cif(erpUserDto.cif),
            ContactMapper.toDomain(erpUserDto.contact),
            erpUserDto.allow_quote_transport,
            new Weight(erpUserDto.container_max_weight),
        );

    }

}
