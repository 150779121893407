import CartItem from '../../../../domain/model/CartItem';
import { CartItemId } from '../../../../domain/value-objects/CartItemId';
import { Lot } from '../../../../domain/value-objects/Lot';
import { Item } from '../../../../shared/ApiItem';
import { Price } from '../../../../../orders/model/value-objects/Price';
import ProductQuantity from '../../../../../orders/model/value-objects/ProductQuantity';
import { Weight } from '../../../../../orders/model/value-objects/Weight';
import { ProductId } from '../../../../../products/domain/value-object/ProductId';

export class CartItemMapper {

    static fromResponse(response: Item): CartItem {
        return new CartItem(
            new CartItemId(response.id),
            new ProductId(response.product_id),
            new Price(response.price.amount),
            new Price(response.unit_price.amount),
            new Price(response.discount?.amount ?? 0.0),
            new Price(response.discount2?.amount ?? 0.0),
            new Weight(response.weight.amount),
            new ProductQuantity(
                response.quantity.amount ? parseFloat(response.quantity.amount.toFixed(3)) : 0,
                response.quantity.unit,
            ),
            new Lot(
                new ProductId(response.lot.product),
                response.lot.box,
                response.lot.caliber,
                response.lot.pallet_type,
                response.lot.quality,
                response.lot.quantity,
                response.lot.reference,
                response.lot.warehouse_location,
            ),
            response.quantity_in_pallets,
            response.quantity_in_boxes,
            response.quantity_in_pieces,
            response.out_of_stock,
        );
    }

}
