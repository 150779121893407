import React from 'react';
import { useTranslation } from 'react-i18next';
import { COWeight } from '../../../../shared/components/CO-weight';
import { Currency } from '../../../../shared/Currency';
import { getRoundedValues } from '../../../../shared/getRoundedValues';
import {
    CART_DISCOUNTS_APPLIED_LABEL,
    CONSOLIDATED_ORDER,
    LIFT_GATE_LABEL,
    ORDER_SHIPPING_ADDRESS,
    ORDER_SUMMARY_TITLE,
    ORDER_SUMMARY_TOTAL_PRODUCTS,
    ORDER_SUMMARY_TOTAL_WEIGHT,
    SHIPPING_INSTRUCTIONS,
    TRANSPORT_LABEL,
} from '../../../../../i18n/translations/TR';
import './order-summary.scss';
import Order from '../../../model/Order';
import { TotalPrice } from '../../../../cart/components/total-price/total-price';
import { ETransportType } from '../../../model/value-objects/transport-type';
import { DiscountsSummary } from '../../components/DiscountsSummary/DiscountsSummary';
import { DiscountsSummaryLayout } from '../../components/DiscountsSummary/DiscountsSummaryLayout';
import { ShippingAddress } from '../../../model/ShippingAddress';
import { useOrigin } from '../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../shared/domain/value-objects/Origin';

interface OrderSummaryProps {
    order: Order
    orderShippingAddress: ShippingAddress | undefined
}

export const OrderSummary = (
    {
        order,
        orderShippingAddress,
    }: OrderSummaryProps,
): React.ReactElement => {
    const origin = useOrigin();
    const { t } = useTranslation();

    const shippingInstructionsInfo = (): string => {
        if (!order.transport) {
            return '';
        }
        if (order.transport.liftGateRate && order.transport.isConsolidated) {
            return `${order.transport.type.translate} (${t(CONSOLIDATED_ORDER)} / ${t(LIFT_GATE_LABEL)})`;
        }
        if (!order.transport?.liftGateRate && order.transport?.isConsolidated) {
            return `${order.transport?.type.translate} (${t(CONSOLIDATED_ORDER)})`;
        }
        if (order.transport?.liftGateRate && !order.transport.isConsolidated) {
            return `${order.transport.type.translate} (${t(LIFT_GATE_LABEL)})`;
        }

        return order.transport.type.translate;
    };

    return (
        <div className="order-summary-container">
            <div className="order-summary-header">
                <h5 className="card-wow-title">{t(ORDER_SUMMARY_TITLE)}</h5>
                {order.net ? (
                    <TotalPrice
                        price={order.net}
                    />
                ) : null}
            </div>

            <div className="order-summary-box">
                <div className="order-summary-box-items-container">
                    <div className="order-summary-box-item">
                        <p className="order-summary-box-item-title">
                            {t(ORDER_SUMMARY_TOTAL_PRODUCTS)}
                        </p>
                        <div className="order-summary-box-item-body">
                            <p>{order.lines?.length}</p>
                        </div>
                    </div>
                    <div className="order-summary-box-item">
                        <p className="order-summary-box-item-title">
                            {t(ORDER_SUMMARY_TOTAL_WEIGHT)}
                        </p>
                        <div className="order-summary-box-item-body">
                            <COWeight
                                value={getRoundedValues(order.weightGross?.weight ?? 0)}
                                className="import"
                            />
                        </div>
                    </div>
                </div>
                <div className="order-summary-box-items-container">
                    <div className="order-summary-box-item">
                        <p className="order-summary-box-item-title">
                            {t(ORDER_SHIPPING_ADDRESS)}
                        </p>
                        <div className="order-summary-box-item-body">
                            <p>
                                { origin === Origin.EUROPE ?
                                    orderShippingAddress?.buildAddressInfo() :
                                    order.shippingAddress?.buildAddressInfo() }
                            </p>
                        </div>
                    </div>
                    {order.transport ? (
                        <div className="order-summary-box-item">
                            <p className="order-summary-box-item-title">
                                {t(SHIPPING_INSTRUCTIONS)}
                            </p>
                            <div className="order-summary-box-item-body">
                                <p>
                                    {shippingInstructionsInfo()}
                                </p>
                            </div>
                        </div>
                    ) : null}
                </div>
                {
                    order.discounts.length > 0 ? (
                        <div className="order-summary-box-items-container">
                            <div className="order-summary-box-item">
                                <p className="order-summary-box-item-title">
                                    {t(CART_DISCOUNTS_APPLIED_LABEL)}
                                </p>
                                <div className="order-summary-box-item-body">
                                    <DiscountsSummary
                                        discounts={order.discounts}
                                        gross={order.gross}
                                        layout={DiscountsSummaryLayout.FULL}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    order.transport?.freight || order.transport?.liftGateRate ? (
                        <div className="order-summary-box-items-container">
                            {
                                order.transport?.freight && order.transport.type.value === ETransportType.WOW ? (
                                    <div className="order-summary-box-item">
                                        <p className="order-summary-box-item-title">
                                            {t(TRANSPORT_LABEL)}
                                        </p>
                                        <div className="order-summary-box-item-body">
                                            <Currency
                                                value={getRoundedValues(order.transport?.freight.amount ?? 0.0)}
                                                className="import"
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                order.transport?.liftGateRate ? (
                                    <div className="order-summary-box-item">
                                        <p className="order-summary-box-item-title">
                                            {t(LIFT_GATE_LABEL)}
                                        </p>
                                        <div className="order-summary-box-item-body">
                                            <Currency
                                                value={getRoundedValues(order.transport.liftGateRate.amount ?? 0.0)}
                                                className="import"
                                            />
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
};
