import React from 'react';
import { ErrorType } from '../../../../shared/context/CurrentUserContext';

interface Props {
    message: ErrorType | undefined | string
}

export const ErrorMessage: React.FC <Props> = ({ message }) => {

    return (
        <div className="errorMessage">
            {message}
        </div>
    );
};
