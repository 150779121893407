import React from 'react';
import './orders-to-load-table.scss';
import { OrderToLoadTableHead } from './thead/order-to-load-table-head';
import { OrderToLoadTableBody } from './tbody/order-to-load-table-body';
import OrderToLoad from '../../../../domain/order-to-load';
import OrderLineToLoad from '../../../../domain/order-line-to-load';

interface OrdersToLoadTableProps {
    isOrderLoadedTable: boolean
    orders: OrderToLoad[]
    moveOrderToLoaded: (orderToMove: OrderToLoad) => void
    removeOrderFromLoaded: (orderToRemove: OrderToLoad) => void
    moveOrderLineToLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    removeOrderLineFromLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    checkIfOrderIsLoaded: (order: OrderToLoad) => boolean
    checkIfOrderLineIsLoaded: (order: OrderToLoad, line: OrderLineToLoad) => boolean
}
export const OrdersToLoadTable = (props: OrdersToLoadTableProps): React.ReactElement => {
    return (
        <table
            className="table orders-to-load-table table-responsive align-middle table-borderless"
        >
            <OrderToLoadTableHead />
            <OrderToLoadTableBody
                isOrderLoadedTable={props.isOrderLoadedTable}
                orders={props.orders}
                moveOrderToLoaded={props.moveOrderToLoaded}
                removeOrderFromLoaded={props.removeOrderFromLoaded}
                moveOrderLineToLoaded={props.moveOrderLineToLoaded}
                removeOrderLineFromLoaded={props.removeOrderLineFromLoaded}
                checkIfOrderIsLoaded={props.checkIfOrderIsLoaded}
                checkIfOrderLineIsLoaded={props.checkIfOrderLineIsLoaded}
            />
        </table>
    );
};
