import React from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DELIVERY_SERVICES,
    OBLIGATORY_FIELD_ERROR,
    SELECT_AN_OPTION,
} from '../../../../../../../../i18n/translations/TR';
import { DeliveryService } from '../../../../../../../shared/domain/delivery-service';

interface DeliveryServiceFieldProps {
    deliveryServices: DeliveryService[]
    deliveryServiceFieldError: FieldError | undefined
    deliveryServiceFieldRegister: UseFormRegisterReturn
}

export const DeliveryServiceField = (props: DeliveryServiceFieldProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="transport-form-container-item__delivery-services">
            <label htmlFor="deliveryService" className="form-label">
                <span>{t(DELIVERY_SERVICES)}</span>
            </label>
            <div className="row">
                <div className="col-lg-12">
                    <select
                        className="form-select"
                        id="deliveryService"
                        {...props.deliveryServiceFieldRegister}
                    >
                        <option value="">{t(SELECT_AN_OPTION)}</option>
                        {props.deliveryServices.map((value) => (
                            <option value={value.id.value}>{value.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {
                props.deliveryServiceFieldError ?
                    <div className="error-input-form">{t(OBLIGATORY_FIELD_ERROR)}</div>
                    :
                    null
            }
        </div>
    );
};
