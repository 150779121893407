/* eslint-disable react/require-default-props */
import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

interface Props {
    value: number | string,
    suffix?: string,
    prefix?: string,
    className?: string,
}

export const NumberFormatComponentPieceUnit: React.FC<Props> = ({ value, suffix, prefix, className }) => {
    const { i18n } = useTranslation();

    return (
        <NumberFormat
            value={value}
            displayType="text"
            suffix={suffix}
            prefix={prefix}
            className={className!}
            thousandSeparator={i18n.language === 'es' ? '.' : ','}
            decimalSeparator={i18n.language === 'es' ? ',' : '.'}
        />
    );
};
