import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'app/shared/Spinner';
import {
    TOTAL_PRODUCTS,
    TOTAL_WEIGHT,
    STOCK_TIME_REMAINING,
    CART_SUMMARY_PRODUCT_LINE_FOR_MANUFACTURING,
    CART_DISCOUNTS_APPLIED_LABEL,
} from 'i18n/translations/TR';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import { COWeight } from 'app/shared/components/CO-weight';
import Cart from '../../domain/model/Cart';
import CartItem from '../../domain/model/CartItem';
import { ListItemCart } from '../ListItemCart';
import { DiscountsSummary } from '../../../orders/application/components/DiscountsSummary/DiscountsSummary';

export interface CartItemsListPropsInterface {
    cart: Cart,
    isSubmitting: boolean,
    onChange: () => void,
    onRemove: () => void,
}

export const CartItemsList: React.FC<CartItemsListPropsInterface> = (props: CartItemsListPropsInterface) => {

    const { t } = useTranslation();
    const state = React.useContext(CurrentUserContext);
    const orderByCreationDate = (item1:CartItem, item2:CartItem):number => {
        if (item1.id.value.toLowerCase() < item2.id.value.toLowerCase()) { return -1; }
        if (item1.id.value.toLowerCase() > item2.id.value.toLowerCase()) { return 1; }

        return 0;
    };
    const stockedItems = props.cart.items.filter((item) => !item.outOfStock);
    const outOfStockItems = props.cart.items.filter((item) => item.outOfStock);

    return (
        <div className="cart-items-list">
            {props.cart && props.cart.items.length > 0 ?
                (
                    <>
                        <div className="col-lg-12">
                            <div className="card-box">
                                <ul className="card-box-row">
                                    <li className="card-box-list-item">
                                        <p className="title">{t(TOTAL_PRODUCTS)}</p>
                                        <p>{props.cart.items.length}</p>
                                    </li>
                                    <li className="card-box-list-item">
                                        <p className="title">{t(TOTAL_WEIGHT)}</p>
                                        {props.cart.weight ? (
                                            <p>
                                                <COWeight
                                                    value={getRoundedValues(props.cart.weight.weight)}
                                                    className="import"
                                                />
                                            </p>
                                        ) : null}
                                    </li>
                                    <li className="card-box-list-item">
                                        <p className="title">
                                            {t(STOCK_TIME_REMAINING)}
                                        </p>
                                        <p>{state?.timer}</p>
                                    </li>
                                    {
                                        props.cart.discounts.length > 0 ? (
                                            <li className="card-box-list-item">
                                                <p className="title">{t(CART_DISCOUNTS_APPLIED_LABEL)}</p>
                                                <DiscountsSummary
                                                    gross={props.cart.brutePrice()}
                                                    discounts={props.cart.discounts}
                                                />
                                            </li>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        </div>

                        {stockedItems.length > 0 ? (
                            <div className="col-lg-12">
                                <ul className="section-list">
                                    {
                                        stockedItems
                                            .sort(orderByCreationDate)
                                            .map((item) => (
                                                <ListItemCart
                                                    key={item.id.value}
                                                    item={item}
                                                    onChange={props.onChange}
                                                    onRemove={props.onRemove}
                                                    cartSummary={false}
                                                />
                                            ))
                                    }
                                </ul>
                            </div>
                        ) : null}

                        {outOfStockItems.length > 0 ? (
                            <div className="col-lg-12">
                                <h6>{t(CART_SUMMARY_PRODUCT_LINE_FOR_MANUFACTURING)}</h6>
                                <ul className="section-list">
                                    {
                                        outOfStockItems
                                            .sort(orderByCreationDate)
                                            .map((item) => (
                                                <ListItemCart
                                                    key={item.id.value}
                                                    item={item}
                                                    cartSummary={false}
                                                    onChange={props.onChange}
                                                    onRemove={props.onRemove}
                                                />
                                            ))
                                    }
                                </ul>
                            </div>
                        ) : null}
                    </>
                )
                : <Spinner classCss="header-section-spinner" />}
        </div>
    );
};
