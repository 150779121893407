import React from 'react';
import { useTranslation } from 'react-i18next';
import { ADD, BOX_UNIT, OUTLET, PIECE_UNIT } from 'i18n/translations/TR';
import { ApiProduct } from 'app/home/product/shared/domain/ApiProduct';
import { NumberFormatComponentPieceUnit } from 'app/shared/NumberFormatPieceUnit';
import { getWeight } from 'app/shared/getWeight';
import { getArea } from 'app/shared/getArea';
import { getSquareMeasurement } from 'app/shared/getSquareMeasurement';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { ReactComponent as IcSize } from '../../../../assets/img/icons/ic-size.svg';
import { ReactComponent as IcWeight } from '../../../../assets/img/icons/ic-weight.svg';
import { ReactComponent as IcArea } from '../../../../assets/img/icons/ic-area.svg';
import { getMeasurement } from '../../../shared/getMeasurement';
import { Currency } from '../../../shared/Currency';
import { MarketingCollectionId } from '../../../products/domain/model/MarketingCollectionId';
import { usePermission } from '../../../shared/hooks/use-Permission';

interface ProductProps {
    product: ApiProduct;
}

export const ListItem: React.FC<ProductProps> =
    (
        { product },
    ) => {
        const { t } = useTranslation();
        const permission = usePermission();
        return (
            <li className="list-item">
                <div className="product-info">
                    <div className="product-image">
                        <img src={product.image} alt="product" />
                    </div>
                    <div className="product-item-details">
                        <div className="product-title">
                            <span>{product.description}</span>
                            <span className="product-format">
                                {`${product?.format} ${getMeasurement()}`}
                            </span>
                            {product.outlet ? (
                                <span className="product-outlet-label">
                                    <span className="product-stock text-start">
                                        {t(OUTLET)}
                                    </span>
                                </span>
                            ) : null}
                        </div>
                        <div className="product-description">{product.collection}</div>
                        <div>
                            {product.boxPiece > 0 ? (
                                <div className="product-size">
                                    <div className="d-flex">
                                        <IcSize className="icon-mini" />
                                        {`${product.boxPiece} ${t(PIECE_UNIT)}/${t(BOX_UNIT)}`}
                                    </div>
                                </div>
                            ) : null}
                            {product.boxMeter > 0 ? (
                                <div className="product-size">
                                    <div className="d-flex">
                                        <IcArea className="icon-mini" />
                                        <NumberFormatComponentPieceUnit
                                            value={getRoundedValues(product.boxMeter, 3)}
                                            suffix={` ${getSquareMeasurement()}/${t(BOX_UNIT)}`}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {product.boxWeight > 0 ? (
                                <div className="product-weight">
                                    <div className="d-flex">
                                        <IcWeight className="icon-mini" />
                                        <NumberFormatComponentPieceUnit
                                            value={getRoundedValues(product.boxWeight)}
                                            suffix={` ${getWeight()}/${t(BOX_UNIT)}`}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="product-actions">
                    {product.price !== null ?
                        (
                            <div className="product-price">
                                <Currency
                                    value={product?.price.value}
                                    className="import"
                                />
                                <div className="units-type">{getArea(product.unit)}</div>
                            </div>
                        ) : null}
                    {permission.canDoOrders() && product.collectionId !== MarketingCollectionId ?
                        (
                            <button
                                type="button"
                                className="btn btn-secondary btn-mini"
                            >
                                {t(ADD)}
                            </button>
                        ) : null}
                </div>
            </li>
        );
    };
