import { Country } from './country';
import { Province } from './Province';
import { ProvinceId } from './value-objects/ProvinceId';

export class CountryByProvince {

    public constructor(
        private _country: Country,
        private _provinces: Province[],

    ) {
    }
    public get provinces(): Province[] {
        return this._provinces;
    }
    public set provinces(value: Province[]) {
        this._provinces = value;
    }
    public get country(): Country {
        return this._country;
    }
    public set country(value: Country) {
        this._country = value;
    }

    public getProvince(provinceId: ProvinceId): Province | undefined {
        return this._provinces.find((item) => item.id.value === provinceId.value);
    }

}
