import { Address } from 'app/account/domain/value-objects/address';
import { PostalCode } from 'app/account/domain/value-objects/postal-code';
import { Country } from './country';
import { ShippingAddressId } from './ShippingAddressId';
import { Province } from './Province';
import { ShippingAddress } from './ShippingAddress';
import { ProvinceId } from './value-objects/ProvinceId';

export class ShippingAddressDefault extends ShippingAddress {

    constructor(
        code: ShippingAddressId,
        description: string,
        country: Country,
        postalCode: PostalCode,
        locality: string,
        address: Address,
        phone: string,
        address1: Address,
        address2: Address,
        provinceId: ProvinceId,
        private _province: Province | null,
        protected _portal: boolean,
    ) {
        super(
            code,
            description,
            country,
            provinceId,
            postalCode,
            locality,
            address,
            phone,
            address1,
            address2,
            _portal,
        );
    }

    public get province(): Province | null {
        return this._province;
    }

}
