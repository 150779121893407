import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import 'swiper/components/effect-fade/effect-fade.scss';

interface Props {
    classNameProp: string,
    images: string[]
}

export const SwiperBackground: React.FC<Props> = ({ classNameProp, images }) => {

    SwiperCore.use([Autoplay, EffectFade]);

    return (
        <div>
            <Swiper
                slidesPerView={1}
                speed={4000}
                effect="fade"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                fadeEffect={{
                    crossFade: true, // enables slides to cross fade
                }}
                className={classNameProp}
            >
                { images.map((image) => (
                    <SwiperSlide
                        key={image}
                        className="swiper-slide"
                    >
                        <img src={image} alt="WOW slide" />
                    </SwiperSlide>
                )) }

            </Swiper>
        </div>
    );
};
