import React, { useContext } from 'react';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ProductId } from 'app/products/domain/value-object/ProductId';
import { ApiProductDetail } from './ApiProductDetail';
import { WarehouseLocation } from './WarehouseLocation';
import { useOrigin } from '../../../shared/hooks/useOrigin';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import { lotInfo } from './WarehouseInfo';
import { CurrentUserContext } from '../../../shared/context/CurrentUserContext';
import { useAnalyticsRepository } from '../../../shared/hooks/use-analytics-repository';
import { EAnalyticsEvent } from '../../../shared/domain/analytics';

interface UseProductDetailList {
    data?: ApiProductDetail
    error: boolean
    loading: boolean
    fetchGetProduct: () => void
    outOfStockWarehousesLocations: WarehouseLocation[]
}

export function useProductDetailsList(id: string): UseProductDetailList {
    const [data, setData] = React.useState<ApiProductDetail>();
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [outOfStockWarehousesLocations, setOutOfStockWarehousesLocations] = React.useState<WarehouseLocation[]>([]);
    const origin = useOrigin();
    const context = useContext(CurrentUserContext);
    const analyticsRepository = useAnalyticsRepository();

    const handleOutOfStockWarehousesLocations = (
        allWarehousesLocations: WarehouseLocation[],
        productLotInfo: lotInfo[],
    ): WarehouseLocation[] => {
        const productWareHousesCodes = new Set(productLotInfo.map((info) => info.warehouse_location.code));
        return allWarehousesLocations.filter((warehouse) => !productWareHousesCodes.has(warehouse.code));
    };

    const fetchGetWarehouseLocations = (productDetail: ApiProductDetail): void => {
        TencerApiClientSingleton.getInstance()
            .getWarehousesLocation()
            .then((warehousesLocation) => {
                if (productDetail?.lotInfo) {
                    setOutOfStockWarehousesLocations(
                        handleOutOfStockWarehousesLocations(warehousesLocation, productDetail?.lotInfo),
                    );
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    };

    const fetchGetProduct = ():void => {
        TencerApiClientSingleton.getInstance()
            .getProduct(id)
            .then((products) => {
                setData(products);
                if (origin === Origin.USA) {
                    fetchGetWarehouseLocations(products);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    const fetchPostProductDetailEvent = (productId: ProductId): void => {
        analyticsRepository.fetchPostProductDetailEventMutation.mutate({
            date: new Date(),
            event: EAnalyticsEvent.PRODUCT_DETAIL,
            productId,
        });
    };

    React.useEffect(() => {
        fetchGetProduct();
    }, [context?.cartModified]);

    React.useEffect(() => {
        fetchPostProductDetailEvent(new ProductId(id));
    }, []);

    return {
        data,
        error,
        loading,
        fetchGetProduct,
        outOfStockWarehousesLocations,
    };
}
