import { Product } from './Product';

export class Tile {

    public get products(): Product[] {
        return this._products;
    }
    public get type(): string {
        return this._type;
    }

    public constructor(
        private _products: Product[],
        private _type: string,
    ) {}

}
