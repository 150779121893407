import jwtDecode from 'jwt-decode';
import { PermissionDTO } from '../infrastructure/tencer-api/dto/permission.DTO';
import { PermissionArrayMapper } from '../infrastructure/tencer-api/data-mapper/permission-array.mapper';
import { PermissionArray } from '../domain/permission-array';
import { TencerAuthServiceInstance } from '../tencer-routing/tencer-auth-route/TencerAuthService';

export interface useTokenResponse {
    erpCode: string | undefined;
    exp: number | undefined;
    iat: number | undefined;
    rol: string | undefined;
    origin: string | undefined;
    username: string | undefined;
    permissions: PermissionArray;
}

export interface DecodedToken {
    erp_code: string;
    exp: number;
    iat: number;
    roles: string[];
    username: string;
    permissions: PermissionDTO[];
    origin: string;
}

export enum RolName {
    ADMIN = 'ROLE_ADMIN',
}

export const useToken = (): {
    permissions: PermissionArray;
    origin: string | undefined;
    erpCode: string | undefined;
    exp: number | undefined;
    iat: number | undefined;
    rol: string | undefined;
    username: string | undefined
} => {
    const token = TencerAuthServiceInstance.getToken();
    const decodedToken: DecodedToken | undefined = token ? jwtDecode(token) : undefined;
    const rol = decodedToken ? decodedToken.roles[0] : undefined;
    const username = decodedToken ? decodedToken.username : undefined;
    const erpCode = decodedToken ? decodedToken.erp_code : undefined;
    const exp = decodedToken ? decodedToken.exp : undefined;
    const iat = decodedToken ? decodedToken.iat : undefined;
    const origin = decodedToken ? decodedToken.origin : undefined;
    const permissions = decodedToken
        ? PermissionArrayMapper.toDomain(decodedToken.permissions)
        : new PermissionArray();

    return {
        rol,
        erpCode,
        iat,
        exp,
        origin,
        username,
        permissions,
    };
};
