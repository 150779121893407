import { ErpUserId } from 'app/account/domain/value-objects/erp-user-id';
import { PostShippingAddressRequest } from 'app/account/infrastructure/api/dto/request/post-shipping-address-request.DTO';
import { UserCountryResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/UserCountryResponse';
import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { CountryByProvince } from '../../../model/countryByProvince';
import { IShippingAddressRepository } from '../../../model/IShippingAddressRepository';
import { ShippingAddressId } from '../../../model/ShippingAddressId';
import { CountryMapper } from './mappers/CountryMapper';
import { ProvinceMapper } from './mappers/ProvinceMapper';
import { ShippingAddressMapper } from './mappers/ShippingAddressMapper';
import { ShippingAddressDefault } from '../../../model/ShippingAddressDefault';
import { ShippingAddress } from '../../../model/ShippingAddress';

export class ApiShippingAddressRepository implements IShippingAddressRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async putShippingAddress(
        shippingAddressId: ShippingAddressId,
        request: PostShippingAddressRequest,
    ): Promise<void> {
        return this.client.putShippingAddress(shippingAddressId.value, request);
    }

    async deleteShippingAddress(shippingAddressId: ShippingAddressId): Promise<void> {
        return this.client.deleteShippingAddress(shippingAddressId.value);
    }

    async getDefaultShippingAddress(userCredentialId: string): Promise<ShippingAddressDefault> {
        const shippingAddress = await this.client.getDefaultShippingAddress(userCredentialId);
        return ShippingAddressMapper.fromDefaultResponse(shippingAddress);
    }

    async getShippingAddress(shippingAddressId: ShippingAddressId): Promise<ShippingAddress> {
        const shippingAddress = await this.client.getShippingAddress(shippingAddressId.value);
        return ShippingAddressMapper.fromResponse(shippingAddress);
    }

    async putDefaultShippingAddress(
        erpCode: string,
        userCredentialId: string,
        shippingAddressId: ShippingAddressId,
    ): Promise<void> {
        return this.client.putDefaultShippingAddress(erpCode, userCredentialId, shippingAddressId.value);
    }

    async getShippingAddresses(
        erpCode: ErpUserId,
        limit?: number,
        description?: string,
        excludeDefault?: boolean,
    ): Promise<ShippingAddress[]> {
        const fetched = await this.client.getShippingAddresses(erpCode.value, limit, description, excludeDefault);
        return fetched;
    }

    async getUserCountries(): Promise<CountryByProvince[]> {
        const fetched = await this.client.getUserCountries();
        return fetched.map((countryByProvince:UserCountryResponse) => new CountryByProvince(
            CountryMapper.fromResponse(countryByProvince.country),
            countryByProvince.provinces
                .map((province) => ProvinceMapper.fromResponse(province)),
        ));
    }

    async postShippingAddress(request: PostShippingAddressRequest): Promise<void> {
        return this.client.postShippingAddress(request);
    }

}
