import React from 'react';
import { useTranslation } from 'react-i18next';
import { WELCOME, DESCRIPTION_LOGIN } from 'i18n/translations/TR';
import { SwiperBackground } from '../../shared/SwiperBackground';
import { BG_01, BG_02, BG_03, BG_04, BG_05, BG_06 } from '../../../assets/img';
import { LoginForm } from './shared/components/LoginForm';

export const SignInScreen: React.FC = () => {
    const { t } = useTranslation();
    const classNameProp = 'swiper-container-hero swiper-login';

    const images : string[] = [BG_01, BG_02, BG_03, BG_04, BG_05, BG_06];

    return (

        <div className="login-page">
            <div className="login-container">
                <div className="login-page-text">
                    <p className="login-welcome">{t(WELCOME)}</p>
                    <h1 className="login-title">
                        WOW
                        <span className="f-serif"> Design</span>
                    </h1>
                    <h3 className="login-description">
                        {t(DESCRIPTION_LOGIN)}
                    </h3>
                </div>
                <LoginForm />
                <SwiperBackground classNameProp={classNameProp} images={images} />
            </div>
        </div>

    );
};
