import { ProvinceId } from './value-objects/ProvinceId';
import { CountryId } from './value-objects/CountryId';

export class Province {

    public constructor(
        private _id: ProvinceId,
        private _countryId: CountryId,
        private _name: string,
        private _shortName: string,
        private _prefix: string|null,
        private _postalCodeMin: number| null,
        private _postalCodeMax: number| null,
    ) {
    }

    public get prefix(): string|null {
        return this._prefix;
    }
    public get shortName(): string {
        return this._shortName;
    }
    public get name(): string {
        return this._name;
    }
    public get countryId(): CountryId {
        return this._countryId;
    }
    public get id(): ProvinceId {
        return this._id;
    }

    public get postalCodeMin(): number| null {
        return this._postalCodeMin;
    }
    public get postalCodeMax(): number| null {
        return this._postalCodeMax;
    }

}
