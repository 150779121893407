import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ITransportRepository } from '../../domain/repositories/transport-repository-interface';
import { DeliveryService } from '../../../shared/domain/delivery-service';
import { DeliveryServiceMapper } from './api/data-mappers/delivery-service.mapper';

export class TransportRepository implements ITransportRepository {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async getLiftGatePrice(): Promise<number> {
        const response = await this.client.getLiftGatePrice();

        return response.value;
    }

    async getTransportPrice(): Promise<number> {
        const response = await this.client.getTransportPrice();

        return response.value;
    }

    async getDeliveryServices(): Promise<DeliveryService[]> {
        const response = await this.client.getDeliveryServices();

        return DeliveryServiceMapper.fromResponse(response);
    }

    async getAllowQuoteTransport(erpUserId: string): Promise<boolean> {
        const response = await this.client.getErpUser(
            {
                erpUserId,
            },
        );

        return response.allow_quote_transport;
    }

}
