import i18next from 'i18next';
import { CONTAINER_LABEL, TRUCK_LABEL } from '../../../../i18n/translations/TR';

export enum ELoadingOrderTransportType{
    TRUCK = 'TRUCK',
    CONTAINER = 'CONTAINER',
}
export class LoadingOrderTransportType {

    private readonly _value: ELoadingOrderTransportType;

    public constructor(value: ELoadingOrderTransportType) {
        this.ensureIsNotUndefined(value);
        this._value = value;
    }

    private ensureIsNotUndefined(value: ELoadingOrderTransportType): void {
        if (value === undefined) {
            throw new Error('LoadingOrderTransportType cannot be undefined');
        }
    }

    public get value(): ELoadingOrderTransportType {
        return this._value;
    }

    public get translate(): string {
        switch (this._value) {
            case ELoadingOrderTransportType.TRUCK:
                return i18next.t(TRUCK_LABEL) as string;
            case ELoadingOrderTransportType.CONTAINER:
            default:
                return i18next.t(CONTAINER_LABEL) as string;
        }
    }

}
