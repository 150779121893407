import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { LoadingOrderRepository } from '../../infrastructure/api/loading-order-repository';
import { FetchLoadingOrderMutationRequest } from './request/fetch-loading-order-mutation-request';
import LoadingOrder from '../../domain/loading-order';
import { LoadingOrderId } from '../../domain/value-objects/loading-order-id';
import OrderToLoad from '../../domain/order-to-load';
import LoadingOrderSlot from '../../domain/loading-order-slot';
import { FetchPostLoadingOrderMutationRequest } from './request/fetch-post-loading-order-mutation-request';
import { TypeAheadDTO } from '../../../shared/infrastructure/tencer-api/dto/typeAhead.DTO';

interface LoadingOrderRepositoryInterface {
    fetchGetLoadingOrderPackages: UseMutationResult<number, unknown, OrderToLoad[], unknown>
    fetchGetLoadingOrderDates: UseMutationResult<LoadingOrderSlot, unknown, void, unknown>
    fetchDownloadLoadingOrderPDF: UseMutationResult<Blob, unknown, LoadingOrderId, unknown>
    fetchGetLoadingOrder: UseMutationResult<LoadingOrder, unknown, LoadingOrderId, unknown>
    fetchGetOrdersToLoadMutation: UseMutationResult<OrderToLoad[], unknown, TypeAheadDTO, unknown>
    fetchGetContainerMaxWeight: UseMutationResult<number, unknown, string, unknown>
    fetchPostLoadingOrder: UseMutationResult<void, unknown, FetchPostLoadingOrderMutationRequest, unknown>
    fetchGetLoadingOrderMutation: UseMutationResult<LoadingOrder[], unknown, FetchLoadingOrderMutationRequest, unknown>
}

export const useLoadingOrderRepository = (): LoadingOrderRepositoryInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new LoadingOrderRepository(client);

    const fetchGetLoadingOrderMutation = useMutation(async (
        request: FetchLoadingOrderMutationRequest,
    ) => repository.getLoadingOrders(
        request.actualPage,
        request.resultsPerPage,
    ));

    const fetchDownloadLoadingOrderPDF = useMutation(async (
        loadingOrderId: LoadingOrderId,
    ) => repository.downloadLoadingOrderPDF(
        loadingOrderId,
    ));

    const fetchGetOrdersToLoadMutation = useMutation(async (
        loadingOrderShippingAddress: TypeAheadDTO,
    ) => repository.getOrdersToLoad(
        loadingOrderShippingAddress,
    ));

    const fetchGetLoadingOrderDates = useMutation(async () => repository.getLoadingOrderDates());

    const fetchGetLoadingOrderPackages = useMutation(async (
        ordersLoaded: OrderToLoad[],
    ) => repository.getLoadingOrderPackages(ordersLoaded));

    const fetchPostLoadingOrder = useMutation(async (
        request: FetchPostLoadingOrderMutationRequest,
    ) => repository.postLoadingOrder(
        request.date,
        request.ordersLoaded,
        request.shippingAddress,
        request.transportType,
    ));

    const fetchGetLoadingOrder = useMutation(async (
        loadingOrderId: LoadingOrderId,
    ) => repository.getLoadingOrder(
        loadingOrderId,
    ));

    const fetchGetContainerMaxWeight = useMutation(async (
        erpUserId: string,
    ) => repository.getContainerMaxWeight(
        erpUserId,
    ));

    return {
        fetchGetLoadingOrder,
        fetchPostLoadingOrder,
        fetchGetLoadingOrderDates,
        fetchGetContainerMaxWeight,
        fetchGetLoadingOrderMutation,
        fetchDownloadLoadingOrderPDF,
        fetchGetOrdersToLoadMutation,
        fetchGetLoadingOrderPackages,
    };
};
