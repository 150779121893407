import { WarehouseLocation } from '../../../product-details/shared/domain/WarehouseLocation';
import { ProductId } from '../../../products/domain/value-object/ProductId';

export class Lot {

    constructor(
        public readonly productId: ProductId,
        public readonly box: string,
        public readonly caliber: string,
        public readonly palletType: string,
        public readonly quality: string,
        public readonly quantity: number,
        public readonly reference: string,
        public readonly warehouse: WarehouseLocation | null,
    ) {}

}
