import React from 'react';
import {
    DEUTSCHE_FOOTER,
    DEUTSCHE_SHORT_FOOTER,
    ENGLISH_FOOTER,
    ENGLISH_SHORT_FOOTER,
    FRANCES_FOOTER,
    FRANCES_SHORT_FOOTER,
    ITALIANO_FOOTER,
    ITALIANO_SHORT_FOOTER,
    SPANISH_FOOTER,
    SPANISH_SHORT_FOOTER,
} from 'i18n/translations/TR';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { IcLanguagePrimary } from '../../../../assets/img/icons';
import { TencerLanguage } from '../../../../i18n';

type LanguageSelectorProps = {
    changeLanguage: (lng: TencerLanguage) => void;
};

export const LanguageSelector: React.FC<LanguageSelectorProps> = ({ changeLanguage }) => {
    const { t, i18n } = useTranslation();

    const changeString = (): string => {
        let languageName = '';
        if (i18n.language === 'en') {
            languageName = t(ENGLISH_SHORT_FOOTER);
        } else if (i18n.language === 'es') {
            languageName = t(SPANISH_SHORT_FOOTER);
        } else if (i18n.language === 'it') {
            languageName = t(ITALIANO_SHORT_FOOTER);
        } else if (i18n.language === 'de') {
            languageName = t(DEUTSCHE_SHORT_FOOTER);
        } else if (i18n.language === 'fr') {
            languageName = t(FRANCES_SHORT_FOOTER);
        }

        return languageName;
    };

    const handleLanguageChange = (lng: TencerLanguage): void => {
        changeLanguage(lng);
        if (lng.includes(TencerLanguage.SPANISH)) {
            document.cookie = 'es-ES';
        } else if (lng.includes(TencerLanguage.ENGLISH)) {
            document.cookie = 'en-EN';
        } else if (lng.includes(TencerLanguage.ITALIAN)) {
            document.cookie = 'it';
        } else if (lng.includes(TencerLanguage.GERMAN)) {
            document.cookie = 'de';
        } else if (lng.includes(TencerLanguage.FRENCH)) {
            document.cookie = 'fr';
        }
    };

    return (
        <Dropdown className="dropdown drop-language">
            <Dropdown.Toggle
                className="dropdown-toggle bg-transparent btn-link"
                variant="success"
                id="dropdown-basic"
            >
                <img
                    src={IcLanguagePrimary}
                    className="lan-icon"
                    alt="language"
                />
                {changeString()}
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item
                    className="dropdown-item"
                    onClick={
                        (): void => handleLanguageChange(TencerLanguage.ENGLISH)
                    }
                >
                    {t(ENGLISH_FOOTER)}
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdown-item"
                    onClick={(): void => handleLanguageChange(TencerLanguage.SPANISH)}
                >
                    {t(SPANISH_FOOTER)}
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdown-item"
                    onClick={(): void => handleLanguageChange(TencerLanguage.ITALIAN)}
                >
                    {t(ITALIANO_FOOTER)}
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdown-item"
                    onClick={(): void => handleLanguageChange(TencerLanguage.GERMAN)}
                >
                    {t(DEUTSCHE_FOOTER)}
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdown-item"
                    onClick={(): void => handleLanguageChange(TencerLanguage.FRENCH)}
                >
                    {t(FRANCES_FOOTER)}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
