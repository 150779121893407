import React from 'react';
import { useTranslation } from 'react-i18next';
import { PROCESS_ORDER, TOTAL_AMOUNT } from 'i18n/translations/TR';
import { getRoundedValues } from 'app/shared/getRoundedValues';
import { useNavigate } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { Price } from '../../orders/model/value-objects/Price';
import { Currency } from '../../shared/Currency';

interface Props {
    price: Price
}

export const CartFooter: React.FC<Props> = ({ price }) => {

    const history = useNavigate();

    const goToCartSummary = ():void => {
        history(TencerRoutes.CART_SUMMARY);
    };

    const { t } = useTranslation();

    return (
        <div className="cart-footer">
            <div className="container cart-footer-display">
                <div className="total-price-header">
                    <div className="total-price-title">{t(TOTAL_AMOUNT)}</div>
                    <div className="total-price-import">
                        <Currency
                            className="product-price m-0 import"
                            value={getRoundedValues(price.amount)}
                        />
                    </div>
                </div>

                <div onClick={goToCartSummary} role="presentation" className="btn btn-primary">{t(PROCESS_ORDER)}</div>

            </div>
        </div>
    );
};
