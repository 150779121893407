import { String } from 'app/shared/domain/value-objects/String';

export enum ORDER_STATUS_TYPE {
    ORDERED = 'PEDIDO',
    IN_PREPARATION = 'EN PREPARACION',
    READY = 'PREPARADO',
    CHARGED = 'CARGADO',
    INVOICED = 'FACTURADO',
    RELEASED = 'RELEASED',
    OPEN = 'OPEN',
    UNKNOWN = 'UNKNOWN',
}

export class Status extends String {
}
