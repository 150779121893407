import { ACCEPT, CANCEL } from 'i18n/translations/TR';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose: () => void
    onAccept: () => void
    isBusy: boolean
    title: string
    body: string
    show: boolean
}
export const ConfirmationModal = (props: Props):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            show={props.show}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{props.title}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{props.body}</div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn-link bg-transparent text-decoration-none"
                    onClick={props.onClose}
                    disabled={props.isBusy}
                >
                    {t(CANCEL)}
                </button>
                <button
                    type="submit"
                    form="add-form"
                    className="btn btn-primary"
                    disabled={props.isBusy}
                    onClick={props.onAccept}
                >
                    {
                        props.isBusy
                            ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                            : null
                    }
                    {t(ACCEPT)}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
