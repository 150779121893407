import React from 'react';
import { useTranslation } from 'react-i18next';
import { FLOOR_TILES, WALL_TILES, SPECIAL_PIECES, BOTH_TILES } from 'i18n/translations/TR';
import { DownloadsBanner } from 'app/shared/DownloadBanner';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { ProductMapper } from '../orders/infrastructure/repository/api/mappers/ProductMapper';
import { Product } from '../products/domain/model/Product';
import { useCollectionDetailsList } from './components/shared/CollectionDetailList';
import { HeaderSection } from './components/HeaderSection';
import { CollectionCarouselSection } from './components/CollectionCarouselSection';

export const CollectionDetails: React.FC = () => {

    const { t } = useTranslation();

    const url = window.location.pathname;

    const id = url.substring(url.lastIndexOf('/') + 1);

    let serverErrorMessage: boolean = false;

    const response = useCollectionDetailsList(id);
    const loading = response?.loading;
    const detail = response?.data;
    const error = response?.error;

    if (error) {
        serverErrorMessage = true;
    }

    let bothTiles:Product[] = [];
    if (detail?.bothTiles) {
        bothTiles = detail.bothTiles.map((product) => ProductMapper.fromApiCollectionList(product));
    }

    const floorTiles:Product[] =
    detail?.floorTiles ?
        detail?.floorTiles.map((product) => ProductMapper.fromApiCollectionList(product))
        : [];

    const wallTiles:Product[] =
    detail?.wallTiles ?
        detail?.wallTiles.map((product) => ProductMapper.fromApiCollectionList(product))
        : [];

    const specialTiles:Product[] =
    detail?.specialTiles ?
        detail?.specialTiles.map((product) => ProductMapper.fromApiCollectionList(product))
        : [];

    return (
        <div>
            {!loading ?
                (
                    <div>
                        {!serverErrorMessage ?
                            (
                                <div>

                                    {detail ?
                                        (
                                            <>
                                                {/* Sección banner inicial */}
                                                <HeaderSection
                                                    name={detail.name}
                                                    category={detail.name}
                                                />

                                                {/* Carrusel both tiles */}
                                                {detail.bothTiles ?
                                                    (
                                                        <CollectionCarouselSection
                                                            title={t(BOTH_TILES)}
                                                            products={bothTiles}
                                                        />
                                                    ) : null }

                                                {/* Carrusel floor tiles */}

                                                {detail.floorTiles ?
                                                    (
                                                        <CollectionCarouselSection
                                                            title={t(FLOOR_TILES)}
                                                            products={floorTiles}
                                                        />
                                                    ) : null }

                                                {/* Carrusel wall tiles */}

                                                {detail.wallTiles ?
                                                    (
                                                        <CollectionCarouselSection
                                                            title={t(WALL_TILES)}
                                                            products={wallTiles}
                                                        />
                                                    ) : null }

                                                {/* Carrusel special tiles */}
                                                {detail.specialTiles ?
                                                    (
                                                        <CollectionCarouselSection
                                                            title={t(SPECIAL_PIECES)}
                                                            products={specialTiles}
                                                        />
                                                    ) : null }

                                                { /* Sección descargas */ }
                                                <DownloadsBanner />
                                            </>
                                        )
                                        : null}
                                </div>
                            )
                            : <ServerErrorMessage />}
                    </div>
                )
                : <Spinner classCss="header-section-spinner" />}
        </div>
    );
};
