import React from 'react';

interface Props {
    message: string;
}

export const ThankYouMessage: React.FC <Props> = ({ message }) => {
    return (
        <div>
            {message}
        </div>
    );
};
