import React from 'react';
import { UseMutationResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_FIELD_CIF, ACCOUNT_FIELD_COMPANY_NAME, ACCOUNT_FIELD_EMAIL, ACCOUNT_FIELD_PHONE, ACCOUNT_SETTINGS_SUBTITLE, ACCOUNT_SETTINGS_TITLE } from '../../../../../../../i18n/translations/TR';
import { AccountSettingsResetPasswordForm } from './account-settings-reset-password-form/account-settings-reset-password-form';
import { ErpUser } from '../../../../../domain/erp-user';
import { PatchErpUserPasswordDTO } from '../../../../../infrastructure/api/dto/request/patch-erp-user-password.DTO';

interface AccountSettingsCardProps{
    erpUser:ErpUser
    mutationChangePassword: UseMutationResult<string, unknown, PatchErpUserPasswordDTO, unknown>
}
export const AccountSettingsCard = (
    {
        erpUser,
        mutationChangePassword,
    }:AccountSettingsCardProps,
):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <form className="row account-card">
            <h5 className="card-wow-title">{t(ACCOUNT_SETTINGS_TITLE)}</h5>
            <p className="account-card-subtitle">{t(ACCOUNT_SETTINGS_SUBTITLE)}</p>

            <div className="account-card-item">
                <label htmlFor="companyName" className="form-label">{t(ACCOUNT_FIELD_COMPANY_NAME)}</label>
                <input className="form-control" id="companyName" disabled value={erpUser.name.value} />
            </div>
            <div className="account-card-item">
                <label htmlFor="CIF/EIN" className="form-label">{t(ACCOUNT_FIELD_CIF)}</label>
                <input className="form-control" id="CIF/EIN" value={erpUser.cif.value ?? ''} disabled />
            </div>
            <div className="account-card-item">
                <label htmlFor="email" className="form-label">{t(ACCOUNT_FIELD_EMAIL)}</label>
                <input className="form-control" id="email" value={erpUser.Contact.mail?.email ?? ''} disabled />
            </div>
            <div className="account-card-item">
                <label htmlFor="phone" className="form-label">{t(ACCOUNT_FIELD_PHONE)}</label>
                <input className="form-control" id="phone" value={erpUser.Contact.phoneNumber?.value ?? ''} disabled />
            </div>

            <AccountSettingsResetPasswordForm
                mutationChangePassword={mutationChangePassword}
            />
        </form>
    );
};
