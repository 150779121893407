import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import MarketingCollection from '../../domain/model/MarketingCollection';
import { CollectionRepository } from '../../infrastructure/repository/tencer-api/CollectionRepository';

interface CollectionRepositoryMutationsInterface {
    getMarketingCollectionMutation:
    UseMutationResult<MarketingCollection, unknown, void, unknown>
}

export const useCollectionRepository = (): CollectionRepositoryMutationsInterface => {
    const repository = new CollectionRepository(TencerApiClientSingleton.getInstance());
    const getMarketingCollectionMutation = useMutation(async () => repository.getMarketingCollection());

    return {
        getMarketingCollectionMutation,
    };
};
