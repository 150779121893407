import { GetDeliveryServicesResponseDTO } from '../dto/response/get-delivery-services-response.DTO';
import { DeliveryService } from '../../../../../shared/domain/delivery-service';
import { DeliveryServiceId } from '../../../../../shared/domain/value-objects/delivery-service-id';

export class DeliveryServiceMapper {

    public static fromResponse(data: GetDeliveryServicesResponseDTO): DeliveryService[] {

        return data.couriers.map((service) => {
            return new DeliveryService(
                new DeliveryServiceId(service.code.value),
                service.name.value,
            );
        });

    }

}
