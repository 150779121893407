import React from 'react';
import { LoadingOrderDetailTableBody } from './tbody/loading-order-detail-table-body';
import OrderToLoad from '../../../../domain/order-to-load';
import { LoadingOrderDetailTableHead } from './thead/loading-order-detail-table-head';
import './loading-order-detail-table.scss';

interface LoadingOrderDetailTableProps {
    orders: OrderToLoad[]
}
export const LoadingOrderDetailTable = (props: LoadingOrderDetailTableProps): React.ReactElement => {
    return (
        <table
            className="
            table
            loading-order-detail-list
            table-responsive
            table-borderless
            "
        >
            <LoadingOrderDetailTableHead />
            <LoadingOrderDetailTableBody
                orders={props.orders}
            />
        </table>
    );
};
