export interface OrderShipmentResponse {
    type:{
        name: string,
        value: ETransportTypeDTO,
    },
    consolidated: boolean | null
    description: string,
    lift_gate_price: number | null,
    freight_price: number | null
}

export enum ETransportTypeDTO {
    WOW = 1,
    OWN_LOGISTIC = 2,
    WILL_CALL = 3,
    SMALL_PARCEL = 4,
}
