import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountShippingAddressCardItem } from '../account-shipping-address-card-item/account-shipping-address-card-item';
import { ShippingAddressId } from '../../../../../../../orders/model/ShippingAddressId';
import { ShippingAddress } from '../../../../../../../orders/model/ShippingAddress';
import { SHIPPING_ADDRESS_SELECTOR_SEARCH_PLACEHOLDER } from '../../../../../../../../i18n/translations/TR';

interface ShippingAddressSelectorProps {
    onSelect: (shippingAddress:ShippingAddressId) => void
    shippingAddresses: ShippingAddress[]
    handleShowEditModal: (addressToEdit: ShippingAddress) => void
    handleShowDeleteConfirmationModal: (addressToDelete: ShippingAddress) => void
}

export const ShippingAddressSelector = (props: ShippingAddressSelectorProps): React.ReactElement => {
    const { t } = useTranslation();
    const [filteredAddresses, setFilteredAddresses] = React.useState(props.shippingAddresses);
    const [selection, setSelection] = React.useState<ShippingAddress | undefined>(undefined);
    const [queryText, setQueryText] = React.useState<string>('');

    const filter = (query: string): void => {
        const filtered = props.shippingAddresses.filter((address: ShippingAddress) => {
            return address.description.toLowerCase().includes(query.toLowerCase())
                || address.code.value.toLowerCase().includes(query.toLowerCase());
        });

        const array: ShippingAddress[] = [];
        filtered.forEach((item): void => {
            array.push(item);
        });

        setFilteredAddresses(array);
    };

    React.useEffect(() => {
        filter(queryText);
    }, [queryText, props.shippingAddresses]);

    const handleSelected = (selected: ShippingAddress): void => {
        if (selected) {
            props.onSelect(selected.code);
            setSelection(selected);
        }
    };

    const renderItem = (option: ShippingAddress): React.ReactElement => {
        return (
            <AccountShippingAddressCardItem
                key={option.code.value}
                address={option}
                handleShowDeleteConfirmationModal={props.handleShowDeleteConfirmationModal}
                handleShowEditModal={props.handleShowEditModal}
                isSelected={selection?.code.value === option.code.value}
                onClick={():void => handleSelected(option)}
            />
        );
    };

    return (
        <div className="shipping-address-selector">
            <input
                type="text"
                className="shipping-address-selector-query"
                placeholder={t(SHIPPING_ADDRESS_SELECTOR_SEARCH_PLACEHOLDER)}
                onChange={(e): void => setQueryText(e.target.value)}
            />
            {(
                filteredAddresses.map((address: ShippingAddress) => (
                    renderItem(address)
                ))
            )}
        </div>
    );
};
