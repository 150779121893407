import { Phone } from '../../../domain/value-objects/phone';
import { Email } from '../../../domain/value-objects/email';
import { ErpContactDTO } from '../dto/erp-contact.DTO';
import { ErpContact } from '../../../domain/erp-contact';
import { ContactDescription } from '../../../domain/value-objects/contact-description';

export class ErpContactMapper {

    public static toDomain(erpContactDto: ErpContactDTO): ErpContact {
        return new ErpContact(
            new Email(erpContactDto.email),
            erpContactDto.events,
            new Phone(erpContactDto.phone),
            new ContactDescription(erpContactDto.description),
        );
    }

}
