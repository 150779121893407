import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CurrentUserContext, UserContextType } from '../../../shared/context/CurrentUserContext';
import { useIsMounted } from '../../../shared/hooks/useIsMounted';
import { useCartSummaryList, UseProductDetailList } from './use-cart-summary-list';
import { useAccount, UseAccountResponse } from '../../../shared/hooks/use-account';
import { useTransport, UseTransportResponse } from './use-transport';
import { useOrigin } from '../../../shared/hooks/useOrigin';
import { useToken, useTokenResponse } from '../../../shared/hooks/use-token';
import { Origin } from '../../../shared/domain/value-objects/Origin';
import Cart from '../../../cart/domain/model/Cart';
import { TencerRoutes } from '../../../shared/tencer-routing/TencerRoutes';
import {
    UseTransportFormName,
    UseTransportFormRegisters,
} from '../../domain/model/transport-form';
import { useCartSummaryRepository } from './use-cart-summary-repository';
import { getRoundedValues } from '../../../shared/getRoundedValues';
import { ETransportType } from '../../../orders/model/value-objects/transport-type';

export interface UseCartSummaryResponse {
    pageName: string
    totalAmount: number
    watchLiftGate: boolean
    origin: Origin | undefined
    state: UserContextType | null
    cart: Cart | null | undefined
    UseAccount: UseAccountResponse
    tokenPayload: useTokenResponse
    cartSummaryScreenIsBusy: boolean
    cartSummary: UseProductDetailList
    UseTransport: UseTransportResponse
    showTransportPriceErrorModal: boolean
    watchResponsible: ETransportType
    afterChangeShippingAddressDefault: () => void
    handleHideTransportPriceErrorModal: () => void
    checkoutCart: (transportInfo?: UseTransportFormRegisters) => void
}

export const useCartSummary = (): UseCartSummaryResponse => {
    const isMounted = useIsMounted();
    const origin = useOrigin();
    const navigate = useNavigate();
    const tokenPayload = useToken();
    const UseAccount = useAccount();
    const cartSummary = useCartSummaryList();
    const state = React.useContext(CurrentUserContext);
    const repository = useCartSummaryRepository();

    const pageName = 'cart-summary';
    const cart = cartSummary?.cart;
    const [totalAmount, setTotalAmount] = useState(0);
    const [showTransportPriceErrorModal, setShowTransportPriceErrorModal] = useState(false);

    const handleShowTransportPriceErrorModal = (): void => {
        setShowTransportPriceErrorModal(true);
    };

    const handleHideTransportPriceErrorModal = (): void => {
        setShowTransportPriceErrorModal(false);
    };

    const UseTransport = useTransport(handleShowTransportPriceErrorModal);
    const watchLiftGate = UseTransport.UseTransportForm.watch(UseTransportFormName.LIFT_GATE);
    const watchResponsible = UseTransport.UseTransportForm.watch(UseTransportFormName.RESPONSIBLE);

    const checkoutCart = (transportInfo?: UseTransportFormRegisters): void => {
        if (origin === Origin.USA && watchResponsible === ETransportType.WOW && !UseTransport.hasTransportPrice) {
            setShowTransportPriceErrorModal(true);
        } else {
            repository.mutationCheckout.mutate(transportInfo, {
                onSuccess: () => {
                    state?.setExpirationDate(null);
                    state?.setCartItems(undefined);
                    navigate(TencerRoutes.ORDERS);
                },
            });
        }

    };

    const cartSummaryScreenIsBusy =
        cartSummary.isLoading ||
        UseTransport.isGettingLiftGatePrice ||
        UseTransport.isGettingDeliveryServices ||
        repository.mutationCheckout.isLoading;

    const calcTotalAmount = (): void => {
        const cartPrice = cartSummary.cart ? cartSummary.cart.price.amount : 0;
        let amount = 0;
        if (origin === Origin.USA) {
            amount += cartPrice;
            if (watchResponsible === ETransportType.WOW) {
                amount += UseTransport.transportPrice;
            }

            if (watchLiftGate) {
                amount += UseTransport.liftGatePrice;
            }

            setTotalAmount(getRoundedValues(amount));

        } else {
            setTotalAmount(getRoundedValues(cartPrice));
        }
    };

    const afterChangeShippingAddressDefault = (): void => {
        if (origin === Origin.USA) {
            UseTransport.getTransportPrice();
        }
    };

    useEffect((): void => {
        if (!isMounted) {
            return;
        }

        if (cart?.freeStockAt) {
            state?.setExpirationDate(cart.freeStockAt.toISOString());
        }
    }, [cart]);

    useEffect(() => {
        calcTotalAmount();
    }, [
        watchLiftGate,
        watchResponsible,
        cartSummary.cart?.price,
        UseTransport.transportPrice,
    ]);

    useEffect(() => {
        if (watchResponsible === ETransportType.WOW && !UseTransport.hasTransportPrice) {
            handleShowTransportPriceErrorModal();
        }

    }, [watchResponsible]);

    return {
        cart,
        state,
        origin,
        pageName,
        UseAccount,
        cartSummary,
        totalAmount,
        UseTransport,
        tokenPayload,
        checkoutCart,
        watchLiftGate,
        watchResponsible,
        cartSummaryScreenIsBusy,
        showTransportPriceErrorModal,
        afterChangeShippingAddressDefault,
        handleHideTransportPriceErrorModal,
    };
};
