import i18next from 'i18next';
import { ERROR_500 } from '../../../../i18n/translations/TR';

export class Address {

    private readonly _value: string;

    public constructor(value:string) {
        this.ensureIsNotUndefined(value);
        this._value = value?.trim();

    }

    private ensureIsNotUndefined(value: string): void {
        if (value === undefined) {
            throw i18next.t(ERROR_500); // Todo crear error específico
        }
    }

    public get value(): string {
        return this._value;
    }

}
