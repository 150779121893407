import React from 'react';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import {
    ADD,
    ADD_TO_CART,
    ADDING,
    CANCEL,
    CART_ITEM_SELECT_UNIT_TYPE_LABEL,
    MATERIAL,
    NAME,
    PRODUCT_SIZE,
    QUANTITY,
    STOCK_AVAILABLE,
    WAREHOUSE_SELECTOR_LABEL,
} from 'i18n/translations/TR';
import { ApiProductDetail } from '../shared/domain/ApiProductDetail';
import { StockAvailable } from './StockAvailable';
import { useShoppingModal } from './useShoppingModal';
import { NoStockModal } from './NoStockModal';
import { DifferentWarehousesModal } from './DifferentWarehousesModal';

interface Props {
    show: boolean;
    onHideShop: () => void;
    productDetails: ApiProductDetail;
    showForce: boolean
    handleCloseForce: () => void;
    handleShowForce: () => void;
}

export const ShoppingModal: React.FC<Props> = (
    { show,
        onHideShop,
        productDetails,
        showForce,
        handleCloseForce,
        handleShowForce },
) => {

    const shoppingModal = useShoppingModal(productDetails, onHideShop, handleShowForce, handleCloseForce);
    const { t } = useTranslation();
    const state = React.useContext(CurrentUserContext);

    return (
        <div>
            <Modal
                onHide={shoppingModal.closeModal}
                show={show}
                className="modal fade shopping-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title">{t(ADD_TO_CART)}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="detail">
                        <strong className="pe-1">{`${t(NAME)}: `}</strong>
                        {productDetails.description}
                    </div>
                    <div className="detail">
                        <strong className="pe-1">{`${t(PRODUCT_SIZE)}: `}</strong>
                        {productDetails.format}
                    </div>
                    <div className="detail">
                        <strong className="pe-1">{` ${t(MATERIAL)}: `}</strong>
                        {productDetails.paste}
                    </div>

                    <div className="detail">
                        <strong className="pe-1">{`${t(STOCK_AVAILABLE)}: `}</strong>
                        <div>
                            <StockAvailable
                                origin={shoppingModal.origin}
                                amount={productDetails?.stock.amount}
                                unit={productDetails?.unit}
                                lot={productDetails.lotInfo ?? []}
                            />
                        </div>
                    </div>
                    <div className="product-actions">
                        <div>
                            <form
                                id="add-form"
                                onSubmit={shoppingModal.handleSubmit(shoppingModal.submitHandler)}
                                className="number-input"
                            >
                                <div className="form-group form-group-quantity">
                                    <p className="product-action-title">{t(QUANTITY)}</p>
                                    <div className="product-action-body">
                                        <div className="container container-inline quantity-units-container">
                                            <select
                                                className="form-select"
                                                id="select-product-quantity-unit"
                                                {...shoppingModal.register('unit', {
                                                    required: true,
                                                })}
                                                onChange={(): void => {
                                                    shoppingModal.clearErrors('unit');
                                                    shoppingModal.setFocus('valueInput');
                                                }}
                                            >
                                                {productDetails.unit === '0' ? (
                                                    <option
                                                        key={shoppingModal.unitPiece.text}
                                                        value={shoppingModal.unitPiece.unit}
                                                    >
                                                        {shoppingModal.unitPiece.text}
                                                    </option>
                                                ) : null }
                                                {productDetails.unit === '1' ? (
                                                    <option
                                                        key={shoppingModal.unitM2.text}
                                                        value={shoppingModal.unitM2.unit}
                                                    >
                                                        {shoppingModal.unitM2.text}
                                                    </option>
                                                ) : null }
                                                {shoppingModal.unitOptions.map((unit) => (
                                                    <option key={unit.text} value={unit.unit}>
                                                        {unit.text}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className="container
                                                        container-inline
                                                        number-input-container
                                                        quantity-amount-container
                                                        "
                                        >
                                            <input
                                                id="input-product-quantity-value"
                                                className="quantity"
                                                type="number"
                                                min={shoppingModal.quantityStep !== 1 ? 0.01 : 1}
                                                max={shoppingModal.maxQuantity}
                                                step={shoppingModal.quantityStep}
                                                {...shoppingModal.register('valueInput', {
                                                    required: true,
                                                    valueAsNumber: true,
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {shoppingModal.warehouses.length > 0 ? (
                                    <div className="form-group form-group-warehouse">
                                        <p className="product-action-title">{t(WAREHOUSE_SELECTOR_LABEL)}</p>
                                        <select
                                            className="form-select"
                                            id="selectWarehouse"
                                            {...shoppingModal.register('warehouse')}
                                        >
                                            {shoppingModal.warehouses.map((warehouse) => (
                                                <option key={warehouse.code} value={warehouse.code}>
                                                    {warehouse.code}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null }
                            </form>
                        </div>
                    </div>
                    {shoppingModal.errors.valueInput ?
                        <p className="errorMessage">{shoppingModal.errors.valueInput.message}</p>
                        : null}
                    {shoppingModal.errors.unit ?
                        <p className="errorMessage">{t(CART_ITEM_SELECT_UNIT_TYPE_LABEL)}</p>
                        : null}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={shoppingModal.closeModal}
                        disabled={state!.cartLoading}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="submit"
                        form="add-form"
                        className="btn btn-primary"
                        disabled={state!.cartLoading}
                    >
                        {
                            state!.cartLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm button-loading"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : null
                        }
                        {state!.cartLoading ? t(ADDING) : t(ADD)}
                    </button>

                </Modal.Footer>
            </Modal>

            <Modal
                onHide={handleCloseForce}
                show={showForce}
                className="modal fade"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title">{t(ADD_TO_CART)}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div>{t(shoppingModal.errorMessage)}</div>
                </Modal.Body>

                <Modal.Footer>
                    <button
                        type="button"
                        className="btn-link bg-transparent text-decoration-none"
                        onClick={handleCloseForce}
                        disabled={state!.cartLoading}
                    >
                        {t(CANCEL)}
                    </button>
                    <button
                        type="submit"
                        form="add-form"
                        className="btn btn-primary"
                        disabled={state!.cartLoading}
                        onClick={shoppingModal.handleSubmit(shoppingModal.submitHandler)}
                    >
                        {
                            state!.cartLoading ? (
                                <span
                                    className="spinner-border spinner-border-sm button-loading"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : null
                        }
                        {state!.cartLoading ? t(ADDING) : t(ADD)}
                    </button>

                </Modal.Footer>
            </Modal>

            <NoStockModal
                showModal={shoppingModal.showNoStockUsaModal}
                closeModal={shoppingModal.handleCloseNoStockUsaModal}
            />

            <DifferentWarehousesModal
                showModal={shoppingModal.showDifferentWarehousesModal}
                closeModal={shoppingModal.handleDifferentWarehousesModal}
            />
        </div>
    );
};
