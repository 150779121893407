import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../../hooks/use-cart-summary';
import { PO_LABEL } from '../../../../../../../i18n/translations/TR';

interface IOrderPoProps {
    UseCartSummary: UseCartSummaryResponse
}
export const OrderPoField = ({
    UseCartSummary,
}: IOrderPoProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="po-container">
            <label htmlFor="clientRef" className="cart-content-subtitle">
                <span>{t(PO_LABEL)}</span>
            </label>
            <input
                type="text"
                className="po-input"
                id="clientRef"
                maxLength={20}
                {...UseCartSummary.cartSummary.cartCheckoutForm.clientRefInput}
            />
        </div>
    );
};
