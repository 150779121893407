import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import React from 'react';
import { ApiFilterFields } from './ApiFilterFields';

interface UseProductList {
    data?: ApiFilterFields;
    error: string;
    loading: boolean;
}

export function useFilterFieldsList(): UseProductList {
    const [data, setData] = React.useState<ApiFilterFields>();
    const [error, setError] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance()
            .getFilterValues()
            .then((products) => setData(products))
            .catch((e) => setError(e))
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
    };
}
