import { ProductId } from '../value-object/ProductId';
import { ProductPrice } from '../value-object/ProductPrice';
import { Packing } from './Packing';

export class Product {

    public get id(): ProductId {
        return this._id;
    }

    public constructor(
        private _id: ProductId,
        private _packing: Packing,
        private _box: string | null = null,
        private _price: ProductPrice | null = null,
    ) {}

    public get price(): ProductPrice | null {
        return this._price;
    }
    public get packing(): Packing {
        return this._packing;
    }

    public get box(): string | null {
        return this._box;
    }

    public get description(): string | null {
        return this._packing.description;
    }

    public get boxMeter(): number | null {
        return this._packing.boxMeter;
    }
    public get boxMeterL(): number | null {
        return this._packing.boxMeterL;
    }
    public get boxPiece(): number | null {
        return this._packing.boxPiece;
    }
    public get boxWeight(): number | null {
        return this._packing.boxWeight;
    }
    public get default(): boolean | null {
        return this._packing.default;
    }
    public get palletBox(): number | null {
        return this._packing.palletBox;
    }
    public get tone(): string | null {
        return this._packing.code;
    }

    public get image(): string | null {
        return this._packing.image ? this._packing.image.value : null;
    }

    public get collectionName(): string | null {
        return this._packing.collectionName ? this._packing.collectionName.value : null;
    }
    public get collectionId(): number | null {
        return this._packing.collectionId ? this._packing.collectionId.value : null;
    }
    public get palletPlane(): number | null {
        return this._packing.palletPlane;
    }
    public get palletPiece(): number | null {
        return this._packing.palletPiece;
    }
    public get heights(): number | null {
        return this._packing.heights;
    }
    public get palletMeter(): number | null {
        return this._packing.palletMeter;
    }
    public get format(): string | null {
        return this._packing.format ? this._packing.format.value : null;
    }
    public get unit(): string {
        return this._packing.unit;
    }
    public get type(): string | null {
        return this._packing.type;
    }
    public get family(): string | null {
        return this._packing.family;
    }
    public get outlet(): boolean {
        return this._packing.outlet;
    }
    public get stock(): number | null {
        return this._packing.stock;
    }
    public get paste(): string | null {
        return this._packing.paste;
    }

    public get enabled(): boolean {
        return this._packing.enabled;
    }
    public get tile(): string | null {
        return this._packing.tile;
    }

}
