import { useState } from 'react';
import { OrderFilter } from '../../model/OrderFilter';
import { OrderFilterCollection } from '../../model/OrderFilterCollection';

export interface useOrderFiltersInterface {
    filters: OrderFilterCollection,
    applyFilters: (filters: OrderFilterCollection) => void,
    addFilter: (filter: OrderFilter) => void,
}

export enum OrderFilterName {
    PHASE = 'phase',
    PO = 'po',
    START_DATE = 'startDate',
    FINAL_DATE = 'finalDate',
}

export const useOrderFilters = (): useOrderFiltersInterface => {
    const defaultFilters = new OrderFilterCollection([]);

    const [filters, setFilters] = useState<OrderFilterCollection>(defaultFilters);

    const applyFilters = (appliedFilters: OrderFilterCollection): void => {
        const allFilters = new OrderFilterCollection([...defaultFilters.toArray(), ...appliedFilters.toArray()]);
        setFilters(allFilters);
    };

    const addFilter = (filter: OrderFilter): void => {
        const allFilters = new OrderFilterCollection([...filters.toArray(), filter]);
        setFilters(allFilters);
    };

    return {
        filters,
        applyFilters,
        addFilter,
    };
};
