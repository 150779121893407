import React from 'react';
import { Link } from 'react-router-dom';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Product } from '../../products/domain/model/Product';
import { MiniSwiperCard } from './MiniSwiperCard';

SwiperCore.use([Pagination, Navigation]);

interface Props {
    title: string,
    products: Product[]
}

export const CollectionCarouselSection: React.FC <Props> = ({ title, products }) => {

    const urlRouter = TencerRoutes.PRODUCT_DETAILS;
    const urlRouterFormat = urlRouter.replace(':id', '');

    return (
        <div>
            {products.length ?
                (
                    <section className="product-detail-section bg-white">
                        <h3 className="container section-title">{title}</h3>
                        <div
                            className="collection-swiper-wrapper
                            container product-type-grid swiper-container swiper-product-type"
                        >

                            {products.map((product:Product) => (
                                <div key={product.id.value} className="swiper-slide collection-swiper-slider">
                                    <Link
                                        to={`${urlRouterFormat}${product.id.value}`}
                                    >
                                        <MiniSwiperCard
                                            product={
                                                {
                                                    description: product.description ?? '',
                                                    product: product.id.value,
                                                    box: product.box ?? '',
                                                    boxMeter: product.boxMeter,
                                                    boxMeterL: product.boxMeterL,
                                                    boxPiece: product.boxPiece,
                                                    boxWeight: product.boxWeight,
                                                    code: product.tone,
                                                    palletBox: product.palletBox,
                                                    default: product.default ?? false,
                                                    image: product.image,
                                                    collection: product.collectionName,
                                                    collectionId: product.collectionId,
                                                    palletPlane: product.palletPlane,
                                                    palletPiece: product.palletPiece,
                                                    heights: product.heights,
                                                    palletMeter: product.palletMeter,
                                                    unit: product.unit,
                                                    format: product.format,
                                                    family: product.family ?? '',
                                                    type: product.type ?? '',
                                                    outlet: product.outlet,
                                                    stock: product.stock,
                                                    paste: product.paste,
                                                    price: product.price,
                                                    imageDetail: null,
                                                    imageHeader: '',
                                                    enabled: product.enabled,
                                                    tile: product.tile,
                                                }
                                            }
                                        />
                                    </Link>
                                </div>
                            ))}
                        </div>

                    </section>
                )
                : null}
        </div>
    );
};
