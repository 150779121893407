import { useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn, UseFormReturn } from 'react-hook-form';
import { UserClientRef } from 'app/shared/domain/value-objects/UserClientRef';
import { useOrderRepository } from '../../../orders/application/hooks/useOrderRepository';
import Cart from '../../../cart/domain/model/Cart';

interface CartSummaryFormInterface {
    clientRef: string | null;
}
export interface UseCartCheckoutFormInterface {
    form: UseFormReturn<CartSummaryFormInterface>
    clientRefInput: UseFormRegisterReturn
    submit: (callback?: () => void) => void
    POIsBusy: boolean
}

export const useCartCheckoutForm = (cart: Cart | null | undefined):UseCartCheckoutFormInterface => {
    const [POIsBusy, setPOIsBusy] = useState(false);
    const cartSummaryForm = useForm<CartSummaryFormInterface>();
    const clientRefInput = cartSummaryForm.register('clientRef', {
        required: false,
        maxLength: 20,
        onChange: () => {
            setPOIsBusy(true);
        },
    });
    const orderRepository = useOrderRepository();

    const submitClientRef = (clientRef: UserClientRef, callback?: () => void): void => {
        if (!cart) {
            return;
        }

        orderRepository.addClientRefMutation.mutate(
            {
                cartId: cart.id,
                clientRef,
            },
            {
                onSuccess: () => {
                    if (callback) {
                        callback();
                    }
                    setPOIsBusy(false);
                },
            },
        );
    };

    useEffect(
        () => {
            const timer = setTimeout(() => {
                const clientRef = cartSummaryForm.watch('clientRef');
                if (!clientRef) {
                    return;
                }
                submitClientRef(
                    new UserClientRef(clientRef),
                );

            }, 2000);
            return ():void => { clearTimeout(timer); };
        },
        [cartSummaryForm.watch('clientRef')],
    );

    const submit = (callback?: () => void | undefined): void => {
        return callback ? callback() : undefined;
    };

    return {
        form: cartSummaryForm,
        clientRefInput,
        submit,
        POIsBusy,
    };
};
