import { WarehouseLocationDTO } from '../dto/warehouseLocation.DTO';
import { WarehouseLocation } from '../../../shared/domain/WarehouseLocation';

export class WarehouseLocationMapper {

    public static toDomain(warehouseLocationsDto: WarehouseLocationDTO []):WarehouseLocation[] {
        const warehouseLocations: WarehouseLocation[] = [];

        warehouseLocationsDto.forEach((warehouseLocationDto):void => {
            warehouseLocations.push({
                name: warehouseLocationDto.name,
                code: warehouseLocationDto.code,
            });
        });
        return warehouseLocations;
    }

}
