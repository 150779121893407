import React from 'react';
import { useTranslation } from 'react-i18next';
import { LOADING } from 'i18n/translations/TR';

interface Props {
    classCss: string
}

export const Spinner: React.FC<Props> = ({ classCss }) => {

    const { t } = useTranslation();

    return (
        <div className={classCss}>
            <div className="spinner-grow" role="status">
                <span className="sr-only invisible">{t(LOADING)}</span>
            </div>
        </div>
    );
};
