import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ACCEPT, ERROR_500, ORDER_ATTACHED_FILES_LABEL, ORDER_ATTACHMENTS_EMPTY } from 'i18n/translations/TR';
import { COErrorMessage } from 'app/shared/components/CO-error-message';
import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import Order from '../../../model/Order';
import { ReactComponent as IcDownload } from '../../../../../assets/img/icons/ic-download.svg';
import { useOrderRepository } from '../../hooks/useOrderRepository';

interface AttachedFilesProps{
    order: Order
}
export const AttachedFiles = (props:AttachedFilesProps):React.ReactElement => {
    const { t } = useTranslation();
    const [showAttachedFilesModal, setShowAttachedFilesModal] = useState(false);
    const downloadFileMutation = useOrderRepository().fetchOrderFileMutation;

    const handleShowAttachedFilesModal = ():void => {
        setShowAttachedFilesModal(true);
    };

    const handleHideAttachedFilesModal = ():void => {
        setShowAttachedFilesModal(false);
        downloadFileMutation.reset();
    };

    const downloadAttachment = (orderId: OrderId, orderFileId: string):void => {
        downloadFileMutation.mutate(
            { orderId, orderFileId },
            {
                onSuccess: (data:Blob) => {
                    const file = new Blob([data], { type: data.type });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                },
            },
        );
    };

    return (
        <>
            <button
                type="button"
                className="btn"
                onClick={handleShowAttachedFilesModal}
            >
                {`(${props.order.files?.length}) ${t(ORDER_ATTACHED_FILES_LABEL)}`}
            </button>

            {
                showAttachedFilesModal ? (
                    <Modal
                        show={showAttachedFilesModal}
                        onHide={():void => setShowAttachedFilesModal(false)}
                        className="modal fade"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                            <h5 className="modal-title">{t(ORDER_ATTACHED_FILES_LABEL)}</h5>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                props.order && props.order.files && props.order.files.length > 0 ? (
                                    <ul className="attached-files-list">
                                        {
                                            props.order.files.map((file) => (
                                                <li key={file.id.value}>
                                                    <div
                                                        aria-hidden
                                                        className="attached-files-list-item-container"
                                                        onClick={
                                                            ():void => downloadAttachment(props.order.id, file.id.value)
                                                        }
                                                    >
                                                        {file.name?.value ? file.name.value : file.fileName.value}
                                                        <IcDownload />
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                ) : (
                                    <div>{t(ORDER_ATTACHMENTS_EMPTY)}</div>
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                downloadFileMutation.isError ? (
                                    <COErrorMessage message={t(ERROR_500)} className="errorMessage" />
                                ) : null
                            }
                            <button
                                type="submit"
                                form="add-form"
                                className="btn btn-primary"
                                onClick={handleHideAttachedFilesModal}
                                disabled={downloadFileMutation.isLoading}
                            >
                                {
                                    downloadFileMutation.isLoading ? (
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true" />
                                    ) : null
                                }
                                <span>
                                    {' '}
                                    {t(ACCEPT)}
                                </span>
                            </button>
                        </Modal.Footer>
                    </Modal>
                ) : null
            }
        </>
    );
};
