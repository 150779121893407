import React, { useState } from 'react';
import cx from 'classnames';
import moment from 'moment/moment';
import { OrderToLoadLineItem } from './order-to-load-line-item/order-to-load-line-item';
import { ReactComponent as IcNext } from '../../../../../../../../assets/img/icons/ic-next-white.svg';
import { ReactComponent as IcBack } from '../../../../../../../../assets/img/icons/ic-back-white.svg';
import { ReactComponent as IcChevronUp } from '../../../../../../../../assets/img/icons/ic-chevron-up.svg';
import { ReactComponent as IcChevronDown } from '../../../../../../../../assets/img/icons/ic-chevron-down.svg';
import OrderToLoad from '../../../../../../domain/order-to-load';
import i18n from '../../../../../../../../i18n';
import OrderLineToLoad from '../../../../../../domain/order-line-to-load';

interface OrderToLoadItemProps {
    isOrderLoadedTable: boolean
    order: OrderToLoad
    moveOrderToLoaded: (orderToMove: OrderToLoad) => void
    removeOrderFromLoaded: (orderToRemove: OrderToLoad) => void
    moveOrderLineToLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    removeOrderLineFromLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    checkIfOrderIsLoaded: (order: OrderToLoad) => boolean
    checkIfOrderLineIsLoaded: (order: OrderToLoad, line: OrderLineToLoad) => boolean
}

export const OrderToLoadItem = (props: OrderToLoadItemProps): React.ReactElement => {
    const [showLines, setShowLines] = useState(true);

    const orderToLoadInfoRowClassNames = cx({
        'order-to-load-info-row': true,
        'is-loaded': props.checkIfOrderIsLoaded(props.order) && !props.isOrderLoadedTable,
    });

    const renderOrderInfoRow = (): React.ReactElement => {
        return (
            <tr className={orderToLoadInfoRowClassNames}>
                <td>{props.order.id.value}</td>
                <td>{props.order.po}</td>
                <td>
                    {
                        props.order.orderToLoadDate
                            ? moment(props.order.orderToLoadDate)
                                .locale(i18n.language)
                                .format('MMM D, YYYY')
                            : ''
                    }
                </td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>
                    <button
                        type="button"
                        className="show-lines-button"
                        onClick={(): void => {
                            setShowLines(!showLines);
                        }}
                    >
                        {showLines ? (
                            <IcChevronUp />

                        ) : (
                            <IcChevronDown />
                        )}
                    </button>
                </td>
                {props.isOrderLoadedTable ? (
                    <td>
                        {props.isOrderLoadedTable ? (
                            <button
                                type="button"
                                className=" add-item-button primary"
                                onClick={(): void => props.removeOrderFromLoaded(props.order)}
                            >
                                <IcBack />
                            </button>
                        ) : null}
                    </td>
                ) : (
                    <td>
                        {!props.checkIfOrderIsLoaded(props.order) ? (
                            <button
                                type="button"
                                className="add-item-button primary"
                                onClick={(): void => props.moveOrderToLoaded(props.order)}
                            >
                                <IcNext />
                            </button>
                        ) : null}
                    </td>
                )}
            </tr>
        );
    };

    return (
        <>
            {renderOrderInfoRow()}
            {showLines ? (
                props.order.linesToLoad.map((line) => {
                    return (
                        <OrderToLoadLineItem
                            key={line.id.idx}
                            isOrderLoadedTable={props.isOrderLoadedTable}
                            itemIsLoaded={props.checkIfOrderLineIsLoaded(props.order, line)}
                            line={line}
                            onClick={(): void => {
                                if (!props.isOrderLoadedTable) {
                                    props.moveOrderLineToLoaded(props.order, line);
                                } else {
                                    props.removeOrderLineFromLoaded(props.order, line);
                                }
                            }}
                        />
                    );
                })
            ) : null}

            <tr className="empty-row">
                <td>
                    {}
                </td>
            </tr>
        </>
    );
};
