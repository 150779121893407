import React from 'react';
import { GA4React } from 'ga-4-react';
import { Environment } from 'environment';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import reportWebVitals from './reportWebVitals';

const ga4react = new GA4React(Environment.GA_CODE);

ga4react.initialize().then((ga4) => {
    const pageview = window.location.pathname + window.location.search;
    ga4.pageview(pageview);
    ga4.gtag('event', 'pageview', 'path');
}, (err) => {
    console.error(err);
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
