import React from 'react';
import { ReactComponent as IcDelete } from '../../../../assets/img/icons/ic-delete-grey.svg';

interface DeleteButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const DeleteButton = (props: DeleteButtonProps): React.ReactElement => {

    return (
        <button type="button" className="btn-icon btn-icon-delete" onClick={props.onClick}>
            <span>
                <IcDelete />
            </span>
        </button>
    );
};
