import React, { useState } from 'react';

export interface usePaginationInterface {
    actualPage:number
    resultsPerPage: number
    hasMore: boolean
    setResultsPerPage: React.Dispatch<React.SetStateAction<number>>
    setActualPage: React.Dispatch<React.SetStateAction<number>>
    refresh: (resultsLength:number) => void
    resetPaginator: (resultsLength: number) => void
}

export const usePagination = (initialPage: number = 15): usePaginationInterface => {
    const [actualPage, setActualPage] = useState<number>(1);
    const [resultsPerPage, setResultsPerPage] = useState<number>(initialPage || 15);
    const [hasMore, sethasMore] = useState(true);

    const nextPage = ():void => {
        setActualPage(actualPage + 1);
    };

    const refresh = (resultsLength: number): void => {
        const willHaveMore = resultsLength === resultsPerPage;
        sethasMore(willHaveMore);
        if (willHaveMore) {
            nextPage();
        }
    };

    const resetPaginator = (resultsLength: number): void => {
        const willHaveMore = resultsLength === resultsPerPage;
        sethasMore(willHaveMore);
        if (willHaveMore) {
            setActualPage(2);
        } else {
            setActualPage(1);
        }
    };

    return {
        resultsPerPage,
        setResultsPerPage,
        actualPage,
        setActualPage,
        hasMore,
        refresh,
        resetPaginator,
    };
};
