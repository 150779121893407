import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { AnalyticsRepository } from '../infrastructure/tencer-api/repository/analytics-repository';
import { FetchPostLogoutEventMutationRequest } from './request/fetch-post-logout-event-mutation-request';
import { FetchPostProductDetailEventMutationRequest } from './request/fetch-post-product-detail-event-mutation-request';

interface AnalyticsRepositoryInterface {
    fetchPostLogOutEventMutation: UseMutationResult<void, unknown, FetchPostLogoutEventMutationRequest, unknown>
    fetchPostProductDetailEventMutation:
    UseMutationResult<void, unknown, FetchPostProductDetailEventMutationRequest, unknown>
}

export const useAnalyticsRepository = (): AnalyticsRepositoryInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new AnalyticsRepository(client);

    const fetchPostLogOutEventMutation = useMutation(async (
        request: FetchPostLogoutEventMutationRequest,
    ) => repository.logOutEvent(
        request.date,
        request.event,
    ));

    const fetchPostProductDetailEventMutation = useMutation(async (
        request: FetchPostProductDetailEventMutationRequest,
    ) => repository.productDetailEvent(
        request.date,
        request.event,
        request.productId,
    ));

    return {
        fetchPostLogOutEventMutation,
        fetchPostProductDetailEventMutation,
    };
};
