import { OrderLineToLoadDTO, OrderToLoadDTO } from '../dto/order-to-load.DTO';
import OrderToLoad from '../../../domain/order-to-load';
import { OrderId } from '../../../../shared/domain/value-objects/OrderId';
import OrderLineToLoad from '../../../domain/order-line-to-load';
import { OrderLineToLoadId } from '../../../domain/value-objects/order-line-to-load-id';

export class OrderToLoadMapper {

    private static orderToLoadLinesToDomain(orderLinesToLoadDto: OrderLineToLoadDTO[]) : OrderLineToLoad[] {
        const orderLinesToLoad: OrderLineToLoad[] = [];

        orderLinesToLoadDto.forEach((line) => {
            orderLinesToLoad.push(
                new OrderLineToLoad(
                    new OrderLineToLoadId(
                        line.idx,
                        line.year,
                        Number(line.order_number),
                    ),
                    line.description,
                    line.format,
                    line.tone,
                    line.palets,
                    line.boxes_total,
                    line.pieces_total,
                    line.weight_gross,
                ),
            );
        });

        return orderLinesToLoad;
    }
    public static toDomain(orderToLoadDto: OrderToLoadDTO): OrderToLoad {
        return new OrderToLoad(
            new OrderId(orderToLoadDto.order),
            orderToLoadDto.user_client_ref,
            new Date(orderToLoadDto.date_time),
            this.orderToLoadLinesToDomain(orderToLoadDto.lines),
        );
    }

}
