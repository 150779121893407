import React from 'react';
import './HomeScreen.scss';
import { useTranslation } from 'react-i18next';
import { FEATURED_COLLECTIONS, BESTSELLERS, OUTLET } from 'i18n/translations/TR';
import { MainBanner } from './banner/MainBanner';
import { ProductsCarousel } from './product/ProductsCarousel';

interface HomeScreenProps {
    title: string,
    url: string
}

export const HomeScreen: React.FC<HomeScreenProps> = () => {
    const { t } = useTranslation();

    return (
        <div className="co-app">

            {/* Section main banner carousel */}
            <MainBanner />

            {/* Section carousel top trending products category */}
            <ProductsCarousel title={t(BESTSELLERS)} />

            {/* Section featured collections */}
            <ProductsCarousel title={t(FEATURED_COLLECTIONS)} />

            {/* Section carousel outlet products category */}
            <ProductsCarousel title={t(OUTLET)} />
        </div>
    );
};
