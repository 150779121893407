import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { CANCEL, TOTAL_AMOUNT, VALIDATE_CART } from '../../../../../i18n/translations/TR';
import { TencerRoutes } from '../../../../shared/tencer-routing/TencerRoutes';
import { Currency } from '../../../../shared/Currency';
import { OrderId } from '../../../../shared/domain/value-objects/OrderId';
import { UseCartCheckoutFormInterface } from '../../hooks/use-cart-checkout-form';
import { UseTransportFormRegisters } from '../../../domain/model/transport-form';
import { useOrigin } from '../../../../shared/hooks/useOrigin';
import { Origin } from '../../../../shared/domain/value-objects/Origin';
import { DownloadProformaButton } from '../../../../orders/application/components/DownloadProformaButton';
import { ETransportType } from '../../../../orders/model/value-objects/transport-type';

interface Props {
    orderId: OrderId
    totalAmount: number
    isGettingTransportPrice: boolean
    watchResponsible: ETransportType
    cartSummaryForm: UseCartCheckoutFormInterface
    UseTransportForm: UseFormReturn<UseTransportFormRegisters>
    checkoutCart: (transportInfo?: UseTransportFormRegisters) => void
}

export const CartSummaryFooter: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const origin = useOrigin();

    const backToCart = (): void => {
        navigate(TencerRoutes.CART);
    };

    const onSubmit = props.UseTransportForm.handleSubmit((data) => {
        props.checkoutCart(data);
    });

    return (
        <div className="cart-summary-footer">
            <div className="cart-summary-footer-price-container">
                <div className="cart-summary-footer-total-price-title">{t(TOTAL_AMOUNT)}</div>
                <div className="cart-summary-footer-total-price-import">
                    {props.isGettingTransportPrice && props.watchResponsible === ETransportType.WOW ? (
                        <Skeleton inline width={70} />
                    ) : (
                        <Currency value={props.totalAmount} />
                    )}
                </div>
            </div>
            <div className="cart-summary-footer-buttons-container">
                <button type="button" className="btn btn-primary-outline" onClick={backToCart}>
                    {t(CANCEL)}
                </button>

                <DownloadProformaButton
                    orderId={props.orderId}
                    cartSummaryForm={props.cartSummaryForm}
                />

                <button
                    className="c-action-button btn action-button "
                    type="button"
                    disabled={props.cartSummaryForm && props.cartSummaryForm.POIsBusy}
                    onClick={(): void => {
                        if (origin === Origin.USA) {
                            onSubmit();
                        } else {
                            props.checkoutCart();
                        }
                    }}
                >
                    <span className="text">{t(VALIDATE_CART)}</span>
                </button>
            </div>
        </div>
    );
};
