import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { FILTER, BESTSELLERS, PRODUCTS, OUTLET, HOME, IN_STOCK, NO_STOCK, SEARCH } from 'i18n/translations/TR';
import { useForm } from 'react-hook-form';
import { TencerRoutes } from './tencer-routing/TencerRoutes';
import { ReactComponent as IcFilter } from '../../assets/img/icons/ic-filter.svg';
import { ReactComponent as IcClose } from '../../assets/img/icons/ic-close.svg';

interface Props {
    outlet:boolean
    bestsellers:boolean
    searchContent: string
    title: string,
    materialLabel: string
    collectionLabel: string
    stockLabel?: boolean,
    isFilter: boolean
    handleShow: () => void,
    brandLabel: string,
}

interface FormInputs {
    search: string,
}

export const SectionHeaderCatalog: React.FC <Props> = (
    {
        handleShow,
        searchContent,
        title,
        materialLabel,
        collectionLabel,
        stockLabel,
        isFilter,
        brandLabel,
    },
) => {

    const history = useNavigate();

    const { t } = useTranslation();

    const location = useLocation();

    // eslint-disable-next-line no-undef
    const [interval, setInterval] = useState<NodeJS.Timeout>();

    const { register, watch } = useForm<FormInputs>({
        mode: 'onChange',
    });

    const watchSearch = useRef({});
    watchSearch.current = watch('search');

    const goAndSearch = (searchInput: string):void => {
        if (searchInput.length) {
            const form = searchInput;
            if (form.length >= 3) {
                if (interval) {
                    clearInterval(interval);
                }

                const intervalId = setTimeout(() => {
                    history(TencerRoutes.CATALOG, { state: { searchCatalog: form } });
                }, 2000);
                setInterval(intervalId);
            }
        }

    };

    const classes = classNames(
        'actions',
        { 'd-none': title === t(OUTLET) || title === t(BESTSELLERS) },
    );

    const classFilter = classNames(
        'filter-tag-container',
        isFilter ? 'd-flex' : 'd-none',
    );

    const classBreadcrumb = classNames(
        { 'd-none': title === t(PRODUCTS) },
    );

    const goCatalog = ():void => {
        history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
    };

    const goHome = ():void => {
        history(TencerRoutes.HOME);
    };

    const removeCollection = ():void => {
        if (location.state.filters.material ||
            location.state.filters.materialName ||
            location.state.filters.brand ||
            location.state.filters.brandName ||
            location.state.filters.stock === true) {
            history(
                TencerRoutes.CATALOG,
                { state:
                    { filters:
                        { material: location.state.filters.material,
                            materialName: location.state.filters.materialName,
                            stock: location.state.filters.stock,
                            brandName: location.state.filters.brandName,
                            brand: location.state.filters.brand } } },
            );
        } else {
            history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
        }
    };

    const removeStock = ():void => {

        if (location.state.filters.collection ||
            location.state.filters.collectionName ||
            location.state.filters.brand ||
            location.state.filters.brandName ||
            location.state.filters.material ||
            location.state.filters.materialName) {
            history(
                TencerRoutes.CATALOG,
                { state:
                    { filters:
                        { material: location.state.filters.material,
                            materialName: location.state.filters.materialName,
                            brandName: location.state.filters.brandName,
                            brand: location.state.filters.brand,
                            collectionName: location.state.filters.collectionName,
                            collection: location.state.filters.collection } } },
            );
        } else {
            history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
        }
    };

    const removeMaterial = ():void => {

        if (location.state.filters.collection ||
            location.state.filters.collectionName ||
            location.state.filters.brand ||
            location.state.filters.brandName ||
            location.state.filters.stock === true) {
            history(
                TencerRoutes.CATALOG,
                { state:
                    { filters:
                        { collectionName: location.state.filters.collectionName,
                            collection: location.state.filters.collection,
                            brandName: location.state.filters.brandName,
                            brand: location.state.filters.brand,
                            stock: location.state.filters.stock } } },
            );
        } else {
            history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
        }
    };

    const removeBrand = ():void => {

        if (location.state.filters.collection ||
            location.state.filters.collectionName ||
            location.state.filters.material ||
            location.state.filters.materialName ||
            location.state.filters.stock === true) {
            history(
                TencerRoutes.CATALOG,
                { state:
                    { filters:
                        { collectionName: location.state.filters.collectionName,
                            collection: location.state.filters.collection,
                            material: location.state.filters.material,
                            materialName: location.state.filters.materialName,
                            stock: location.state.filters.stock } } },
            );
        } else {
            history(TencerRoutes.CATALOG, { state: { productsType: t(PRODUCTS) } });
        }
    };
    const openFilters = ():void => {
        handleShow();
    };

    return (
        <>
            <nav aria-label="breadcrumb" className={classBreadcrumb}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item" onClick={goHome} role="presentation">{t(HOME)}</li>
                    <li className="breadcrumb-item" onClick={goCatalog} role="presentation">{t(PRODUCTS)}</li>
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
            </nav>
            <div className="header-catalog header-actions">
                <h3 className="section-title">{title}</h3>
                <div className={classes}>
                    <input
                        className="form-control me-2 form-search d-none"
                        type="search"
                        placeholder={t(SEARCH)}
                        aria-label="Search"
                        {...register(
                            'search',
                            { required: true },
                        )}
                        defaultValue={searchContent}
                        onChange={(e): void => goAndSearch(e.target.value)}
                    />
                    <p
                        className="bg-transparent"
                        onClick={openFilters}
                        aria-hidden="true"
                    >
                        <IcFilter />
                        <span>{t(FILTER)}</span>
                    </p>
                </div>
            </div>
            <div className={classFilter}>
                {materialLabel ? (
                    <div className="filter-tag">
                        <span>{materialLabel}</span>
                        <IcClose onClick={removeMaterial} role="presentation" />
                    </div>
                ) : null}

                {collectionLabel ? (
                    <div className="filter-tag">
                        <span>{collectionLabel}</span>
                        <IcClose onClick={removeCollection} role="presentation" />
                    </div>
                ) : null}

                {brandLabel ? (
                    <div className="filter-tag">
                        <span>{brandLabel}</span>
                        <IcClose onClick={removeBrand} role="presentation" />
                    </div>
                ) : null }

                {stockLabel ? (
                    <div className="filter-tag">
                        <span>{stockLabel ? t(IN_STOCK) : t(NO_STOCK) }</span>
                        <IcClose onClick={removeStock} role="presentation" />
                    </div>
                ) : null }
            </div>
        </>
    );
};

SectionHeaderCatalog.defaultProps = {
    stockLabel: undefined,
};
