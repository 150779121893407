import { String } from 'app/shared/domain/value-objects/String';
import I18n from '../../../../i18n';
import { MANUFACTURED_LABEL, MANUFACTURING_LABEL, RESERVED_LABEL } from '../../../../i18n/translations/TR';

export enum OrderLineStatusEnum {
    C = 'C',
    F = 'F',
    P = 'P',
}

export class OrderLineStatus extends String {

    public translate = (): string => {
        switch (this.value) {
            case OrderLineStatusEnum.C:
                return I18n.t(RESERVED_LABEL);
            case OrderLineStatusEnum.F:
                return I18n.t(MANUFACTURING_LABEL);
            case OrderLineStatusEnum.P:
            default:
                return I18n.t(MANUFACTURED_LABEL);
        }

    };

}
