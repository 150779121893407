import { useTranslation } from 'react-i18next';
import { WEIGHT_UNIT, POUND_WEIGHT } from 'i18n/translations/TR';
import { useOrigin } from './hooks/useOrigin';

export const getWeight = (): string => {

    const { t } = useTranslation();
    const origin = useOrigin();

    let weightString: string = '';

    if (origin === 'EUROPE') {
        weightString = t(WEIGHT_UNIT);
    } else if (origin === 'USA') {
        weightString = t(POUND_WEIGHT);
    }
    return weightString;

};
