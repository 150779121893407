import React from 'react';
import { LoadingOrderListTableHead } from './head/loading-order-list-table-head';
import { LoadingOrderListTableBody } from './body/loading-order-list-table-body';
import LoadingOrder from '../../../../domain/loading-order';
import { LoadingOrderId } from '../../../../domain/value-objects/loading-order-id';
import './loading-order-list-table.scss';

interface OrderListTableProps {
    loadingOrders: LoadingOrder[]
    downloadLoadingOrderPDF: (loadingOrderId: LoadingOrderId) => void
    isDownloadingPDF: boolean
}

export const LoadingOrderListTable = (props: OrderListTableProps): React.ReactElement => {
    return (
        <table
            className="
            table
            loading-orders-list
            table-responsive
            table-striped
            table-hover
            table-borderless
            "
        >
            <LoadingOrderListTableHead />
            <LoadingOrderListTableBody
                loadingOrders={props.loadingOrders}
                downloadLoadingOrderPDF={props.downloadLoadingOrderPDF}
                isDownloadingPDF={props.isDownloadingPDF}
            />
        </table>
    );
};
