import { OrderFilter } from './OrderFilter';

export class OrderFilterCollection {

    private _filters: { [key: string]: OrderFilter } = {};

    public get filters(): { [key: string]: OrderFilter; } {
        return this._filters;
    }
    public set filters(value: { [key: string]: OrderFilter }) {
        this._filters = value;
    }

    constructor(defaultFilters: OrderFilter[]) {
        defaultFilters.forEach((filter: OrderFilter) => {
            this.addFilter(filter);
        });
    }

    public getValue(key: string): OrderFilter {
        return this._filters[key];
    }

    public addFilter(filter: OrderFilter): void {
        this._filters[filter.name] = filter;
    }

    public removeFilter(key: string): void {
        delete this._filters[key];
    }

    public toArray(): OrderFilter[] {
        return Object.values(this._filters);
    }

}
