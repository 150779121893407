import { OrderFilterName } from '../../../../application/hooks/useOrderFilters';
import {
    OrderFilterRequest,
    OrderFilterRequestItem,
    OrderFilterRequestName,
    StatusOrderFilterName,
} from '../requests/OrderFilterRequest';
import { OrderFilterCollection } from '../../../../model/OrderFilterCollection';
import { OrderFilter } from '../../../../model/OrderFilter';
import { OrderPhase } from '../../../../model/OrderPhase';

export class OrderFiltersMapper {

    private static filterNameToDTO(orderPhase: OrderPhase): StatusOrderFilterName {
        switch (orderPhase) {
            case OrderPhase.PREPARING:
                return StatusOrderFilterName.PREPARING;
            case OrderPhase.ORDERED:
                return StatusOrderFilterName.ORDERED;
            case OrderPhase.CARRIED:
                return StatusOrderFilterName.CARRIED;
            case OrderPhase.INVOICED:
                return StatusOrderFilterName.INVOICED;
            case OrderPhase.PREPARED:
                return StatusOrderFilterName.PREPARED;
            case OrderPhase.OPEN:
                return StatusOrderFilterName.OPEN;
            case OrderPhase.RELEASED:
            default:
                return StatusOrderFilterName.RELEASED;
        }
    }

    private static fromApplication(filter: OrderFilter): OrderFilterRequestItem {
        switch (filter.name) {
            case OrderFilterName.FINAL_DATE:
                return {
                    name: OrderFilterRequestName.FINAL_DATE,
                    value: filter.value as string,
                };
            case OrderFilterName.PHASE:
                return {
                    name: OrderFilterRequestName.PHASE,
                    value: this.filterNameToDTO(filter.value as OrderPhase),
                };
            case OrderFilterName.PO:
                return {
                    name: OrderFilterRequestName.PO,
                    value: filter.value as string,
                };
            case OrderFilterName.START_DATE:
            default:
                return {
                    name: OrderFilterRequestName.START_DATE,
                    value: filter.value as string,
                };
        }
    }

    static toWs(filters: OrderFilterCollection): OrderFilterRequest {
        const filtersDTO: OrderFilterRequest = {
            request: [],
        };

        filters.toArray().forEach((item) => {
            filtersDTO.request.push(this.fromApplication(item));
        });

        return filtersDTO;
    }

}
