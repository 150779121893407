import React from 'react';
import { AccountView } from './view/account-view';
import { useAccount } from '../../../../shared/hooks/use-account';

export const AccountContainer = ():React.ReactElement => {
    const UseAccount = useAccount();

    return (
        <AccountView
            getErpUserIsLoading={UseAccount.getErpUserIsLoading}
            erpUserContacts={UseAccount.erpUserContacts}
            getErpUserContactsIsLoading={UseAccount.getErpUserContactsIsLoading}
            showDeleteContactModal={UseAccount.showDeleteContactModal}
            handleHideDeleteContactModal={UseAccount.handleHideDeleteContactModal}
            handleShowDeleteContactModal={UseAccount.handleShowDeleteContactModal}
            deleteErpUserContact={UseAccount.deleteErpUserContact}
            deleteErpUserContactIsLoading={UseAccount.deleteErpUserContactIsLoading}
            mutationAddErpUserContact={UseAccount.mutationAddErpUserContact}
            getShippingAddressesIsLoading={UseAccount.getShippingAddressesIsLoading}
            shippingAddresses={UseAccount.shippingAddresses}
            erpUser={UseAccount.erpUser}
            mutationChangePassword={UseAccount.mutationChangePassword}
            userCredentialId={UseAccount.userCredentialId}
            countries={UseAccount.countries}
            shippingAddressDefault={UseAccount.defaultShippingAddress}
            getDefaultShippingAddressIsLoading={UseAccount.getDefaultShippingAddressIsLoading}
            getDefaultShippingAddress={UseAccount.getDefaultShippingAddress}
            getShippingAddresses={UseAccount.getShippingAddresses}
        />
    );
};
