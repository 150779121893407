import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { URL } from 'app/shared/domain/value-objects/URL';
import { OrderFileResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/OrderFileResponse';
import { OrderFilesArrayResponse } from '../../../../../shared/infrastructure/tencer-api/dto/responses/OrderFilesArrayResponse';
import { FileName } from '../../../../model/value-objects/FileName';
import { FileId } from '../../../../model/value-objects/FileId';
import { File } from '../../../../model/File';

export class OrderFileMapper {

    static fromOrderFileResponse(response: OrderFileResponse): File {
        return new File(
            new URL(response.url),
            new FileId(response.id),
            new OrderId(response.order_id),
            new FileName(response.filename),

        );
    }

    static fromOrderFilesResponse(response: OrderFilesArrayResponse): File[] {
        return response.map((file: OrderFileResponse) => OrderFileMapper.fromOrderFileResponse(file));
    }

}
