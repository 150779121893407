import React from 'react';
import { useIsMounted } from 'app/shared/hooks/useIsMounted';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiCollection } from './ApiCollection';

interface UseFeaturedCollectionListList {
    data: ApiCollection[];
    error: boolean;
    loading: boolean;
}

export function useFeaturedCollectionList(): UseFeaturedCollectionListList {
    const [data, setData] = React.useState<ApiCollection[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const isMounted = useIsMounted();

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance().getCollections()
            .then((collections) => {
                if (!isMounted) {
                    return;
                }
                setData(collections);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
    };
}
