import { TencerApiClient } from 'app/shared/infrastructure/tencer-api/TencerApiClient';
import { ProductId } from 'app/products/domain/value-object/ProductId';
import { AnalyticsRepositoryInterface } from '../../../domain/analytics-repository-interface';
import { EAnalyticsEvent } from '../../../domain/analytics';
import { PostAnalyticsEventMapper } from '../data-mapper/post-analytics-event.mapper';

export class AnalyticsRepository implements AnalyticsRepositoryInterface {

    private readonly client: TencerApiClient;

    constructor(client: TencerApiClient) {
        this.client = client;
    }

    async logOutEvent(
        date: Date,
        event: EAnalyticsEvent.LOGOUT,
    ): Promise<void> {
        return this.client.postAnalyticsEvent(
            PostAnalyticsEventMapper.fromDomain(
                date,
                event,
            ),
        );
    }

    async productDetailEvent(
        date: Date,
        event: EAnalyticsEvent.PRODUCT_DETAIL,
        productId: ProductId,
    ): Promise<void> {
        return this.client.postAnalyticsEvent(
            PostAnalyticsEventMapper.fromDomain(
                date,
                event,
                productId,
            ),
        );
    }

}
