import React from 'react';
import { useTranslation } from 'react-i18next';
import { UseCartSummaryResponse } from '../../../hooks/use-cart-summary';
import { ORDER } from '../../../../../../i18n/translations/TR';
import { ListItemCart } from '../../../../../cart/components/ListItemCart';

interface ICartSummaryListProps {
    UseCartSummary: UseCartSummaryResponse
}
export const CartSummaryList = (
    { UseCartSummary }: ICartSummaryListProps,
): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <>
            <h5 className="card-wow-title">{t(ORDER)}</h5>
            <ul className="section-list">
                {UseCartSummary.cart!.items.map((item) => (
                    <ListItemCart
                        key={item.id.value}
                        item={item}
                        cartSummary
                        onChange={UseCartSummary.cartSummary.refreshCart}
                        onRemove={UseCartSummary.cartSummary.refreshCart}
                    />
                ))}
            </ul>
        </>
    );
};
