import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { SHIPPING_ADDRESS_LABEL_DESCRIPTION } from '../../../../../../i18n/translations/TR';

interface ShippingAddressDescriptionFieldProps {
    descriptionRegister: UseFormRegisterReturn
    errorField: FieldError | undefined

}

export const ShippingAddressDescriptionField = (
    {
        descriptionRegister,
        errorField,
    }: ShippingAddressDescriptionFieldProps,
): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="form-field description-field">
            <label htmlFor="description" className="form-label is-required">
                {t(SHIPPING_ADDRESS_LABEL_DESCRIPTION)}
            </label>
            <input
                type="text"
                id="description"
                className="form-control"
                {...descriptionRegister}
            />
            {errorField ? (
                <div className="error-input-form">{errorField.message}</div>
            ) : null}
        </div>
    );
};
