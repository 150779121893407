/* eslint-disable react/require-default-props */
import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { getCurrency } from './getCurrency';
import { useOrigin } from './hooks/useOrigin';

interface Props {
    value: number | string,
    className?: string,
}

export const Currency: React.FC<Props> = ({ value, className }) => {
    const { i18n } = useTranslation();
    const origin = useOrigin();
    const originUsa = 'USA';
    const originEurope = 'EUROPE';
    return (
        <NumberFormat
            value={value}
            displayType="text"
            prefix={origin === originUsa ? `${getCurrency()}` : ''}
            suffix={origin === originEurope ? ` ${getCurrency()}` : ''}
            className={className!}
            thousandSeparator={i18n.language === 'es' ? '.' : ','}
            decimalSeparator={i18n.language === 'es' ? ',' : '.'}
            decimalScale={2}
            fixedDecimalScale
        />
    );
};
