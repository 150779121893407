import { LoadingOrderId } from './value-objects/loading-order-id';
import { ShippingAddress } from '../../orders/model/ShippingAddress';
import OrderToLoad from './order-to-load';
import { LoadingOrderStatus } from './value-objects/loading-order-status';
import { LoadingOrderTransportType } from './value-objects/loading-order-transport_type';

export default class LoadingOrder {

    private readonly _id: LoadingOrderId;
    public get id(): LoadingOrderId {
        return this._id;
    }

    private readonly _shippingAddress: ShippingAddress | null;
    public get shippingAddress(): ShippingAddress | null {
        return this._shippingAddress;
    }

    private readonly _transportType: LoadingOrderTransportType;
    public get transportType(): LoadingOrderTransportType {
        return this._transportType;
    }

    private readonly _loadingOrderDate: Date;
    public get loadingOrderDate(): Date {
        return this._loadingOrderDate;
    }

    private readonly _packages: number;
    public get packages(): number {
        return this._packages;
    }

    private readonly _totalWeight: number;
    public get totalWeight(): number {
        return this._totalWeight;
    }

    private readonly _ordersToLoad: OrderToLoad[];
    public get ordersToLoad(): OrderToLoad[] {
        return this._ordersToLoad;
    }

    private readonly _status: LoadingOrderStatus;
    public get status(): LoadingOrderStatus {
        return this._status;
    }

    constructor(
        id: LoadingOrderId,
        shippingAddress: ShippingAddress | null,
        transportType: LoadingOrderTransportType,
        loadingOrderDate: Date,
        packages: number,
        weight: number,
        ordersToLoad: OrderToLoad[],
        status: LoadingOrderStatus,
    ) {
        this._id = id;
        this._shippingAddress = shippingAddress;
        this._transportType = transportType;
        this._loadingOrderDate = loadingOrderDate;
        this._packages = packages;
        this._totalWeight = weight;
        this._ordersToLoad = ordersToLoad;
        this._status = status;
    }

}
