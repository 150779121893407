import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    ACCEPT,
    RESET_PASSWORD_SUCCESSFUL_INFO,
    RESET_PASSWORD_SUCCESSFUL_TITLE,
} from '../../../../../../../../../i18n/translations/TR';

interface SuccessfulResetPasswordModalProps {
    showSuccessfulResetPasswordModal: boolean
    closeSuccessfulResetPasswordModal: () => void
}

export const SuccessfulResetPasswordModal = ({
    showSuccessfulResetPasswordModal,
    closeSuccessfulResetPasswordModal,
}: SuccessfulResetPasswordModalProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            show={showSuccessfulResetPasswordModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{t(RESET_PASSWORD_SUCCESSFUL_TITLE)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{t(RESET_PASSWORD_SUCCESSFUL_INFO)}</div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    form="add-form"
                    className="btn btn-primary"
                    onClick={closeSuccessfulResetPasswordModal}
                >
                    {t(ACCEPT)}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
