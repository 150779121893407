import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseMutationResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { PatchErpUserPasswordDTO } from '../../../../../../infrastructure/api/dto/request/patch-erp-user-password.DTO';
import {
    ERROR_CODE_10202,
    ERROR_CODE_10203,
    ERROR_CODE_10204,
    NEW_PASSWORD,
    PASSWORDS_REPEAT,
    PASSWORD_CURRENT,
    PASSWORD_RESET_TITLE,
    OBLIGATORY_FIELD_ERROR,
    CHANGE_PASSWORD,
    SET_PASSWORD_HELP,
} from '../../../../../../../../i18n/translations/TR';
import { useErrorTranslation } from '../../../../../../../shared/hooks/useErrorTranslation';
import { SuccessfulResetPasswordModal } from './modal/successful-reset-password-modal';

interface ResetPasswordRegisters {
    actualPassword:string
    newPassword1:string
    newPassword2:string
    validation:string
}
enum ResetPasswordRegisterName {
    actualPassword = 'actualPassword',
    newPassword1 = 'newPassword1',
    newPassword2 = 'newPassword2',
    validation = 'validation',
}

interface AccountSettingsResetPasswordFormProps{
    mutationChangePassword: UseMutationResult<string, unknown, PatchErpUserPasswordDTO, unknown>
}
export const AccountSettingsResetPasswordForm = (
    {
        mutationChangePassword,
    }:AccountSettingsResetPasswordFormProps,
):React.ReactElement => {
    const [showSuccessfulResetPassword, setShowSuccessfulResetPassword] = useState<boolean>(false);
    const UseFormResetPassword = useForm<ResetPasswordRegisters>();
    const { t } = useTranslation();
    const UseErrorTranslation = useErrorTranslation();
    const onSubmitResetPassword =
        UseFormResetPassword.handleSubmit((resetPasswordRegisters: ResetPasswordRegisters) => {
            UseFormResetPassword.clearErrors(ResetPasswordRegisterName.validation);
            if (resetPasswordRegisters.newPassword1 !== resetPasswordRegisters.newPassword2) {
                UseFormResetPassword.setError(
                    ResetPasswordRegisterName.validation,
                    { type: 'differentPasswords', message: t(ERROR_CODE_10204) },
                );
            } else if (resetPasswordRegisters.newPassword1 === resetPasswordRegisters.actualPassword) {
                UseFormResetPassword.setError(
                    ResetPasswordRegisterName.validation,
                    {
                        type: 'newPasswordMustBeDifferentToCurrent',
                        message: t(ERROR_CODE_10203),
                    },
                );
            } else {
                mutationChangePassword.mutate({
                    old_password: resetPasswordRegisters.actualPassword,
                    new_password: resetPasswordRegisters.newPassword1,
                    repeated_password: resetPasswordRegisters.newPassword2,
                }, {
                    onSuccess: ():void => {
                        UseFormResetPassword.reset();
                        setShowSuccessfulResetPassword(true);
                    },
                    onError: (error):void => {
                        UseFormResetPassword.setError(
                            ResetPasswordRegisterName.validation,
                            {
                                type: 'apiError',
                                // @ts-ignore
                                message: UseErrorTranslation.translate(error.response.data.code),
                            },
                        );
                    },
                });
            }
        });

    const actualPasswordRegister = UseFormResetPassword.register(ResetPasswordRegisterName.actualPassword, {
        required: t(OBLIGATORY_FIELD_ERROR) as string,
        pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,]).{8,}$/,
            message: t(ERROR_CODE_10202),
        },
        onChange: ():void => {
            UseFormResetPassword.clearErrors(ResetPasswordRegisterName.validation);
        },
    });

    const newPassword1Register = UseFormResetPassword.register(ResetPasswordRegisterName.newPassword1, {
        required: t(OBLIGATORY_FIELD_ERROR) as string,
        pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,]).{8,}$/,
            message: t(ERROR_CODE_10202),
        },
        onChange: ():void => {
            UseFormResetPassword.clearErrors(ResetPasswordRegisterName.validation);
        },
    });

    const newPassword2Register = UseFormResetPassword.register(ResetPasswordRegisterName.newPassword2, {
        required: t(OBLIGATORY_FIELD_ERROR) as string,
        pattern: {
            value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,]).{8,}$/,
            message: t(ERROR_CODE_10202),
        },
        onChange: ():void => {
            UseFormResetPassword.clearErrors(ResetPasswordRegisterName.validation);
        },
    });

    return (
        <>
            <div
                className="row account-card-reset-password-form"
            >
                <h6>
                    {t(PASSWORD_RESET_TITLE)}
                </h6>
                <div className="account-card-item">
                    <label htmlFor="newPassword" className="form-label">{t(NEW_PASSWORD)}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        {...newPassword1Register}
                    />
                    {UseFormResetPassword.formState.errors.newPassword1 ? (
                        <div className="error-input-form">
                            {UseFormResetPassword.formState.errors.newPassword1.message}
                        </div>
                    ) : null }
                    <small id="passwordHelp" className="form-text text-muted">{t(SET_PASSWORD_HELP)}</small>

                </div>
                <div className="account-card-item">
                    <label htmlFor="newPasswordRepeat" className="form-label">{t(PASSWORDS_REPEAT)}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="newPasswordRepeat"
                        {...newPassword2Register}
                    />
                    {UseFormResetPassword.formState.errors.newPassword2 ? (
                        <div className="error-input-form">
                            {UseFormResetPassword.formState.errors.newPassword2.message}
                        </div>
                    ) : null }
                </div>
                <div className="account-card-item">
                    <label htmlFor="actualPassword" className="form-label">{t(PASSWORD_CURRENT)}</label>
                    <input
                        type="password"
                        className="form-control"
                        id="actualPassword"
                        {...actualPasswordRegister}
                    />
                    {UseFormResetPassword.formState.errors.actualPassword ? (
                        <div className="error-input-form">
                            {UseFormResetPassword.formState.errors.actualPassword.message}
                        </div>
                    ) : null }
                </div>

                <div className="reset-password-button-container">
                    {UseFormResetPassword.formState.errors.validation ? (
                        <div className="error-input-form validation-error">
                            {UseFormResetPassword.formState.errors.validation.message}
                        </div>
                    ) : null }
                    <button
                        type="button"
                        className="btn"
                        disabled={mutationChangePassword.isLoading}
                        onClick={(): void => {
                            UseFormResetPassword.clearErrors();
                            onSubmitResetPassword();
                        }}
                    >
                        {
                            mutationChangePassword.isLoading ?
                                (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                                : null
                        }
                        {t(CHANGE_PASSWORD)}
                    </button>
                </div>
            </div>
            <SuccessfulResetPasswordModal
                showSuccessfulResetPasswordModal={showSuccessfulResetPassword}
                closeSuccessfulResetPasswordModal={(): void => setShowSuccessfulResetPassword(false)}
            />
        </>
    );
};
