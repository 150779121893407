import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ADD, ADD_TO_CART, ADDING, CANCEL, EMPTY_CART_MESSAGE, YOUR_CART } from 'i18n/translations/TR';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { CurrentUserContext } from 'app/shared/context/CurrentUserContext';
import { Modal } from 'react-bootstrap';
import { PageBanner } from '../page-banner/component/PageBanner';
import { CartFooter } from './components/CartFooter';
import { useCartItemsList } from './shared/useCartItemList';
import { CartItemsList } from './components/CartItemsList/CartItemsList';
import { TotalPrice } from './components/total-price/total-price';
import { usePermission } from '../shared/hooks/use-Permission';

export const CartScreen: React.FC = () => {
    const { t } = useTranslation();
    const state = React.useContext(CurrentUserContext);
    const cartItemsList = useCartItemsList();
    const permission = usePermission();
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('error');
    const handleClose = (): void => {
        setShowModal(false);
    };
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isLoading = cartItemsList.isLoading;
    const pageName = 'cart';

    const closeModal = (): void => {
        handleClose();
    };

    React.useEffect(() => {
        if (state?.force.length && state?.errorMessageModal) {
            setShowModal(true);
            setErrorMessage(state.errorMessageModal);
        }
    }, [state?.errorMessageModal]);

    const onChange = ():void => {
        setIsSubmitting(false);
        state?.setCartModified(new Date());
        cartItemsList.refreshCart();
        handleClose();
    };

    const submitForce = async (): Promise<void> => {
        setIsSubmitting(true);
        state?.modifyCart(
            state.amountProductsRequested,
            state.itemIdRemove,
            state?.measureType,
            state.force,
            onChange,
        ).catch(() => {
            setShowModal(true);
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    if (!permission.canDoOrders()) {
        return null;
    }

    React.useEffect(() => {
        cartItemsList.refreshCart();
    }, []);

    return (

        <div>
            {!isLoading ? (
                <div className="screen">
                    {cartItemsList.error ? (
                        <ServerErrorMessage />
                    ) : (
                        <>
                            <PageBanner pageId={pageName} />
                            <div className="container cart-header">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <h4 className="section-title">{t(YOUR_CART)}</h4>
                                    </div>
                                    <div className="col-sm-4">
                                        { cartItemsList.cart ? (
                                            <TotalPrice
                                                price={cartItemsList.cart?.price}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            {!cartItemsList.cart || cartItemsList.cart.items.length === 0 ? (
                                <div className="container d-flex">{t(EMPTY_CART_MESSAGE)}</div>
                            ) : (
                                <>
                                    <div className="container cart-content">
                                        <div className="row">
                                            <CartItemsList
                                                cart={cartItemsList.cart}
                                                onChange={cartItemsList.refreshCart}
                                                onRemove={cartItemsList.refreshCart}
                                                isSubmitting={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                    <CartFooter price={cartItemsList.cart?.price ?? 0.0} />
                                </>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <Spinner classCss="header-section-spinner" />
            )}

            {showModal ? (
                <Modal
                    onHide={handleClose}
                    show={showModal}
                    className="modal fade"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <h5 className="modal-title">{t(ADD_TO_CART)}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>{t(errorMessage)}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn-link bg-transparent text-decoration-none"
                            onClick={closeModal}
                        >
                            {t(CANCEL)}
                        </button>
                        <button type="submit" form="add-form" className="btn btn-primary" onClick={submitForce}>
                            {isSubmitting ? (
                                <span
                                    className="spinner-border spinner-border-sm button-loading"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : null}
                            {isSubmitting ? t(ADDING) : t(ADD)}
                        </button>
                    </Modal.Footer>
                </Modal>
            ) : null}
        </div>
    );
};
