import { Phone } from './value-objects/phone';
import { Email } from './value-objects/email';
import { Language } from './value-objects/language';

export class Contact {

    private _mail: Email | null;
    private _phoneNumber: Phone | null;
    private _defaultLanguage: Language | null;

    public constructor() {
        this._mail = null;
        this._phoneNumber = null;
        this._defaultLanguage = null;
    }

    public get mail(): Email | null {
        return this._mail;
    }
    public set mail(value: Email | null) {
        this._mail = value;
    }

    public get phoneNumber():Phone | null {
        return this._phoneNumber;
    }

    public set phoneNumber(value: Phone | null) {
        this._phoneNumber = value;
    }

    public get defaultLanguage(): Language | null {
        return this._defaultLanguage;
    }

    public set defaultLanguage(value: Language | null) {
        this._defaultLanguage = value;
    }

}
