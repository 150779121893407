import React from 'react';
import { useTranslation } from 'react-i18next';
import { WELCOME, DESCRIPTION_LOGIN, DESIGN_BANNER, WOW } from 'i18n/translations/TR';
import { SwiperBackground } from 'app/shared/SwiperBackground';
import { PasswordResetForm } from './shared/PasswordResetForm';
import { BG_01, BG_02, BG_03, BG_04, BG_05, BG_06 } from '../../assets/img';

export const PasswordRecoveryScreen: React.FC = () => {
    const { t } = useTranslation();
    const classNameProp = 'swiper-container-hero swiper-login';

    const images : string[] = [BG_01, BG_02, BG_03, BG_04, BG_05, BG_06];

    return (

        <div className="login-page">
            <div className="login-container">
                <div className="login-page-text">
                    <p className="login-welcome">{t(WELCOME)}</p>
                    <h1 className="login-title">
                        {t(WOW)}
                        <span className="f-serif">
                            {t(DESIGN_BANNER)}
                        </span>
                    </h1>
                    <h3 className="login-description">
                        {t(DESCRIPTION_LOGIN)}
                    </h3>
                </div>
                <PasswordResetForm />
                <SwiperBackground classNameProp={classNameProp} images={images} />
            </div>
        </div>

    );
};
