import React from 'react';
import { useTranslation } from 'react-i18next';
import OrderToLoad from '../../../../../../domain/order-to-load';
import OrderLineToLoad from '../../../../../../domain/order-line-to-load';
import {
    BOX_UNIT,
    BOXES,
    LOT,
    PALLETS,
    PALLETS_SINGLE,
    PIECE_UNIT,
    PIECE_UNIT_SINGLE,
} from '../../../../../../../../i18n/translations/TR';
import { Quantity } from '../../../../../../../shared/components/Quantity';
import { COWeight } from '../../../../../../../shared/components/CO-weight';

interface LoadingOrderDetailTableBodyItemProps {
    order: OrderToLoad
}

export const LoadingOrderDetailTableBodyItem = (props: LoadingOrderDetailTableBodyItemProps): React.ReactElement => {
    const { t } = useTranslation();
    const renderOrderInfoRow = (): React.ReactElement => {
        return (
            <tr className="order-to-load-info-row">
                <td>{props.order.id.value}</td>
                <td>{props.order.po}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
                <td>{}</td>
            </tr>
        );
    };

    const renderOrderLineInfoRow = (line: OrderLineToLoad): React.ReactElement => {
        return (
            <tr>
                <td>{line.description}</td>
                <td>{}</td>
                <td>{line.format}</td>
                <td>
                    <div className="product-tag">
                        <span className="product-tag-label">
                            {t(LOT).toUpperCase()}
                        </span>
                        <span className="product-tag-value">
                            {line.reference}
                        </span>
                    </div>
                </td>
                <td>
                    <div className="product-tag">
                        <Quantity
                            value={line.quantityInPallets}
                            label={
                                line.quantityInPallets > 1 ?
                                    `${t(PALLETS).toLowerCase()}`
                                    : `${t(PALLETS_SINGLE).toLowerCase()}`
                            }
                        />
                    </div>
                </td>
                <td>
                    <div className="product-tag">
                        <Quantity
                            value={line.quantityInBoxes}
                            label={
                                line.quantityInBoxes > 1 ?
                                    `${t(BOXES).toLowerCase()}`
                                    : `${t(BOX_UNIT).toLowerCase()}`
                            }
                        />
                    </div>
                </td>
                <td>
                    <div className="product-tag">
                        <Quantity
                            value={line.quantityInPieces}
                            label={line.quantityInPieces > 1 ? `${t(PIECE_UNIT)}` : `${t(PIECE_UNIT_SINGLE)}`}
                        />
                    </div>
                </td>
                <td>
                    <div className="product-tag">
                        <COWeight value={line.weightGross} />
                    </div>
                </td>
            </tr>
        );
    };

    return (
        <>
            {renderOrderInfoRow()}
            {
                props.order.linesToLoad.map((line) => {
                    return renderOrderLineInfoRow(line);
                })
            }
            <tr className="empty-row">
                <td>
                    {}
                </td>
            </tr>
        </>
    );
};
