import React, { useEffect } from 'react';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    ACCOUNT,
    DOWNLOADS, LOADING_ORDERS,
    LOG_OUT_NAVBAR,
    MENU_OPTION_MARKETING,
    ORDERS,
    OUTLET,
    PRODUCTS,
    SEARCH,
} from 'i18n/translations/TR';
import { WOW_DOWNLOADS_HREF } from 'app/constants/hrefConstants';
import { TencerRoutes } from './tencer-routing/TencerRoutes';
import { CurrentUserContext } from './context/CurrentUserContext';
import { ReactComponent as LogoWowBlack } from '../../assets/img/logo-wow-black.svg';
import { ReactComponent as IcMenu } from '../../assets/img/icons/ic-menu.svg';
import { ReactComponent as IcShopBag } from '../../assets/img/icons/ic-shop-bag.svg';
import { ReactComponent as Account } from '../../assets/img/icons/account.svg';
import { ReactComponent as Logout } from '../../assets/img/icons/ic-logout.svg';
import { useToken } from './hooks/use-token';
import { useOrigin } from './hooks/useOrigin';
import { Origin } from './domain/value-objects/Origin';
import { TencerAuthServiceInstance } from './tencer-routing/tencer-auth-route/TencerAuthService';
import { LanguageSelector } from './components/LanguageSelector/LanguageSelector';
import CartMapper from '../cart/infraestructure/repository/api/data-mapper/CartMapper';
import { TencerApiClientSingleton } from './hooks/TencerApiClientSingleton';
import { useCartSummaryList } from '../cart-summary/application/hooks/use-cart-summary-list';
import { TencerLanguage } from '../../i18n';
import { usePermission } from './hooks/use-Permission';

interface FormInputs {
    search: string,
}

export const Header: React.FC = () => {
    const { t, i18n } = useTranslation();
    const history = useNavigate();
    const state = React.useContext(CurrentUserContext);
    const cartSummary = useCartSummaryList();
    const location = useLocation();
    const origin = useOrigin();
    const [content, setContent] = React.useState('');
    const { register, handleSubmit, setValue } = useForm<FormInputs>();
    const button = document.getElementById('toggleButton');
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const permission = usePermission();

    const closeMobileMenu = ():void => {
        if (window.location.pathname === TencerRoutes.CATALOG ||
            window.location.pathname === TencerRoutes.COLLECTIONS) {
            if (button != null && isMobile) {
                button.click();
            }
        }
    };

    const UseToken = useToken();

    const submitHandler: SubmitHandler<FormInputs> = (data: FormInputs) => {
        closeMobileMenu();
        history(TencerRoutes.CATALOG, { state: { search: data.search } });
        setValue('search', '');
    };

    const logout = (): void => {
        state?.handleLogout();
    };

    const goToCart = ():void => {
        history(TencerRoutes.CART);
    };

    useEffect(() => {
        if (location.state) {
            const state2 = location.state;
            if (state2.search) {
                setContent(state2.search);
            }
        } else {
            setContent('');
        }

    }, [location, setContent, content]);

    useEffect(() => {
        cartSummary.refreshCart();
    }, [state?.cartModified]);

    const changeLanguage = (lng: TencerLanguage): void => {
        i18n.changeLanguage(lng);
        localStorage.setItem('lang', lng);
    };

    const APIClient = TencerApiClientSingleton.getInstance();
    useEffect(() => {
        APIClient.getMyCart()
            .then((response) => {
                const fetchedCart = CartMapper.fromResponse(response);
                state?.setExpirationDate(fetchedCart?.freeStockAt?.toISOString() ?? '');
            });
    }, []);

    return (
        <Navbar expand="lg" className="fixed-top">
            <Container fluid>
                <Navbar.Brand>
                    <Link to="/" className="navbar-brand">
                        <LogoWowBlack style={{ height: '2.5rem' }} />
                    </Link>

                    <ul className="icons-nav">
                        <Dropdown className="dropstart">
                            <Dropdown.Toggle
                                className="dropdown-toggle bg-transparent btn-link"
                                variant="success"
                                id="dropdown-basic"
                            >
                                <Account />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdown-menu user-container">
                                <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(): void => history(TencerRoutes.ACCOUNT)}
                                >
                                    <div className="user-container-item">
                                        <Account />
                                        <span>{t(ACCOUNT)}</span>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="dropdown-item" onClick={logout}>
                                    {TencerAuthServiceInstance.getToken() !== '' ? (
                                        <div className="user-container-item">
                                            <Logout />
                                            <span>{t(LOG_OUT_NAVBAR)}</span>
                                        </div>
                                    ) : null}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {!permission.canDoOrders() ? null : (
                            <li className="nav-item" onClick={goToCart} role="presentation">
                                <span className="position-relative">
                                    <IcShopBag />
                                    <span
                                        className="position-absolute
                                            translate-middle badge badge-timer"
                                    >
                                        {state?.timer ? state.timer : ('')}
                                    </span>
                                    {state?.cartItems && state.cartItems > 0 ? (
                                        <span className="cart-counter">{state.cartItems}</span>
                                    ) : null}
                                </span>
                            </li>
                        )}
                    </ul>

                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" id="toggleButton">
                    <IcMenu />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar-nav mr-auto navbar-options-container">
                        {
                            UseToken.permissions.permissionsName.includes('LIST') ?
                                (
                                    <>
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive && location.state?.productsType === t(PRODUCTS)) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.CATALOG}
                                            state={{ productsType: t(PRODUCTS) }}
                                            children={<span>{t(PRODUCTS)}</span>}
                                        />
                                        <NavLink
                                            className={(isActive): string => {
                                                if (isActive.isActive && location.state?.productsType === t(OUTLET)) {
                                                    return 'link-active';
                                                }
                                                return 'link-inactive';
                                            }}
                                            to={TencerRoutes.CATALOG}
                                            state={{ productsType: t(OUTLET) }}
                                            children={<span>{t(OUTLET)}</span>}
                                        />
                                    </>
                                )
                                : null
                        }

                        {
                            origin && origin.match(Origin.USA) ?
                                (
                                    <NavLink
                                        className={(isActive): string => {
                                            if (isActive.isActive) {
                                                return 'link-active';
                                            }
                                            return 'link-inactive';
                                        }}
                                        to={TencerRoutes.MARKETING}
                                        children={<span>{t(MENU_OPTION_MARKETING)}</span>}
                                    />
                                )
                                : null
                        }

                        {
                            permission.canDoOrders() ?
                                (
                                    <NavLink
                                        className={(isActive): string => {
                                            if (isActive.isActive) {
                                                return 'link-active';
                                            }
                                            return 'link-inactive';
                                        }}
                                        to={TencerRoutes.ORDERS}
                                        children={<span>{t(ORDERS)}</span>}
                                    />
                                )
                                : null
                        }

                        {
                            permission.canManageLoadingOrders() ?
                                (
                                    <NavLink
                                        className={(isActive): string => {
                                            if (isActive.isActive) {
                                                return 'link-active';
                                            }
                                            return 'link-inactive';
                                        }}
                                        to={TencerRoutes.LOADING_ORDERS}
                                        children={<span>{t(LOADING_ORDERS)}</span>}
                                    />
                                )
                                : null
                        }

                        <NavLink
                            className={(isActive): string => {
                                if (isActive.isActive) {
                                    return 'link-active';
                                }
                                return 'link-inactive';
                            }}
                            to={WOW_DOWNLOADS_HREF}
                            children={<span>{t(DOWNLOADS)}</span>}
                            target="_blank"
                        />
                        {
                            UseToken.permissions.permissionsName.includes('LIST') ?
                                (

                                    <Nav.Item className="w-100">
                                        <form onSubmit={handleSubmit(submitHandler)} className="d-flex">
                                            <input
                                                className="form-control mx-2"
                                                type="search"
                                                placeholder={t(SEARCH)}
                                                defaultValue={content}
                                                {...register(
                                                    'search',
                                                    { required: true },
                                                )}
                                            />
                                            <button
                                                className="btn btn-primary-outline btn-mini"
                                                data-toggle="collapse"
                                                type="submit"
                                            >
                                                {t(SEARCH)}
                                            </button>
                                        </form>
                                    </Nav.Item>
                                )
                                : null
                        }

                        <div className="col-sm-1 col-md-1">
                            <LanguageSelector changeLanguage={changeLanguage} />
                        </div>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
};
