import React from 'react';
import { ApiCollection } from 'app/home/featured-collections/shared/domain/ApiCollection';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ProductSort } from '../../../../products/domain/model/ProductSort';

interface UseCollectionListList {
    data: ApiCollection[];
    error: boolean;
    loading: boolean;
    setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
    hasMore: boolean;
    pageResultsDefault: number;
}

export function useCollectionList(): UseCollectionListList {
    const [data, setData] = React.useState<ApiCollection[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [hasMore, setHasMore] = React.useState(true);

    const pageResultsDefault = 30;

    React.useEffect(() => {
        setLoading(true);
        TencerApiClientSingleton.getInstance()
            .getCollections(undefined, ProductSort.Alphabetical, pageResultsDefault)
            .then((collections) => {
                if (collections.length === 0 || collections.length < pageResultsDefault) {
                    setHasMore(false);
                }

                setData(collections);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
        hasMore,
        setHasMore,
        pageResultsDefault,
    };
}
