import { ErpContactDTO } from '../dto/erp-contact.DTO';
import { ErpContactsArray } from '../../../domain/erp-contacts-array';
import { ErpContactMapper } from './erp-contact.mapper';

export class ErpContactArrayMapper {

    public static toDomain(response: ErpContactDTO[]): ErpContactsArray {
        const erpContactsArray:ErpContactsArray = new ErpContactsArray();

        response.forEach((erpContact):void => {
            erpContactsArray.addErpContact(ErpContactMapper.toDomain(erpContact));
        });
        return erpContactsArray;
    }

}
