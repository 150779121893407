import React from 'react';
import { ShippingAddressDefault } from '../../orders/model/ShippingAddressDefault';

interface Props {
    address: ShippingAddressDefault
}

export const CDeliveryAddress: React.FC<Props> = ({ address }) => {
    return (
        <div className="delivery-address">{address.buildAddressInfo()}</div>
    );
};
