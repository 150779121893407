import React from 'react';
import { ReactComponent as IcEdit } from '../../../../assets/img/icons/ic-edit-grey.svg';

interface DeleteButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const EditButton = (props: DeleteButtonProps): React.ReactElement => {

    return (
        <button type="button" className="btn-icon btn-icon-edit" onClick={props.onClick}>
            <IcEdit />
        </button>
    );
};
