import React from 'react';
import { useTranslation } from 'react-i18next';
import { STIMATED_PRODUCTION_DATE } from 'i18n/translations/TR';

interface Props {
    isOutlet: boolean,
    amount: number,
    stockEntryDate: string|undefined,
    locale: string,
}

export const EstimatedProductionDateLabel: React.FC<Props> = ({ isOutlet, amount, stockEntryDate, locale }) => {

    const { t } = useTranslation();

    const stockInProduction = (): string | null => {

        if (!isOutlet && amount > 0 && stockEntryDate?.length) {

            return `${t(STIMATED_PRODUCTION_DATE)}: ${new Date(stockEntryDate).toLocaleDateString(locale).replace(/\//g, '-')}`;
        }
        return null;
    };

    return (
        stockInProduction() ? (
            <div className="product-stock-label">
                <span className="product-stock stock-in-production">{stockInProduction()}</span>
            </div>
        ) : null
    );
};
