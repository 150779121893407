export interface PostAnalyticsEventRequest {
    event_type: EAnalyticsEventDTO
    date: string
    product_id?: string
}

export enum EAnalyticsEventDTO {
    LOGOUT= 'logout',
    PRODUCT_DETAIL = 'product_detail',
}
