export class TencerRoutes {

    public static HOME = '/';
    public static LOGIN = '/login';
    public static LOGIN_SIGN_IN = '/login/sign-in';
    public static LOGIN_SIGN_UP = '/login/sign-up';
    public static PRODUCT_DETAILS = '/products/:id';
    public static COLLECTION_DETAILS = '/collections/:id';
    public static CATALOG_GENERAL = '/catalog';
    public static CATALOG = '/products';
    public static COLLECTIONS = '/collections';
    public static MARKETING = '/marketing';
    public static RESET_PASSWORD = '/resetPassword/:token';
    public static CART = '/cart';
    public static ORDERS = '/orders';
    public static ORDER_DETAIL = '/orders/:id';
    public static CART_SUMMARY = '/cart-summary';
    public static ACCOUNT = '/account';
    public static LOADING_ORDERS = '/loading-orders';
    public static LOADING_ORDER_DETAIL = '/loading-orders/:id';

}
