import { Spinner } from 'app/shared/Spinner';
import React from 'react';
import { CollectionCarouselSection } from '../../../collection-details/components/CollectionCarouselSection';
import { HeaderSection } from '../../../collection-details/components/HeaderSection';
import { Tile } from '../../../products/domain/model/Tile';
import { useMarketingCollection } from './useMarketingCollection';

export const MarketingCollectionView: React.FC = () => {

    const marketingCollection = useMarketingCollection();

    return (
        <section className="marketing-collection">
            {
                marketingCollection.collection ?
                    (
                        <>
                            <HeaderSection
                                name={marketingCollection.collection.name}
                                category={marketingCollection.collection.name}
                            />
                            {
                                marketingCollection.collection.tiles.map((tile: Tile) => (
                                    <CollectionCarouselSection
                                        key={tile.type}
                                        title={marketingCollection.tileTitle(tile.type)}
                                        products={tile.products}
                                    />
                                ))
                            }
                        </>
                    )
                    : (
                        <Spinner classCss="header-section-spinner" />
                    )
            }
        </section>

    );
};
