import { UseTransportFormRegisters } from '../../../../domain/model/transport-form';
import { TransportRequestDTO } from '../dto/request/transport-request.DTO';
import { ETransportType } from '../../../../../orders/model/value-objects/transport-type';
import { ETransportTypeDTO } from '../../../../../shared/infrastructure/tencer-api/dto/responses/order-shipment-response';

export class TransportRequestMapper {

    public static fromDomain(data: UseTransportFormRegisters): TransportRequestDTO {
        switch (data.responsible) {
            case ETransportType.WILL_CALL:
                return {
                    commentary: data.comments,
                    consolidated: data.consolidated,
                    transport_type: ETransportTypeDTO.WILL_CALL,
                };
            case ETransportType.OWN_LOGISTIC:
                return {
                    commentary: data.comments,
                    consolidated: data.consolidated,
                    transport_type: ETransportTypeDTO.OWN_LOGISTIC,
                };
            case ETransportType.SMALL_PARCEL:
                return {
                    commentary: data.comments,
                    courier: data.deliveryService,
                    transport_type: ETransportTypeDTO.SMALL_PARCEL,
                };
            case ETransportType.WOW:
            default:
                return {
                    commentary: data.comments,
                    lift_gate: data.liftGate,
                    consolidated: data.consolidated,
                    transport_type: ETransportTypeDTO.WOW,
                };
        }
    }

}
