import React from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR_CODE_40017, PROCESS_ORDER } from '../../../i18n/translations/TR';
import { CartSummaryFooter } from './components/footer/cart-summary-footer';
import { useCartSummary } from './hooks/use-cart-summary';
import { PageSpinner } from '../../shared/components/page-spinner/page-spinner';
import './cart-summary-screen.scss';
import { PageBanner } from '../../page-banner/component/PageBanner';
import { CartSummaryBody } from './components/body/cart-summary-body';
import { ModalError } from '../../shared/ModalError';
import { ETransportType } from '../../orders/model/value-objects/transport-type';

export const CartSummaryScreen: React.FC = () => {
    const { t } = useTranslation();
    const UseCartSummary = useCartSummary();

    return (
        <main className="screen screen-cart-summary">
            <PageBanner pageId={UseCartSummary.pageName} />

            <PageSpinner isBusy={UseCartSummary.cartSummaryScreenIsBusy} />

            <ModalError
                message={t(ERROR_CODE_40017)}
                show={
                    UseCartSummary.showTransportPriceErrorModal &&
                    UseCartSummary.watchResponsible === ETransportType.WOW
                }
                onHide={UseCartSummary.handleHideTransportPriceErrorModal}
            />

            {UseCartSummary.cart?.items ? (

                <div className="cart-container">
                    <div className=" cart-header">
                        <h4 className="section-title">{t(PROCESS_ORDER)}</h4>
                    </div>

                    <CartSummaryBody
                        UseCartSummary={UseCartSummary}
                    />

                    {UseCartSummary.cart.orderId ? (
                        <CartSummaryFooter
                            orderId={UseCartSummary.cart.orderId}
                            totalAmount={UseCartSummary.totalAmount}
                            checkoutCart={UseCartSummary.checkoutCart}
                            watchResponsible={UseCartSummary.watchResponsible}
                            cartSummaryForm={UseCartSummary.cartSummary.cartCheckoutForm}
                            UseTransportForm={UseCartSummary.UseTransport.UseTransportForm}
                            isGettingTransportPrice={UseCartSummary.UseTransport.isGettingTransportPrice}
                        />
                    ) : null}
                </div>
            ) : null }
        </main>
    );
};
