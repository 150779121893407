import { URL } from 'app/shared/domain/value-objects/URL';
import { PageBannerResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/PageBannerResponse';
import PageBanner from '../../../../model/PageBanner';

export default class PageBannerMapper {

    public static fromResponse(response: PageBannerResponse | null): PageBanner|null {
        if (!response) {
            return null;
        }

        return new PageBanner(response.text, response.link ? new URL(response.link) : null);
    }

}
