import { ErpContact } from './erp-contact';

export class ErpContactsArray {

    private _erpContacts: ErpContact[];

    public constructor() {
        this._erpContacts = [];
    }

    get erpContacts(): ErpContact[] {
        return this._erpContacts;
    }

    public setErpContacts(value: ErpContact[]): void {
        this._erpContacts = value;
    }

    public addErpContact(value: ErpContact): void {
        this._erpContacts.push(value);
    }

    public addErpContacts(values: ErpContact[]): void {
        values.map((user):void => this.addErpContact(user));
    }

}
