import { PostLoadingOrderLinesRequestDTO } from '../dto/request/post-loading-order-lines-request.DTO';
import OrderLineToLoad from '../../../domain/order-line-to-load';

export class PostLoadingOrderLineMapper {

    public static fromDomain(
        orderToLoadLine: OrderLineToLoad,
    ): PostLoadingOrderLinesRequestDTO {
        return {
            order_number: orderToLoadLine.id.orderNumber,
            year: orderToLoadLine.id.year,
            idx: orderToLoadLine.id.idx,
        };
    }

}
