import { PermissionDTO } from '../dto/permission.DTO';
import { PermissionArray } from '../../../domain/permission-array';
import { PermissionMapper } from './permission.mapper';

export class PermissionArrayMapper {

    public static toDomain(permissionArrayDTO: PermissionDTO[]): PermissionArray {
        const permissionArray: PermissionArray = new PermissionArray();

        permissionArrayDTO.map((permission) => {
            const permissionDomain = PermissionMapper.toDomain(permission);
            permissionArray.addPermission(permissionDomain);
            return true;
        });

        return permissionArray;
    }

}
