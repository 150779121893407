import { OrderId } from 'app/shared/domain/value-objects/OrderId';
import { URL } from 'app/shared/domain/value-objects/URL';
import { FileId } from './value-objects/FileId';
import { FileName } from './value-objects/FileName';

export class File {

    private _url: URL;
    private _id: FileId;
    private _orderId: OrderId;
    private _name: FileName | null;
    private _fileName: FileName;

    public constructor(
        url:URL,
        id:FileId,
        orderId:OrderId,
        fileName:FileName,
    ) {
        this._url = url;
        this._id = id;
        this._orderId = orderId;
        this._name = null;
        this._fileName = fileName;
    }

    public get url(): URL {
        return this._url;
    }

    public get id(): FileId {
        return this._id;
    }

    public get orderId(): OrderId {
        return this._orderId;
    }

    public get name(): FileName | null {
        return this._name;
    }

    public get fileName(): FileName {
        return this._fileName;
    }

}
