import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ACCEPT, ACCOUNT_CONTACT_REMOVE_CONFIRMATION_BODY, ACCOUNT_CONTACT_REMOVE_TITLE, CANCEL } from '../../../../../../../../i18n/translations/TR';

interface AccountContactModalDeleteProps {
    handleClose: () => void
    showModal: boolean
    deleteContactIsLoading:boolean
    deleteErpUserContact: (email:string) => void
    contactToDelete:string
}
export const AccountContactModalDelete = (
    {
        handleClose,
        showModal,
        deleteContactIsLoading,
        deleteErpUserContact,
        contactToDelete,
    }:AccountContactModalDeleteProps,
):React.ReactElement => {
    const { t } = useTranslation();
    return (
        <Modal
            show={showModal}
            className="modal fade"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h5 className="modal-title">{t(ACCOUNT_CONTACT_REMOVE_TITLE)}</h5>
            </Modal.Header>
            <Modal.Body>
                <div>{t(ACCOUNT_CONTACT_REMOVE_CONFIRMATION_BODY)}</div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn-link bg-transparent text-decoration-none"
                    onClick={handleClose}
                    disabled={deleteContactIsLoading}
                >
                    {t(CANCEL)}
                </button>
                <button
                    type="submit"
                    form="add-form"
                    className="btn btn-primary"
                    disabled={deleteContactIsLoading}
                    onClick={():void => {
                        deleteErpUserContact(contactToDelete);
                    }}
                >
                    {
                        deleteContactIsLoading
                            ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />)
                            : null
                    }
                    {`\u00A0${t(ACCEPT)}`}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
