import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { LOGIN_REQUEST_ACCESS_CONTACT_ADMIN, LOGIN_REQUEST_ACCESS_MODAL_TITLE } from '../../../../i18n/translations/TR';

interface Props {
    show: boolean,
    onHide: () => void,
}

export const ApplyAccessModal: React.FC<Props> = (
    {
        show,
        onHide,
    },
) => {
    const { t } = useTranslation();

    return (
        <div>
            <Modal
                show={show}
                className="modal fade"
                centered
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton onHide={onHide}>
                    <h5 className="modal-title">{t(LOGIN_REQUEST_ACCESS_MODAL_TITLE)}</h5>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    {t(LOGIN_REQUEST_ACCESS_CONTACT_ADMIN)}
                </Modal.Body>

            </Modal>
        </div>
    );
};
