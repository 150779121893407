import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationsEn from './translations/translations.en.json';
import translationsEs from './translations/translations.es.json';
import translationsIt from './translations/translations.it.json';
import translationsDe from './translations/translations.de.json';
import translationsFR from './translations/translations.fr.json';
import { TencerAuthServiceInstance } from '../app/shared/tencer-routing/tencer-auth-route/TencerAuthService';

let language: string;

export enum TencerLanguage {
    ENGLISH = 'en',
    SPANISH = 'es',
    FRENCH = 'fr',
    GERMAN = 'de',
    ITALIAN = 'it'
}

const localLanguage = TencerAuthServiceInstance.getLanguage();

const TencerLanguages = [
    TencerLanguage.ENGLISH,
    TencerLanguage.SPANISH,
    TencerLanguage.GERMAN,
    TencerLanguage.ITALIAN,
    TencerLanguage.FRENCH,
];

const isValidLanguage = (): boolean => {
    return localLanguage !== null && !!localLanguage && TencerLanguages.includes(<TencerLanguage>localLanguage);
};

const setLanguage = (): string => {
    if (isValidLanguage()) {
        language = localLanguage;
    } else if (document.cookie.includes(TencerLanguage.SPANISH) ||
        navigator.language.includes(TencerLanguage.SPANISH)) {
        language = TencerLanguage.SPANISH;
    } else if (document.cookie.includes(TencerLanguage.ENGLISH) ||
        navigator.language.includes(TencerLanguage.ENGLISH)) {
        language = TencerLanguage.ENGLISH;
    } else if (document.cookie.includes(TencerLanguage.ITALIAN) ||
        navigator.language.includes(TencerLanguage.ITALIAN)) {
        language = TencerLanguage.ITALIAN;
    } else if (document.cookie.includes(TencerLanguage.GERMAN) ||
        navigator.language.includes(TencerLanguage.GERMAN)) {
        language = TencerLanguage.GERMAN;
    } else if (document.cookie.includes(TencerLanguage.FRENCH) ||
        navigator.language.includes(TencerLanguage.FRENCH)) {
        language = TencerLanguage.FRENCH;
    } else {
        language = TencerLanguage.ENGLISH;
    }
    document.cookie = navigator.language;
    TencerAuthServiceInstance.setLanguage(language);
    return language;
};

i18next
    .use(initReactI18next)
    .init({
        lng: setLanguage(),
        resources: {
            en: {
                translation: translationsEn,
            },
            es: {
                translation: translationsEs,
            },
            it: {
                translation: translationsIt,
            },
            de: {
                translation: translationsDe,
            },
            fr: {
                translation: translationsFR,
            },
        },
    })
    .then((): null => null);

export default i18next;
