export interface OrderFilterValue {}

export class OrderFilter {

    private _name: string;
    public get name(): string {
        return this._name;
    }

    private _value: OrderFilterValue;
    public get value(): OrderFilterValue {
        return this._value;
    }

    constructor(name: string, value: OrderFilterValue) {
        this._name = name;
        this._value = value;
    }

}
