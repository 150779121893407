import { ApiProduct } from 'app/home/product/shared/domain/ApiProduct';
import { MarketingCatalogResponse } from 'app/shared/infrastructure/tencer-api/dto/responses/MarketingCatalogResponse';
import { ProductMapper } from '../../../../../orders/infrastructure/repository/api/mappers/ProductMapper';
import MarketingCollection from '../../../../domain/model/MarketingCollection';
import { Tile } from '../../../../domain/model/Tile';
import { CollectionId } from '../../../../domain/value-object/CollectionId';

export class MarketingCollectionMapper {

    public static fromResponse(response: MarketingCatalogResponse): MarketingCollection {
        const tiles = response.tiles.map((tilesCollection) => {
            return new Tile(
                tilesCollection.products.map((product: ApiProduct) => {
                    return ProductMapper.fromApiProduct(product);
                }),
                tilesCollection.type,
            );
        });

        return new MarketingCollection(
            new CollectionId(Number(response.collection.id)),
            response.collection.name,
            new Date(response.collection.date),
            response.collection.image,
            response.collection.imageHeader,
            tiles,
        );
    }

}
