import { SEARCH } from 'i18n/translations/TR';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
    title: string;
}

export const SectionHeaderCollections: React.FC <Props> = ({ title }) => {

    const { t } = useTranslation();

    return (

        <div className="header-catalog header-actions">
            <h3 className="section-title">{title}</h3>
            <div className="actions d-none">
                <input
                    className="form-control me-2 form-search"
                    type="search"
                    placeholder={t(SEARCH)}
                    aria-label="Search"
                />
            </div>
        </div>
    );
};
