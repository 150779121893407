import { Permission } from './permission';
import { PermissionName } from './value-objects/PermissionName';

export class PermissionArray {

    private _permissions: Permission[];

    public constructor() {
        this._permissions = [];
    }

    get permissions(): Permission[] {
        return this._permissions;
    }

    public setFiles(value: Permission[]): void {
        this._permissions = value;
    }

    public addPermission(value: Permission): void {
        this._permissions?.push(value);
    }

    public addFiles(values: Permission[]): void {
        values.map((file):void => this.addPermission(file));
    }

    public getLength(): number {
        return this._permissions?.length;
    }

    public get permissionsName():string[] {
        const permissionsName: string[] = [];

        this._permissions.forEach((permission) => {
            permissionsName.push(permission.name.value);
        });
        return permissionsName;
    }

    public has(permissionName: PermissionName): boolean {
        return this._permissions.some((permission) => {
            return permission.name.value === permissionName.toString();
        });
    }

}
