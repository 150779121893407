import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import { TencerRoutes } from 'app/shared/tencer-routing/TencerRoutes';
import { useTranslation } from 'react-i18next';
import { NO_RESULTS } from 'i18n/translations/TR';
import { SectionHeaderCatalog } from 'app/shared/SectionHeaderCatalog';
import { Spinner } from 'app/shared/Spinner';
import { ServerErrorMessage } from 'app/shared/ServerErrorMessage';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiProduct } from 'app/home/product/shared/domain/ApiProduct';
import { FilterModal } from './components/FilterModal';
import { ProductsCatalogList } from './components/ProductCatalogList';
import { useCatalogList } from './shared/domain/CatalogList';

interface Props {
    title: string;
    urlGet: string,
    collectionLabel: string,
    materialLabel: string,
    stockLabel?: boolean,
    isFilter: boolean,
    isSearch: boolean,
    isBestSellers: boolean,
    isOutlet: boolean,
    stockParameter?: boolean,
    collectionParameter?: string,
    materialParameter?: string,
    searchContent: string,
    brandParameter?: string,
    brandLabel: string,
}

export const ProductCatalogScreen: React.FC<Props> = (
    {
        title,
        urlGet,
        materialLabel,
        collectionLabel,
        stockLabel,
        isFilter,
        isSearch,
        isBestSellers,
        isOutlet,
        stockParameter,
        searchContent,
        collectionParameter,
        materialParameter,
        brandParameter,
        brandLabel,
    },
) => {
    const classes = classNames(
        'tab-pane fade',
        { 'show active': window.location.pathname === TencerRoutes.CATALOG },
    );

    const { t } = useTranslation();
    let serverErrorMessage: boolean = false;

    const responseCatalog = useCatalogList(urlGet);
    const isLoading = responseCatalog?.loading;
    const products: ApiProduct[] = responseCatalog?.data;
    const error = responseCatalog?.error;
    const more = responseCatalog?.more;

    const pageResultsDefault = 30;

    if (error) {
        serverErrorMessage = true;
    }

    const [show, setShow] = useState(false);
    const [page, setPage] = useState(2);

    const handleShow = (): void => setShow(true);
    const handleClose = (): void => setShow(false);
    const [hasMore, setHasMore] = useState(true);

    const APIClient = TencerApiClientSingleton.getInstance();

    const fetchProducts = async (): Promise<ApiProduct[]> => {
        if (isSearch) {
            return APIClient.getCatalogSearch(
                searchContent,
                page,
                pageResultsDefault,
            );
        }
        if (isBestSellers) {
            return APIClient.getTrendingProducts(pageResultsDefault, page);
        }
        if (isOutlet) {
            return APIClient.getOutletCatalog(pageResultsDefault, page);
        }

        if (isFilter) {
            return APIClient.getPackingCatalog(
                [],
                pageResultsDefault,
                page,
                collectionParameter,
                materialParameter,
                brandParameter,
                stockParameter,
            );
        }

        return APIClient.getPackingCatalog([], pageResultsDefault, page);
    };

    const fetchData = async (): Promise<void> => {
        if (!hasMore) {
            return;
        }
        const productsFromApi = await fetchProducts();
        productsFromApi.map((product: ApiProduct) => (
            products.push(product)
        ));
        if (productsFromApi.length === 0 || productsFromApi.length < pageResultsDefault) {
            setHasMore(false);
        }
        setPage(page + 1);
    };

    return (
        <div
            className={classes}
            id="pills-catalog"
            role="tabpanel"
            aria-labelledby="pills-catalog-tab"
        >
            {!isLoading ?
                (
                    <div>
                        <SectionHeaderCatalog
                            bestsellers={isBestSellers}
                            outlet={isOutlet}
                            title={title}
                            materialLabel={materialLabel}
                            stockLabel={stockLabel}
                            collectionLabel={collectionLabel}
                            brandLabel={brandLabel}
                            handleShow={handleShow}
                            searchContent={searchContent}
                            isFilter={isFilter}
                        />

                        {!serverErrorMessage ?
                            (
                                <InfiniteScroll
                                    dataLength={products.length}
                                    next={fetchData}
                                    hasMore={!more ? false : hasMore}
                                    loader={<Spinner classCss="basic-spinner-container" />}
                                >
                                    {products.length ?
                                        (
                                            <>
                                                <ProductsCatalogList productsCatalog={products} />
                                                <FilterModal show={show} onHide={handleClose} />
                                            </>
                                        ) : <div>{t(NO_RESULTS)}</div>}
                                </InfiniteScroll>
                            )
                            : <ServerErrorMessage />}
                    </div>
                ) : <Spinner classCss="header-section-spinner" />}
        </div>
    );
};

ProductCatalogScreen.defaultProps = {
    stockParameter: undefined,
    stockLabel: undefined,
    collectionParameter: undefined,
    materialParameter: undefined,
    brandParameter: undefined,
};
