import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { useMutation, UseMutationResult } from 'react-query';
import { OrderRepository } from '../../infrastructure/repository/api/OrderRepository';
import Order from '../../model/Order';
import { Packing } from '../../../products/domain/model/Packing';
import { AddClientRefMutationRequest } from './request/AddClientRefMutationRequest';
import { AddOrderFileMutationRequest } from './request/AddOrderFileMutationRequest';
import { FetchOrderFileMutationRequest } from './request/FetchOrderFileMutationRequest';
import { FetchOrderMutationRequest } from './request/FetchOrderMutationRequest';
import { FetchPackingCatalogMutationRequest } from './request/FetchPackingCatalogMutationRequest';
import { FetchUserOrdersMutationRequest } from './request/FetchUserOrdersMutationRequest';

interface OrderRepositoryMutationsInterface {
    addClientRefMutation: UseMutationResult<void, unknown, AddClientRefMutationRequest, unknown>;
    fetchOrderMutation: UseMutationResult<Order, unknown, FetchOrderMutationRequest, unknown>,
    fetchOrderFileMutation: UseMutationResult<Blob, unknown, FetchOrderFileMutationRequest, unknown>,
    fetchUserOrdersMutation: UseMutationResult<Order[], unknown, FetchUserOrdersMutationRequest, unknown>,
    fetchPackingCatalogMutation: UseMutationResult<Packing[], unknown, FetchPackingCatalogMutationRequest, unknown>,
    addOrderFileMutation: UseMutationResult<void, unknown, AddOrderFileMutationRequest, unknown>,
}

export const useOrderRepository = (): OrderRepositoryMutationsInterface => {
    const client = TencerApiClientSingleton.getInstance();
    const repository = new OrderRepository(client);

    const fetchOrderMutation = useMutation(async (
        request: FetchOrderMutationRequest,
    ) => repository.getOrder(request.orderId));

    const fetchOrderFileMutation = useMutation(
        async (request: FetchOrderFileMutationRequest) => repository.getOrderFile(request.orderId, request.orderFileId),
    );

    const fetchUserOrdersMutation = useMutation(
        async (request: FetchUserOrdersMutationRequest) => repository.getUserOrders(
            request.filters,
            request.actualPage,
            request.resultsPerPage,
            request.confirmed,
        ),
    );

    const fetchPackingCatalogMutation = useMutation(
        async (request: FetchPackingCatalogMutationRequest) => repository.getPackingCatalog(
            request.productId,
            request.page,
            request.results,
        ),
    );

    const addOrderFileMutation =
        useMutation(async (
            request: AddOrderFileMutationRequest,
        ) => repository.addOrderFile(request.orderId, request.file));

    const addClientRefMutation =
        useMutation(async (
            request: AddClientRefMutationRequest,
        ) => repository.addClientRef(request.cartId, request.clientRef));

    return {
        fetchOrderMutation,
        fetchOrderFileMutation,
        fetchUserOrdersMutation,
        fetchPackingCatalogMutation,
        addOrderFileMutation,
        addClientRefMutation,
    };
};
