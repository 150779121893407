import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import {
    BOX_UNIT,
    BOXES,
    LOT,
    PALLETS,
    PALLETS_SINGLE,
    PIECE_UNIT,
    PIECE_UNIT_SINGLE,
} from '../../../../../../../../../i18n/translations/TR';
import { Quantity } from '../../../../../../../../shared/components/Quantity';
import { COWeight } from '../../../../../../../../shared/components/CO-weight';
import { ReactComponent as IcNext } from '../../../../../../../../../assets/img/icons/ic-next-white.svg';
import { ReactComponent as IcBack } from '../../../../../../../../../assets/img/icons/ic-back-white.svg';
import OrderLineToLoad from '../../../../../../../domain/order-line-to-load';

interface OrderToLoadItemProps {
    isOrderLoadedTable: boolean
    itemIsLoaded: boolean
    line: OrderLineToLoad
    onClick: () => void
}

export const OrderToLoadLineItem = (
    props: OrderToLoadItemProps,
): React.ReactElement => {
    const { t } = useTranslation();

    const orderToLoadItemRowClassNames = cx({
        'is-item-loaded': props.itemIsLoaded && !props.isOrderLoadedTable,
    });

    return (
        <tr
            className={orderToLoadItemRowClassNames}
        >
            <td>{props.line.description}</td>
            <td>{}</td>
            <td>{props.line.format}</td>
            <td>
                <div className="product-tag">
                    <span className="product-tag-label">
                        {t(LOT).toUpperCase()}
                    </span>
                    <span className="product-tag-value">
                        {props.line.reference}
                    </span>
                </div>
            </td>
            <td>
                <div className="product-tag">
                    <Quantity
                        value={props.line.quantityInPallets}
                        label={
                            props.line.quantityInPallets > 1 ?
                                `${t(PALLETS).toLowerCase()}`
                                : `${t(PALLETS_SINGLE).toLowerCase()}`
                        }
                    />
                </div>
            </td>
            <td>
                <div className="product-tag">
                    <Quantity
                        value={props.line.quantityInBoxes}
                        label={
                            props.line.quantityInBoxes > 1 ?
                                `${t(BOXES).toLowerCase()}`
                                : `${t(BOX_UNIT).toLowerCase()}`
                        }
                    />
                </div>
            </td>
            <td>
                <div className="product-tag">
                    <Quantity
                        value={props.line.quantityInPieces}
                        label={props.line.quantityInPieces > 1 ? `${t(PIECE_UNIT)}` : `${t(PIECE_UNIT_SINGLE)}`}
                    />
                </div>
            </td>
            <td>
                <div className="product-tag">
                    <COWeight value={props.line.weightGross} />
                </div>
            </td>
            <td>{}</td>

            {props.isOrderLoadedTable ? (
                <td>
                    <button
                        type="button"
                        className="add-item-button"
                        onClick={props.onClick}
                    >
                        <IcBack />
                    </button>
                </td>
            ) : (
                <td>
                    {!props.itemIsLoaded ? (
                        <button
                            type="button"
                            className="add-item-button"
                            onClick={props.onClick}
                        >
                            <IcNext />
                        </button>
                    ) : null}
                </td>
            )}
        </tr>
    );
};
