import { ETransportType } from '../../../orders/model/value-objects/transport-type';

export interface UseTransportFormRegisters {
    comments: string
    liftGate: boolean
    consolidated: boolean
    deliveryService: string
    responsible: ETransportType
    allowQuoteTransport: boolean
}

export enum UseTransportFormName {
    COMMENTS= 'comments',
    LIFT_GATE= 'liftGate',
    RESPONSIBLE= 'responsible',
    CONSOLIDATED= 'consolidated',
    DELIVERY_SERVICE= 'deliveryService',
    ALLOW_QUOTE_TRANSPORT= 'allowQuoteTransport',
}
