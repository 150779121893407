import i18next from 'i18next';
import { ERROR_500 } from '../../../../i18n/translations/TR';

export class Language {

    private _language: string;

    public constructor(value: string) {
        this.ensureIsNotUndefined(value);
        this._language = value;
    }

    private ensureIsNotUndefined(value: string | null): void {
        if (value === undefined || value === '') {
            throw i18next.t(ERROR_500); // Todo crear error específico
        }
    }

    public get language(): string {
        return this._language;
    }

}
