import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LiftGateField } from './fields/lift-gate-field';
import { CommentsField } from './fields/comments-field';
import { ConsolidatedField } from './fields/consolidated-field';
import {
    UseTransportFormName,
    UseTransportFormRegisters,
} from '../../../../../domain/model/transport-form';
import { ResponsibleField } from './fields/responsible-field';
import { SHIPPING_METHOD } from '../../../../../../../i18n/translations/TR';
import { DeliveryServiceField } from './fields/delivery-service-field';
import { DeliveryService } from '../../../../../../shared/domain/delivery-service';
import { ETransportType } from '../../../../../../orders/model/value-objects/transport-type';

interface OrderTransportFormProps {
    deliveryServices: DeliveryService[]
    UseTransportForm: UseFormReturn<UseTransportFormRegisters>
}

export const OrderTransportForm = (props: OrderTransportFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const resetFields = (): void => {
        props.UseTransportForm.resetField(UseTransportFormName.COMMENTS);
        props.UseTransportForm.resetField(UseTransportFormName.LIFT_GATE);
        props.UseTransportForm.resetField(UseTransportFormName.CONSOLIDATED);
        props.UseTransportForm.resetField(UseTransportFormName.DELIVERY_SERVICE);
    };

    const watchResponsible = props.UseTransportForm.watch(UseTransportFormName.RESPONSIBLE);
    const watchAllowQuoteTransport = props.UseTransportForm.watch(UseTransportFormName.ALLOW_QUOTE_TRANSPORT);

    const responsibleRegister = props.UseTransportForm.register(UseTransportFormName.RESPONSIBLE, {
        required: true,
        onChange: (): void => {
            resetFields();
        },
    });

    const deliveryServiceRegister = props.UseTransportForm.register(UseTransportFormName.DELIVERY_SERVICE, {
        required: watchResponsible === ETransportType.SMALL_PARCEL,
    });
    const deliveryServiceError = props.UseTransportForm.formState.errors.deliveryService;
    const commentsRegister = props.UseTransportForm.register(UseTransportFormName.COMMENTS);
    const liftGateRegister = props.UseTransportForm.register(UseTransportFormName.LIFT_GATE);
    const consolidatedRegister = props.UseTransportForm.register(UseTransportFormName.CONSOLIDATED);

    return (

        <form className="transport-form-container">
            <div className="cart-content-subtitle">
                <span>{t(SHIPPING_METHOD)}</span>
            </div>

            <ResponsibleField
                responsibleRegister={responsibleRegister}
                allowQuoteTransport={watchAllowQuoteTransport}
            />

            <CommentsField
                commentsRegister={commentsRegister}
                watchResponsible={watchResponsible}
            />

            {
                watchResponsible !== ETransportType.SMALL_PARCEL ? (
                    <div className="row">
                        <ConsolidatedField
                            consolidatedRegister={consolidatedRegister}
                        />
                        {
                            watchResponsible === ETransportType.WOW ? (
                                <LiftGateField
                                    liftGateRegister={liftGateRegister}
                                />
                            ) : null
                        }
                    </div>
                ) : (
                    <DeliveryServiceField
                        deliveryServiceFieldRegister={deliveryServiceRegister}
                        deliveryServiceFieldError={deliveryServiceError}
                        deliveryServices={props.deliveryServices}
                    />
                )
            }
        </form>
    );
};
