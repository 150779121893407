import i18next from 'i18next';
import {
    LOADING_ORDER_CONFIRMED_STATUS,
    LOADING_ORDER_IN_PREPARATION_STATUS,
    LOADING_ORDER_SERVED_STATUS,
} from '../../../../i18n/translations/TR';

export enum ELoadingOrderStatus{
    CONFIRMED = 'CONFIRMED',
    IN_PREPARATION = 'IN_PREPARATION',
    SERVED = 'SERVED',
}
export class LoadingOrderStatus {

    private readonly _value: ELoadingOrderStatus;

    public constructor(value: ELoadingOrderStatus) {
        this.ensureIsNotUndefined(value);
        this._value = value;
    }

    private ensureIsNotUndefined(value: ELoadingOrderStatus): void {
        if (value === undefined) {
            throw new Error('String cannot be undefined');
        }
    }

    public get value(): ELoadingOrderStatus {
        return this._value;
    }

    public get translate(): string {
        switch (this._value) {
            case ELoadingOrderStatus.CONFIRMED:
                return i18next.t(LOADING_ORDER_CONFIRMED_STATUS) as string;
            case ELoadingOrderStatus.SERVED:
                return i18next.t(LOADING_ORDER_SERVED_STATUS) as string;
            case ELoadingOrderStatus.IN_PREPARATION:
            default:
                return i18next.t(LOADING_ORDER_IN_PREPARATION_STATUS) as string;
        }
    }

}
