import React from 'react';
import { OrderToLoadItem } from './order-to-load-item/order-to-load-item';
import OrderToLoad from '../../../../../domain/order-to-load';
import OrderLineToLoad from '../../../../../domain/order-line-to-load';

interface OrderToLoadTableBodyProps {
    isOrderLoadedTable: boolean
    orders: OrderToLoad[]
    moveOrderToLoaded: (orderToMove: OrderToLoad) => void
    removeOrderFromLoaded: (orderToRemove: OrderToLoad) => void
    moveOrderLineToLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    removeOrderLineFromLoaded: (lineOrder: OrderToLoad, line: OrderLineToLoad) => void
    checkIfOrderIsLoaded: (order: OrderToLoad) => boolean
    checkIfOrderLineIsLoaded: (order: OrderToLoad, line: OrderLineToLoad) => boolean
}
export const OrderToLoadTableBody = (props: OrderToLoadTableBodyProps): React.ReactElement => {
    const orderById = (item1:OrderToLoad, item2:OrderToLoad):number => {
        if (item1.id.value.toLowerCase() < item2.id.value.toLowerCase()) { return -1; }
        if (item1.id.value.toLowerCase() > item2.id.value.toLowerCase()) { return 1; }

        return 0;
    };
    return (
        <tbody>
            {
                props.orders
                    .sort(orderById)
                    .map((order) => {
                        return (
                            <OrderToLoadItem
                                key={order.id.value}
                                isOrderLoadedTable={props.isOrderLoadedTable}
                                order={order}
                                moveOrderToLoaded={props.moveOrderToLoaded}
                                removeOrderFromLoaded={props.removeOrderFromLoaded}
                                moveOrderLineToLoaded={props.moveOrderLineToLoaded}
                                removeOrderLineFromLoaded={props.removeOrderLineFromLoaded}
                                checkIfOrderIsLoaded={props.checkIfOrderIsLoaded}
                                checkIfOrderLineIsLoaded={props.checkIfOrderLineIsLoaded}
                            />
                        );
                    })
            }
        </tbody>
    );
};
