import React from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_LOADING_ORDER } from 'i18n/translations/TR';

interface SectionHeaderLoadingOrderProps {
    title: string
    openLoadingOrderAddModal: () => void
}

export const SectionHeaderLoadingOrder: React.FC <SectionHeaderLoadingOrderProps> = (
    { title, openLoadingOrderAddModal },
) => {

    const { t } = useTranslation();

    return (

        <div className="header-catalog header-actions">
            <h3 className="section-title">{title}</h3>
            <div className="actions">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={openLoadingOrderAddModal}
                >
                    {t(CREATE_LOADING_ORDER)}
                </button>
            </div>
        </div>
    );
};
