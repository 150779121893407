import { Contact } from '../../../domain/contact';
import { ContactDTO } from '../dto/contact.DTO';
import { Phone } from '../../../domain/value-objects/phone';
import { Email } from '../../../domain/value-objects/email';
import { Language } from '../../../domain/value-objects/language';

export class ContactMapper {

    public static toDomain(contactDto: ContactDTO): Contact {
        const contactDomain = new Contact();

        if (contactDto.mail !== null) {
            contactDomain.mail = new Email(contactDto.mail);
        }
        if (contactDto.phone_number !== null) {
            contactDomain.phoneNumber = new Phone(contactDto.phone_number);
        }
        if (contactDto.default_language !== null) {
            contactDomain.defaultLanguage = new Language(contactDto.default_language);
        }
        return contactDomain;
    }

}
