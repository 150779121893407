import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import {
    LOADING_DATE, LOADING_ORDER_TRANSPORT_TYPE,
    ORDERS_TABLE_COLUMN_SHIPING_ADDRESS,
    PACKAGES_PALLETS,
    TOTAL_WEIGHT,
} from '../../../../../../i18n/translations/TR';
import LoadingOrder from '../../../../domain/loading-order';
import i18n from '../../../../../../i18n';
import './loading-order-detail-summary.scss';
import { COWeight } from '../../../../../shared/components/CO-weight';

interface LoadingOrderDetailSummaryProps {
    loadingOrder: LoadingOrder
}
export const LoadingOrderDetailSummary = ({ loadingOrder }: LoadingOrderDetailSummaryProps): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className="loading-order-summary-card-container">
            <ul>
                <li>
                    <p>
                        <strong>{t(ORDERS_TABLE_COLUMN_SHIPING_ADDRESS)}</strong>
                    </p>
                    <p>{loadingOrder.shippingAddress?.buildAddressInfo()}</p>
                </li>
                <li>
                    <p>
                        <strong>{t(LOADING_DATE)}</strong>
                    </p>
                    <p>
                        {
                            loadingOrder
                                ? moment(loadingOrder.loadingOrderDate)
                                    .locale(i18n.language)
                                    .format('MMM D, YYYY')
                                : ''
                        }
                    </p>
                </li>
                <li>
                    <p>
                        <strong>{t(LOADING_ORDER_TRANSPORT_TYPE)}</strong>
                    </p>
                    <p>{loadingOrder.transportType.translate}</p>
                </li>
                <li>
                    <p>
                        <strong>{t(PACKAGES_PALLETS)}</strong>
                    </p>
                    <p>{loadingOrder.packages}</p>
                </li>
                <li>
                    <p>
                        <strong>{t(TOTAL_WEIGHT)}</strong>
                    </p>
                    <p>
                        <COWeight value={loadingOrder.totalWeight} className="import" />
                    </p>
                </li>
            </ul>
        </div>
    );
};
