import React from 'react';
import { TencerApiClientSingleton } from 'app/shared/hooks/TencerApiClientSingleton';
import { ApiProductCatalog } from '../../../../../product-catalog-collections/Catalog/shared/domain/ApiProductCatalog';

interface UseProductList {
    data: ApiProductCatalog[];
    error: boolean;
    loading: boolean;
}

export function useTopTrendingList(): UseProductList {
    const [data, setData] = React.useState<ApiProductCatalog[]>([]);
    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        TencerApiClientSingleton.getInstance()
            .getTrendingProducts(5, 1)
            .then((products) => setData(products))
            .catch(() => {
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        error,
        loading,
    };
}
