import React from 'react';
import classNames from 'classnames';
import { ShippingAddress } from '../../../../../../../orders/model/ShippingAddress';
import { DeleteButton } from '../../../../../../../shared/components/button/DeleteButton';
import { EditButton } from '../../../../../../../shared/components/button/EditButton';
import { useToken } from '../../../../../../../shared/hooks/use-token';
import { Origin } from '../../../../../../../shared/domain/value-objects/Origin';

interface AccountShippingAddressCardItemProps{
    address:ShippingAddress
    handleShowEditModal: (addressToEdit: ShippingAddress) => void
    handleShowDeleteConfirmationModal: (addressToDelete: ShippingAddress) => void
    onClick?: () => void
    isSelected: boolean
}
export const AccountShippingAddressCardItem = (
    props :AccountShippingAddressCardItemProps,
):React.ReactElement => {
    const UseToken = useToken();

    return (
        <div
            className={classNames('input-group', 'shipping-address-container', { selected: props.isSelected })}
            onClick={props.onClick}
            aria-hidden
        >
            <div
                aria-hidden
                className="form-check shipping-address-info"
            >
                <input
                    type="radio"
                    className="form-check-input"
                    name="shipping-address-radio"
                    id={`shipping-address-radio-${props.address.code.value}`}
                    defaultChecked={props.isSelected}
                    value={props.address.code.value}
                />
                <label
                    htmlFor={`shipping-address-radio-${props.address.code.value}`}
                    className="form-check-label"
                >
                    {`(${props.address.code.value.trim()}) ${props.address.description}`}
                </label>
            </div>
            {props.address.portal && UseToken.origin === Origin.USA ? (
                <div className="shipping-address-actions">
                    <EditButton onClick={(e): void => {
                        e.stopPropagation();
                        props.handleShowEditModal(props.address);
                    }}
                    />
                    <DeleteButton
                        onClick={(e): void => {
                            e.stopPropagation();
                            props.handleShowDeleteConfirmationModal(props.address);
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
};
