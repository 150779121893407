export class Discount {

    private readonly _value: number;
    private readonly _description: string | null;

    public constructor(value: number, description: string | null = null) {
        this.ensureIsNotUndefined(value);
        this._value = value;
        this._description = description;
    }

    private ensureIsNotUndefined(value: number): void {
        if (value === undefined) {
            throw new Error('Discount cannot be undefined');
        }
    }

    public get value(): number {
        return this._value;
    }

    public get description(): string | null {
        return this._description;
    }

}
