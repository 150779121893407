import { OrderToLoadDTO } from './order-to-load.DTO';
import { ShippingAddressResponse } from '../../../../orders/model/responses/ShippingAddressResponse';
import { ELoadingOrderTransportTypeDTO } from './loading-order-transport-type.DTO';

export interface LoadingOrderDTO {
    loading_order_id: number
    company: string
    date_time: string
    observations: string
    shipping_type: string
    max_weight: number
    created_at: string
    confirmed: boolean
    client_code: string
    orders_to_load: OrderToLoadDTO[]
    warehouse_observations: string
    load_closing_date:string
    confirmation_date: string
    packages: number
    shipping: ShippingAddressResponse
    status: ELoadingOrderStatusDTO
    transport_type: ELoadingOrderTransportTypeDTO
}

export enum ELoadingOrderStatusDTO{
    CONFIRMED = 'CONFIRMADA',
    IN_PREPARATION = 'EN PREPARACION',
    SERVED = 'SERVIDA',
}
